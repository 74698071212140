import React from 'react';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@mui/material/Autocomplete';

import Tooltip from '../../../../common/Tooltip';
import MUITooltip from 'js/components/common/MUITooltip';
import SearchFilterTextFieldSecondary from 'js/components/common/styledMUI/SearchFilterTextFieldSecondary';
import StyledAutoComplete from 'js/components/common/styledMUI/StyledAutoComplete';
import ComboBoxInfoText from './ComboBoxInfoText';

const filter = createFilterOptions();

const MuiSelect = ({
    defaultValue,
    onChange,
    hideHelperText,
    disabled,
    shouldUpdateInputText,
    id = 'select',
    attrName = 'attribute',
    label = 'Select',
    options = [],
    errorMessage = null,
    width = 460,
    isDefault = true,
    tooltipText = null,
    isInputReadOnly = false,
    maxLength = 10,
    textFieldTooltip = '',
    tooltipPosition = 'top',
}) => {
    const [inputValue, setInputValue] = React.useState('');

    const getFilterOptions = (_options, params) => {
        const filtered = filter(_options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
            filtered.push({
                value: params.inputValue,
                label: params.inputValue,
            });
        }
        return filtered.filter((arr, index, self) =>
            index === self.findIndex(t => (t.label === arr.label)));
    };
    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        return option.label || '';
    };
    const getIsOptionEqualToValue = (option, value) => {
        if (typeof option === 'string') {
            return option === value;
        }
        return option.value === value;
    };
    const handleInputChange = (event) => {
        const typedValue = event;
        if (shouldUpdateInputText) {
            if (shouldUpdateInputText(event)) {
                setInputValue(typedValue);
            }
        } else {
            setInputValue(typedValue);
        }
    };
    const renderInput = params =>
        (
            <div className="mui-select-render-input__container">
                {
                    tooltipText &&
                    <Tooltip
                        tooltipTxt={tooltipText}
                        tooltipId={`${id}-select-tip`}
                        tooltipClass="secondary"
                        tooltipPosition={tooltipPosition}
                        iconType="infoIcon"
                    />
                }
                <MUITooltip
                    title={textFieldTooltip}
                    type="light"
                >
                    <SearchFilterTextFieldSecondary
                        {...params}
                        error={!!errorMessage}
                        inputProps={{
                            ...params.inputProps,
                            maxLength, readOnly: isInputReadOnly,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            className: params.InputProps.className,
                            type: isInputReadOnly ? 'button' : 'text',
                        }}
                        label={
                            <div className={`mui-select-render-input__label${tooltipText ? '-spacer' : ''}`}>
                                {label}
                            </div>
                        }
                        variant="outlined"
                        isError={!!errorMessage}
                        isHighlighted={!isDefault}
                        width={width}
                    />
                </MUITooltip>
            </div>
        );
    return (
        <div className="crux-mui-select">
            {
                !isInputReadOnly && !hideHelperText &&
                <ComboBoxInfoText />
            }
            <StyledAutoComplete
                id={`${id}-auto-complete`}
                data-testid="mui-select-auto-complete"
                size="small"
                value={defaultValue}
                filterOptions={getFilterOptions}
                options={options}
                getOptionLabel={getOptionLabel}
                onChange={(event, newValue) => {
                    onChange({ [attrName]: newValue.value });
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    handleInputChange(newInputValue);
                }}
                renderInput={renderInput}
                disableClearable
                disablePortal
                selectOnFocus
                clearOnBlur
                autoHighlight
                isOptionEqualToValue={getIsOptionEqualToValue}
                blurOnSelect
                disabled={disabled}
            />
            {
                errorMessage &&
                <span
                    data-testid={`${id}-error-message`}
                    className="crux-range-select__error-message"
                >
                    {errorMessage}
                </span>
            }
        </div>
    );
};

MuiSelect.propTypes = {
    id: PropTypes.string,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    isDefault: PropTypes.bool,
    hideHelperText: PropTypes.bool,
    attrName: PropTypes.string,
    tooltipText: PropTypes.string,
    errorMessage: PropTypes.string,
    shouldUpdateInputText: PropTypes.func,
    textFieldTooltip: PropTypes.string,
    tooltipPosition: PropTypes.string,
    disabled: PropTypes.bool,
};
MuiSelect.displayName = 'MuiSelect';

export default MuiSelect;
