import React from 'react';
import { components } from 'react-select';

// Custom dropdown arrow for react-select
const DropdownIndicator = props => (
    components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
            <i
                style={{
                    fontSize: '15px',
                    color: '#38424a',
                }}
                className={props.selectProps.menuIsOpen ?
                    'fa fa-caret-up' :
                    'fa fa-caret-down'
                }
            />
        </components.DropdownIndicator>
    )
);

export default DropdownIndicator;
