import API from './index';
import methods from '../constants/methods';
import { UriBuilder } from '../helpers/UriBuilder';
import Commons from '../helpers/Commons';
import CLAPI from './clapi';
import cruxProxy from './cruxProxy';

export const USER_PREF = {
    MARKET_INSIGHTS_AGREEMENT_NAME: 'MARKET_INSIGHTS_AGREEMENT',
    GET_ERROR_MESSAGE: 'Unable to retrieve preference',
    POST_ERROR_MESSAGE: 'Unable to update preference',
};

const MANAGE_BRANDING_URI = 'user/manage/theme';
const VALIDATE_ONLY = 'validateOnly';
const USER_PREF_PATH = '/api/userpref';

const headerWithXsrf = {
    'Content-Type': 'application/json',
    ...cruxProxy.getXsrfHeader(),
};

const buildPageableParams = (urlBuilder, params) => {
    if (params && !Commons.isObjectEmpty(params)) {
        Object.keys(params).forEach(key => urlBuilder.addParam(key, params[key]));
    }
};

const setupRequestForSettings = (clAppAccountUserGuid) => {
    let path;
    if (!clAppAccountUserGuid) {
        path = `user/settings`; // GET method
    } else {
        path = `user/${clAppAccountUserGuid}/settings`; // POST method
    }
    const endpoint = new UriBuilder(`${USER_PREF_PATH}/${path}`).build();

    return {
        path: endpoint,
        protected: true,
    };
};

const getUserDetail = (clAppAccountUserGuid) => {
    const requestOptions = {
        path: `${USER_PREF_PATH}/app/acct/user/details/${clAppAccountUserGuid}`,
        protected: true,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const updateRequest = (domain, clAppAccountUserGuid, uri) => {
    const endpoint = `${USER_PREF_PATH}/user/account/${clAppAccountUserGuid}/${uri}`;
    return {
        path: endpoint,
        protected: true,
    };
};

const setupAccountDetailsRequest = (domain, accountNumber) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const endpoint = `${USER_PREF_PATH}/customer/account/${accountNumber}?${appAccountUserGuid}`;
    return {
        path: endpoint,
        protected: true,
        timeout: API.getTimeouts().large,
    };
};

const setupAddAccountAddressRequest = (domain, accountNumber) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const endpoint = `${USER_PREF_PATH}/customer/account/${accountNumber}/addresses?${appAccountUserGuid}`;
    return {
        path: endpoint,
        protected: true,
        timeout: API.getTimeouts().large,
    };
};

const getUserSettings = () => {
    const requestOptions = setupRequestForSettings();
    const headerOptions = {
        method: methods.GET.method,
    };

    return new Promise((resolve, reject) => {
        API.initiateSessionApiCall(requestOptions, headerOptions)
            .then((response) => {
                resolve(response.userSettings);
            }).catch((error) => {
                reject(error.message);
            });
    });
};

// newSetting = { name:'TARGET_SETTING_NAME', value: 'NEW_VALUE' }
const updateUserSettings = (newSetting) => {
    const requestOptions = setupRequestForSettings(CLAPI.getSelectedClAppAccountUserGuid());

    const requestBody = {
        userSettings: [
            newSetting,
        ],
    };
    const headerOptions = {
        method: methods.POST.method,
        body: requestBody,
        headers: headerWithXsrf,
    };

    return new Promise((resolve, reject) => {
        API.initiateSessionApiCall(requestOptions, headerOptions)
            .then((response) => {
                resolve(response?._links?.self?.href);
            }).catch((error) => {
                reject(error?.message);
            });
    });
};

const updateWorkDetails = (config, sub, request) => {
    const requestOptions = updateRequest(config, CLAPI.getSelectedClAppAccountUserGuid(), 'workdetails');
    const headerOptions = {
        method: methods.PUT.method,
        body: request,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const updatePersonalDetails = (domain, sub, request) => {
    const requestOptions = updateRequest(domain, CLAPI.getSelectedClAppAccountUserGuid(), 'details');
    const headerOptions = {
        method: methods.PUT.method,
        body: request,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getUserSummary = (selectedClAppAccountUserGuid) => {
    const path = `app/acct/user/${CLAPI.getSelectedClAppAccountUserGuid(selectedClAppAccountUserGuid)}/summary`;
    const endpoint = new UriBuilder(`${USER_PREF_PATH}/${path}`).build();

    const requestOptions = {
        path: endpoint,
        protected: true,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    requestOptions.timeout = API.getTimeouts().large;
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const changePassword = (domain, sub, request) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const path = `${USER_PREF_PATH}/user/password/change?${appAccountUserGuid}`;
    const requestOptions = {
        path,
        protected: true,
    };
    const headerOptions = {
        method: methods.POST.method,
        body: request,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getSecurityQuestion = (domain) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const path = `${USER_PREF_PATH}/user/secretQuestion/?${appAccountUserGuid}`;
    const requestOptions = {
        path,
        protected: true,
    };
    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const updateSecurityQuestion = (domain, requestParams) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const path = `${USER_PREF_PATH}/user/secretQuestion/?${appAccountUserGuid}`;
    const requestOptions = {
        path,
        protected: true,
    };
    const headerOptions = {
        method: methods.POST.method,
        body: requestParams,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getExportsActivity = () => {
    const path = `${USER_PREF_PATH}/user/export/${CLAPI.getSelectedClAppAccountUserGuid()}/limit`;

    const requestOptions = {
        path,
        protected: true,
        timeout: API.getTimeouts().large,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const saveExportProperties = (requestParams) => {
    const path = `${USER_PREF_PATH}/user/export/${CLAPI.getSelectedClAppAccountUserGuid()}`;

    const requestOptions = {
        path,
        protected: true,
        timeout: API.getTimeouts().large,
    };
    const headerOptions = {
        method: methods.POST.method,
        body: requestParams,
        headers: headerWithXsrf,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getAppConfigurations = selectedClAppAccountUserGuid => API.initiateSessionApiCall({
    path: `${USER_PREF_PATH}/app/${CLAPI.getSelectedClAppAccountUserGuid(selectedClAppAccountUserGuid)}/configurations`,
    protected: true,
    timeout: API.getTimeouts().small,
}, { method: methods.GET.method });

const getAccountDetails = (domain, accountNumber) => {
    const requestOptions = setupAccountDetailsRequest(domain, accountNumber);
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const updateAccountDetails = (domain, accountNumber, body) => {
    const requestOptions = setupAccountDetailsRequest(domain, accountNumber);
    const headerOptions = {
        method: methods.PATCH.method,
        body,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const addAccountAddress = (domain, accountNumber, body) => {
    const requestOptions = setupAddAccountAddressRequest(domain, accountNumber);
    const headerOptions = {
        method: methods.POST.method,
        body,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getUsers = (domain, accountNumber, filters = {}) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/manage/${accountNumber}`);

    Object.keys(filters).reverse().forEach((key) => {
        const filter = filters[key];
        if (filter) {
            path.addParam(key, filter);
        }
    });
    path.addParam('clAppAccountUserGuid', CLAPI.getSelectedClAppAccountUserGuid());

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const setUserAdminRole = (domain, sub, isChecked) => {
    const path = `${USER_PREF_PATH}/user/manage/${sub}/admin/${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path,
        protected: true,
        timeout: API.getTimeouts().large,
    };

    const headerOptions = {
        method: isChecked
            ? methods.PUT.method
            : methods.DELETE.method,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const postInviteUsers = (domain, emails = [], redirectUri) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const path = `${USER_PREF_PATH}/user/invite?${appAccountUserGuid}`;
    const requestOptions = {
        path,
        protected: true,
        timeout: API.getTimeouts().large,
    };

    const headerOptions = {
        method: methods.POST.method,
        body: { emails, redirectUri },
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const removeUser = (domain, sub) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/app/acct/user/association/${sub}/${CLAPI.getSelectedClAppAccountUserGuid()}`);
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().small,
    };

    const headerOptions = {
        method: methods.DELETE.method,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const setCanOrderTitles = (domain, sub, isChecked) => {
    const path = `${USER_PREF_PATH}/user/manage/${sub}/${CLAPI.getSelectedClAppAccountUserGuid()}/titles`;

    const requestOptions = {
        path,
        protected: true,
        timeout: API.getTimeouts().large,
    };

    const headerOptions = {
        method: methods.POST.method,
        body: {
            canOrderTitles: isChecked,
        },
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const setCanPayInArrears = (checked, sub) => {
    const path = `${USER_PREF_PATH}/user/manage/${sub}/${CLAPI.getSelectedClAppAccountUserGuid()}/payInArrears`;
    const requestOptions = {
        path,
        protected: true,
        timeout: API.getTimeouts().large,
    };

    const headerOptions = {
        method: methods.POST.method,
        body: {
            canPayInArrears: checked,
        },
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const setupThemeRequestOptions = (domain, pathVariable, maxTimeout, validateOnly) => {
    const _customerThemePoid = pathVariable || '';
    const path = new UriBuilder(`${USER_PREF_PATH}/${MANAGE_BRANDING_URI}${_customerThemePoid}`);
    if (validateOnly) {
        path.addParam(VALIDATE_ONLY, true);
    }
    path.addParam('clAppAccountUserGuid', CLAPI.getSelectedClAppAccountUserGuid());
    return {
        path: path.build(),
        protected: true,
        timeout: maxTimeout ? API.getTimeouts().large : API.getTimeouts().small,
    };
};

const getBranding = (domain) => {
    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(setupThemeRequestOptions(domain, null, true), headerOptions);
};

const addNewBranding = (domain, requestBody) => {
    const headerOptions = {
        method: methods.POST.method,
        body: requestBody,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(setupThemeRequestOptions(domain), headerOptions);
};

const editBranding = (domain, requestBody, customerThemePoid) => {
    const headerOptions = {
        method: methods.PUT.method,
        body: requestBody,
        headers: headerWithXsrf,
    };

    return API
        .initiateSessionApiCall(setupThemeRequestOptions(domain, `/${customerThemePoid}`), headerOptions);
};

const deleteBranding = (domain, customerThemePoid) => {
    const headerOptions = {
        method: methods.DELETE.method,
        headers: headerWithXsrf,
    };

    return API
        .initiateSessionApiCall(setupThemeRequestOptions(domain, `/${customerThemePoid}`), headerOptions);
};

const activateTheme = (domain, customerThemePoid) => {
    const headerOptions = {
        method: methods.PUT.method,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(setupThemeRequestOptions(domain, `/${customerThemePoid}/activate`), headerOptions);
};

const uploadThemeLogo = (domain, body, themeId, validateOnly) => {
    const headerOptions = {
        method: methods.POST.method,
        body,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(setupThemeRequestOptions(domain, `/upload/logo/${themeId}`, true, validateOnly), headerOptions);
};

const removeThemeLogo = (domain, customerThemePoid) => {
    const headerOptions = {
        method: methods.DELETE.method,
        headers: headerWithXsrf,
    };

    return API
        .initiateSessionApiCall(setupThemeRequestOptions(domain, `/upload/logo/${customerThemePoid}`), headerOptions);
};

const removePaymentAddress = (domain, addressNumber) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const path = new UriBuilder(`${USER_PREF_PATH}/customer/account/addresses/${addressNumber}?${appAccountUserGuid}`);
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    const headerOptions = {
        method: methods.DELETE.method,
        headers: headerWithXsrf,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};


const uploadUserPhoto = (domain, body, validateOnly = false) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/upload/photo`);
    if (validateOnly) {
        path.addParam(VALIDATE_ONLY, true);
    }
    path.addParam('clAppAccountUserGuid', CLAPI.getSelectedClAppAccountUserGuid());

    const headerOptions = {
        method: methods.PUT.method,
        body,
        headers: headerWithXsrf,
    };

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const deleteUserPhoto = () => {
    const appAccountUserGuid = `clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`;
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/upload/photo?${appAccountUserGuid}`);
    const headerOptions = {
        method: methods.DELETE.method,
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const tncPermanentlyAccepted = () => API.initiateSessionApiCall(
    {
        path: `${USER_PREF_PATH}/terms-and-conditions/permanently-accepted`,
        protected: true,
        timeout: API.getTimeouts().large,
    },
    {
        method: methods.GET.method,
    });

const getTermsAndConditionsTextContent = () => API.initiateSessionApiCall(
    {
        path: `${USER_PREF_PATH}/terms-and-conditions`,
        protected: true,
        timeout: API.getTimeouts().large,
    },
    {
        method: methods.GET.method,
    });

const setTncPermanentlyAccepted = (isChecked, templateName) => {
    const requestOptions = {
        path: `${USER_PREF_PATH}/terms-and-conditions/permanently-accepted`,
        protected: true,
        timeout: API.getTimeouts().large,
    }
    const headerOptions = {
        method: methods.PUT.method,
        body: {
            permanentAgreed: isChecked,
            name: templateName,
        },
        headers: headerWithXsrf,
    }
    return API.initiateSessionApiCall(requestOptions, headerOptions);
}

const getPropertiesCountOnWatchList = (clAppAcctUserGuid) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/watchlist/counts`);
    const headerOptions = { method: methods.GET.method };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};
const getSavedLists = (clAppAcctUserGuid, params) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/savedlist`);

    buildPageableParams(path, params);

    // sort by created date by default
    path.addParam('sort', 'created,desc');

    const headerOptions = { method: methods.GET.method };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getSavedTerritories = (clAppAcctUserGuid, params = {}) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/territories`);

    // This sets the size to default 25
    if (!params?.size) {
        params.size = 25;
    }

    buildPageableParams(path, params);

    path.addParam('sort', 'createdDate,desc');

    const headerOptions = { method: methods.GET.method };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const createSavedList = (clAppAcctUserGuid, requestBody) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/savedlist`);

    const headerOptions = {
        method: methods.POST.method,
        body: requestBody,
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const createSavedTerritory = (clAppAcctUserGuid, requestBody) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/territory`);

    const headerOptions = {
        method: methods.POST.method,
        body: requestBody,
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};


const renameSavedList = (clAppAcctUserGuid, savedListId, requestBody) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/savedlist/${savedListId}`);

    const headerOptions = {
        method: methods.PUT.method,
        body: requestBody,
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const deleteSavedList = (clAppAcctUserGuid, savedListId) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/savedlist/${savedListId}`);

    const headerOptions = {
        method: methods.DELETE.method,
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const deleteSavedTerritories = (clAppAcctUserGuid, savedTerritoriesId) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/territory/${savedTerritoriesId}`);

    const headerOptions = {
        method: methods.DELETE.method,
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const manageSavedProperties = (clAppAcctUserGuid, savedListId, propertyIds, method) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/savedlist/${savedListId}/properties`);

    const headerOptions = {
        method,
        body: {
            propertyIds,
        },
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const addPropertyToSavedList = (clAppAcctUserGuid, savedListId, properties) => {
    return manageSavedProperties(
        clAppAcctUserGuid,
        savedListId,
        properties,
        methods.PUT.method,
    );
};

const deletePropertyToSavedList = (clAppAcctUserGuid, savedListId, properties) => {
    return manageSavedProperties(
        clAppAcctUserGuid,
        savedListId,
        properties,
        methods.DELETE.method,
    );
};

const manageWatchedProperties = (clAppAcctUserGuid, propertyIds, method) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/watchlist`);

    const headerOptions = {
        method,
        body: {
            propertyIds,
        },
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const addPropertyToWatchList = (clAppAcctUserGuid, properties) => {
    return manageWatchedProperties(
        clAppAcctUserGuid,
        properties,
        methods.POST.method,
    );
};

const deletePropertyToWatchList = (clAppAcctUserGuid, properties) => {
    return manageWatchedProperties(
        clAppAcctUserGuid,
        properties,
        methods.PUT.method,
    );
};

const getSavedPropertyInfo = (clAppAcctUserGuid, propertyIds, multiple = false) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/property/listInfo`);

    if (multiple) {
        path.addSlug('multiple');
    }

    const headerOptions = {
        method: methods.POST.method,
        body: {
            propertyIds,
        },
        headers: headerWithXsrf,
    };

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getPropertiesOnList = (pathBuilder) => {
    const headerOptions = {
        method: methods.GET.method,
        headers: headerWithXsrf,
    };

    const requestOptions = {
        path: pathBuilder.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
}

const getPropertiesOnSavedList = (clAppAcctUserGuid, savedListId, params) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/savedlist/${savedListId}/properties`);

    buildPageableParams(path, { sort: 'propertyId', ...params });

    return getPropertiesOnList(path);
};

const getPropertiesDetailsOnSavedList = (clAppAcctUserGuid, savedListId, params) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/savedlist/${savedListId}/properties/details`);

    buildPageableParams(path, { sort: 'propertyId', ...params });

    return getPropertiesOnList(path);
};

const getPropertiesOnWatchList = (clAppAcctUserGuid, savedListId, params) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/watchlist`);

    buildPageableParams(path, { sort: 'propertyId', ...params });

    return getPropertiesOnList(path);
};

const getPropertiesDetailsOnWatchList = (clAppAcctUserGuid, savedListId, params) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/watchlist/details`);

    buildPageableParams(path, { sort: 'propertyId', ...params });

    return getPropertiesOnList(path);
};

const getWatchListAlerts = (clAppAcctUserGuid) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/watchlist/alerts`);
    const headerOptions = { method: methods.GET.method, headers: headerWithXsrf };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const updateLastAccessedDate = (clAppAcctUserGuid) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/lastAccessed`);
    const headerOptions = { method: methods.PUT.method, headers: headerWithXsrf };
    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const updateSavedTerritory = (clAppAcctUserGuid, territoryId, requestBody) => {
    const headerOptions = {
        method: methods.PUT.method,
        body: requestBody,
        headers: headerWithXsrf,
    };
    const requestOptions = {
        path: `${USER_PREF_PATH}/user/account/${clAppAcctUserGuid}/territory/${territoryId}`,
        protected: true,
        timeout: API.getTimeouts().small,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getAllREITokens = (clAppAcctUserGuid) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/rei/connection?clAppAccountUserGuid=${clAppAcctUserGuid}`);
    const headerOptions = {
        method: methods.GET.method,
        headers: headerWithXsrf,
    };

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const connectReiToken = (clAppAcctUserGuid, requestBody) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/rei/connection?clAppAccountUserGuid=${clAppAcctUserGuid}`);
    const headerOptions = {
        method: methods.POST.method,
        headers: headerWithXsrf,
        body: requestBody,
    };

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const disconnectREIToken = (clAppAcctUserGuid, requestBody) => {
    const path = new UriBuilder(`${USER_PREF_PATH}/user/account/rei/connection?clAppAccountUserGuid=${clAppAcctUserGuid}`);
    const headerOptions = {
        method: methods.PUT.method,
        body: requestBody,
        headers: headerWithXsrf,
    };

    const requestOptions = {
        path: path.build(),
        protected: true,
        timeout: API.getTimeouts().large,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

export default {
    getUserDetail,
    getUserSettings,
    updateUserSettings,
    updateWorkDetails,
    updatePersonalDetails,
    getUserSummary,
    changePassword,
    getSecurityQuestion,
    updateSecurityQuestion,
    getExportsActivity,
    saveExportProperties,
    getAppConfigurations,
    getAccountDetails,
    updateAccountDetails,
    addAccountAddress,
    getUsers,
    setUserAdminRole,
    removeUser,
    postInviteUsers,
    setCanOrderTitles,
    getBranding,
    addNewBranding,
    editBranding,
    deleteBranding,
    activateTheme,
    removePaymentAddress,
    uploadThemeLogo,
    removeThemeLogo,
    uploadUserPhoto,
    deleteUserPhoto,
    getPropertiesCountOnWatchList,
    getSavedLists,
    createSavedList,
    deleteSavedList,
    renameSavedList,
    addPropertyToSavedList,
    deletePropertyToSavedList,
    addPropertyToWatchList,
    deletePropertyToWatchList,
    getSavedPropertyInfo,
    tncPermanentlyAccepted,
    setTncPermanentlyAccepted,
    getTermsAndConditionsTextContent,
    getPropertiesOnSavedList,
    getPropertiesDetailsOnSavedList,
    getPropertiesOnWatchList,
    getWatchListAlerts,
    getSavedTerritories,
    deleteSavedTerritories,
    createSavedTerritory,
    updateLastAccessedDate,
    getPropertiesDetailsOnWatchList,
    updateSavedTerritory,
    setCanPayInArrears,
    getAllREITokens,
    connectReiToken,
    disconnectREIToken,
};
