import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import CustomControl from './CustomControl';

class HomeButton extends PureComponent {
    static propTypes = {
        buttonTooltipClass: PropTypes.string,
        onHomeClick: PropTypes.func,
    };
    render() {
        const {
            buttonTooltipClass,
            onHomeClick,
        } = this.props;

        return (
            <CustomControl
                // eslint-disable-next-line
                position={google.maps.ControlPosition.RIGHT_BOTTOM}
            >
                <div className="home-container" data-testid="home-container">
                    <div
                        className={`home-button ${buttonTooltipClass || ''}`}
                        onClick={onHomeClick}
                        data-testid="home-button"
                    >
                        <div className="fa fa-home" />
                        <div className="__react_component_tooltip home-button-tooltip type-dark">
                            <span>Home</span>
                        </div>
                    </div>
                </div>
            </CustomControl>
        );
    }
}

export default HomeButton;
