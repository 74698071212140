import { createTheme } from '@mui/material/styles';
import Colors from '../constants/colors';
import { POPPINS_FONT } from '../constants/crux';

// main theme options
const options = {
    palette: {
        primary: {
            main: Colors.PRIMARY.MAIN,
            contrastText: Colors.WHITE,
        },
        secondary: {
            main: Colors.SECONDARY.MAIN,
            contrastText: Colors.WHITE,
        },
        pale: {
            light: Colors.SECONDARY.LIGHT,
            main: Colors.WHITE,
            dark: Colors.BLACK_4_OPAQUE,
            contrastText: Colors.PRIMARY.MAIN,
        },
        paleSecondary: {
            light: Colors.SECONDARY.LIGHT,
            main: Colors.WHITE,
            dark: Colors.BLACK_4_OPAQUE,
            contrastText: Colors.BLACK_54_OPAQUE,
        },
        offWhite: {
            light: Colors.DIRTY_WHITE,
            main: Colors.OFF_WHITE,
        },
        warning: {
            main: Colors.PENDING_FILL_COLOR
        },
        error: {
            main: Colors.ERROR.TEXT,
        },
    },
    typography: {
        fontFamily: POPPINS_FONT,
        fontSize: 13,
        h3: {
            color: Colors.SECONDARY.MAIN,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
        },
        h4: {
            color: Colors.SECONDARY.MAIN,
            fontWeight: 500,
            fontSize: '20px',
        },
        h5: {
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '32.02px',
            marginBottom: 24,
        },
        h6: {
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '32px',
            letterSpacing: '0.15px',
        },
        subtitle2: {
            fontWeight: 500,
            letterSpacing: '0.1px',
            color: Colors.BLACK_87_OPAQUE,
        },
        h7: {
            color: Colors.SECONDARY.MAIN,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            fontWeight: 500,
        },
        subtitle3: {
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: '0.1px',
            color: Colors.BLACK_87_OPAQUE,
        },
        button: {
            textTransform: 'none',
            fontWeight: 400,
            height: '32px',
            fontSize: '13px',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '5px 12px',
                    '&.Mui-disabled': {
                        color: 'white',
                        backgroundColor: Colors.DISABLED,
                        opacity: 0.6,
                    },
                    '&.Mui-disabled.inverted': {
                        color: Colors.DISABLED,
                        backgroundColor: 'white',
                        opacity: 0.6,
                    },
                },
                containedPale: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                containedSecondary: {
                    '& img': {
                        width: 14,
                    }
                }
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled.Mui-selected': {
                        color: Colors.PRIMARY.MAIN,
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                cellCheckbox: {
                    '& .Mui-checked': {
                        color: Colors.SECONDARY.MAIN,
                    }
                }
            }
        }
    },
    overrides: {
        components: {
            MuiPaper: {
                styleOverrides: {
                    rounded: {
                        borderRadius: 6,
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            tablet: 992,
        }
    }
};

const mainAppTheme = createTheme(options);
const accountDetailsTabTheme = createTheme({
    ...options,
    palette: {
        ...options.palette,
        error: {
            main: Colors.POMEGRANATE,
        }
    },
    typography: {
        ...options.typography,
        fontSize: 13,
        h6: {
            fontWeight: 500,
            lineHeight: '32px',
            fontSize: '20px',
            letterSpacing: '0.15px',
        },
        body1: {
            color: Colors.SECONDARY.MAIN,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
        },
        body2: {
            color: Colors.SECONDARY.MAIN,
            fontSize: '14px',
        },
        body_1_color_60: {
            color: Colors.BLACK_60_OPAQUE,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
        },
        subtitle2: {
            fontWeight: 500,
            letterSpacing: '0.1px',
            color: Colors.BLACK_60_OPAQUE,
        },
        caption: {
            fontSize: '12px',
            lineHeight: '20px',
            fontWeight: 400,
            letterSpacing: '0.4px',
            color: '#000000',
            opacity: 0.6,
        },
    },
    overrides: {
        ...options.overrides.components,
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&:hover:not($disabled):not($focused):not($error) .MuiOutlinedInput-notchedOutline': {
                            borderColor: Colors.PRIMARY.MAIN,
                        },
                    },
                },

            },
            MuiTab: {
                styleOverrides: {
                    textColorPrimary: {
                        textTransform: 'none',
                        fontWeight: 500,
                        lineHeight: '22px',
                        letterSpacing: '0.46px',
                        fontSize: '13px',
                        flexGrow: 1,
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    option: {
                        fontSize: '13px',
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: '13px',
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        '&:focus': {
                            backgroundColor: 'none',
                        },
                    },
                },
            },
        },
    },
});
const userFavoritesTheme = createTheme({
    palette: {
        primary: {
            main: Colors.PRIMARY.MAIN,
        },
        addedPropertyNotification: {
            main: '#4CAF50',
        },
        removedPropertyNotification: {
            main: '#64748B',
        },
    },
});

export default {
    mainAppTheme,
    accountDetailsTabTheme,
    userFavoritesTheme,
};
