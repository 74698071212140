import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = props => (
    <span data-testid={`tool-tip-info-icon`} className="fa-stack fa-lg icon-container" style={props.style}>
        <i className="fa fa-info fa-stack-1x" data-testid="fa-info"/>
        <i className="fa fa-circle-thin fa-stack-2x"  data-testid="fa-circle-thin"/>
    </span>
);

InfoIcon.propTypes = {
    style: PropTypes.object,
};

export default InfoIcon;
