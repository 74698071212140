import { Map as IMap } from 'immutable';
import { CRUX_APP_ERROR } from '../actions/errorHandler';
import * as ClapiAction from '../actions/clapi';
import { LOWERCASE_ALL } from '../constants/rapidSearch';

export const initialState = () => IMap({
    searchResults: null,
    searchTabSelected: LOWERCASE_ALL,
    propertySummaries: [],
    isFetchingAdditionalDetails: false,
    searchFilters: null, // searchFiltersV2
    statusType: LOWERCASE_ALL,
    limit: null,
    pageLimitOffset: null,
});

export default function reducer(state = initialState(), action = {}) {
    switch (action.type) {
        case ClapiAction.UPDATE_SEARCH_FILTERS_OFFSET: {
            const { propertyId, isNonAddressSearch, ...rest } = action.payload;
            return state.merge(IMap({
                searchFilters: { ...state.get('searchFilters'), ...rest },
            }));
        }
        case ClapiAction.SET_SAVED_LIST_FILTERS:
        case ClapiAction.SET_SEARCH_FILTERS_V2: {
            const { propertyId, isNonAddressSearch, ...rest } = action.payload;
            return state.merge(IMap({
                searchFilters: { ...rest },
            }));
        }
        case CRUX_APP_ERROR: case ClapiAction.CLEAR_SEARCH_FILTERS:
            return initialState();
        case ClapiAction.GET_SEARCH_RESULTS_SUCCESS:
        case ClapiAction.GET_SAVED_LIST_RESULT_SUCCESS:
        case ClapiAction.GET_RAPID_NAME_SEARCH_RESULT_SUCCESS:
            if (action.payload) {
                return state.merge(IMap({
                    searchResults: action.payload,
                }));
            }
            return state;
        case ClapiAction.CLEAR_SEARCH_RESULTS:
            return state.merge(IMap({
                searchResults: null,
            }));
        case ClapiAction.UPDATE_SEARCH_RESULTS:
            return state.merge(IMap({
                searchResults: action.payload,
            }));
        case ClapiAction.SHOW_MORE_SUCCESS:
            return state.merge(IMap({
                searchResults: {
                    data: [...state.get('searchResults')?.data, ...action?.payload?.data],
                    metadata: action?.payload?.metadata,
                },
            }));
        case ClapiAction.GET_PROPERTY_DETAILS_BY_IDS:
            return state.merge(IMap({
                isFetchingAdditionalDetails: true,
            }));
        case ClapiAction.GET_PROPERTY_DETAILS_BY_IDS_SUCCESS:
            return state.merge(IMap({
                isFetchingAdditionalDetails: false,
                propertySummaries: state.get('propertySummaries').concat(action.payload.propertySummaryList),
            }));
        case ClapiAction.GET_PROPERTY_DETAILS_BY_IDS_FAIL:
            return state.merge(IMap({
                isFetchingAdditionalDetails: false,
                propertySummaries: state.get('propertySummaries').concat(action.payload),
            }));
        case ClapiAction.CLEAR_SEARCH_RESULTS_PROPERTY_SUMMARIES:
            return state.merge(IMap({
                propertySummaries: [],
            }));
        case ClapiAction.SET_SEARCH_STATUS_TYPE: {
            return state.merge(IMap({
                statusType: action.payload,
            }));
        }
        case ClapiAction.UPDATE_SAVED_LIST_FILTERS_LIMIT:
        case ClapiAction.UPDATE_SEARCH_FILTERS_LIMIT: {
            return state.merge(IMap({
                limit: action.payload,
                // The `pageLimitOffset` resets the offset to 0.
                // It should be used on Per Page Limit dropdown only.
                pageLimitOffset: '0',
            }));
        }
        case ClapiAction.CLEAR_PAGE_LIMIT_OFFSET: {
            return state.merge(IMap({
                pageLimitOffset: null,
            }));
        }
        default:
            return state;
    }
}
