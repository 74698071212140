export const SET_SELECTED_PROPERTY_MARKER = 'SET_SELECTED_PROPERTY_MARKER';
export const SET_NEARBY_SCHOOL_ACTIVE_TAB = 'SET_NEARBY_SCHOOL_ACTIVE_TAB';
export const FLOAT_HEADER = 'FLOAT_HEADER';
export const TOGGLE_SUGGESTIONS_LOADER = 'TOGGLE_SUGGESTIONS_LOADER';
export const SAVE_SEARCH_TYPE = 'SAVE_SEARCH_TYPE';
export const SET_SNACK_BAR = 'SET_SNACK_BAR';

export function setSelectedPropertyMarker(payload) {
    return {
        type: SET_SELECTED_PROPERTY_MARKER,
        payload,
    };
}

export function setNearbySchoolActiveTab(payload) {
    return {
        type: SET_NEARBY_SCHOOL_ACTIVE_TAB,
        payload,
    };
}

export function floatHeader(payload) {
    return {
        type: FLOAT_HEADER,
        payload,
    };
}

export function toggleSuggestionLoader(state = false, error = false) {
    return {
        type: TOGGLE_SUGGESTIONS_LOADER,
        payload: { isLoadingSuggestion: state, hasError: error },
    };
}


export function saveSearchType(selectedSearchOption) {
    return {
        type: SAVE_SEARCH_TYPE,
        payload: selectedSearchOption,
    };
}

export function setSnackBar(display= false, severity = null, message = null) {
    return {
        type: SET_SNACK_BAR,
        payload: { display, severity, message },
    }
}
