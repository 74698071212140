export const OTM_CAMPAIGNS = {
    LISTING: 'sales',
    RENTAL: 'rent',
};

export const OTM_ADVERTISEMENT_TYPE = {
    FOR_SALE: 'for-sale-advertisements',
    FOR_RENT_ADVERTISEMENT: 'for-rent-advertisements',
};

const OTM = {
    ALL: 'all',
    RECENT_SALE: 'recentSale',
    FOR_SALE: 'forSale',
    FOR_RENT: 'forRent',
    AGED: 'aged',
    WITHDRAWN: 'withdrawn',
    TENURE: 'tenure',
};

export default OTM;
