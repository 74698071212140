import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownModal from '../common/modals/DropdownModal';

export default class DropdownSelect extends Component {
    static propTypes = {
        overrideDropdown: PropTypes.bool,
        dropdownChildClass: PropTypes.any,
        classNameForEvent: PropTypes.string,
        customClassName: PropTypes.string,
        placeholder: PropTypes.string,
        customIcon: PropTypes.element,
        value: PropTypes.any,
    };

    state = {
        isOptionsVisible: false,
    };

    handleClick = () => {
        this.setState(prevState => ({
            isOptionsVisible: !prevState.isOptionsVisible,
        }));
    }

    render() {
        const dropdownChildClass = this.props.dropdownChildClass ? this.props.dropdownChildClass : '';
        const customClassName = this.props.customClassName ? ` ${this.props.customClassName}` : '';
        return (
            <div
                ref={node => this.node = node}
                className={
                    `dropdown-select${this.state.isOptionsVisible ? ' open' : ''}${customClassName}`
                }
            >
                <div className="dropdown-heading" onClick={this.handleClick}>
                    <span id="dropdownSelectedValue" className="dropdown-heading-value">
                        { !this.props.value && this.props.placeholder
                            ? <span className="placeholder">{ this.props.placeholder }</span>
                            : this.props.value }
                    </span>
                    {
                        this.props.customIcon
                            ? <span className="custom-icon">{this.props.customIcon}</span>
                            : <i className={`${this.state.isOptionsVisible ?
                                'fa fa-caret-up' :
                                'fa fa-caret-down'}`}
                            />
                    }
                </div>
                {this.state.isOptionsVisible &&
                    <DropdownModal
                        classNameForEvent={this.props.classNameForEvent}
                        customClass={`dropdown-options ${dropdownChildClass}`}
                        isVisible={this.state.isOptionsVisible}
                        onModalClose={this.handleClick}
                        overrideDropdown={this.props.overrideDropdown}
                        >
                        {
                            this.props.children
                        }
                    </DropdownModal>
                }
            </div>
        );
    }
}
