import moment from 'moment/moment';
import Export from '../constants/export';
import { isAU } from '../constants/crux';
import Commons from './Commons';
import PropertyAttrFormatter from './PropertyAttrFormatter';
import Parcels from './Parcels';
import Strings from './Strings';
import SearchFilterHelper from './search/SearchFilterHelper';
import SearchResultsFunctions from './search/SearchResultsFunctions';
import { routeCodes } from '../constants/routes';
import ListPropertyPreview from '../constants/listPropertyPreview';
import FileHelper from './FileHelper';

const getExportDisplayOptions = (isDirectMarketing) => {
    let displayOptions;
    if (isDirectMarketing) {
        displayOptions = isAU
            ? Export.DISPLAY_OPTIONS_DIRECT_MARKETING_AU
            : Export.DISPLAY_OPTIONS_DIRECT_MARKETING_NZ;
    } else {
        displayOptions = isAU ? Export.DISPLAY_OPTIONS_AU : Export.DISPLAY_OPTIONS_NZ;
    }

    return JSON.parse(JSON.stringify(displayOptions));
}

const getSelectedFields = (displayOptions, selectedTab) => {
    return Object.values(displayOptions)
        .flat()
        .reduce((acc, data) => {
            if ((data?.checked && (!data?.view || data?.view.includes(selectedTab)))|| data?.values?.some(additionalFormat => additionalFormat?.value === data.value)) {
                const label = data?.label === 'Contact Export' || data?.label === 'Address to'
                    ? data?.values?.find(selectedValue => selectedValue?.value === data.value)?.label
                    : data.label;
                acc.push(label);
            }
            return acc;
        }, []);
};

const getOwnerNames = (owners, prefix = 'ownerName') => {
    const ownerNames = {};
    owners.forEach((owner, i) => {
        const {
            firstName = '',
            lastName = '',
            companyName = '',
        } = owner;
        Object.assign(ownerNames, {
            [`${prefix}${i + 1}`]: firstName || lastName ?
            `${firstName} ${lastName}`.trim() : companyName,
        });
    });
    return ownerNames;
};

const formatDate = date => (date ? Commons.formatDate(date, 'DD MMM YYYY') : '');
const formatTenureDate = (value, format) => (
    moment(new Date())
        .subtract(parseInt(value, 10), 'y')
        .format(format)
);
const formatTenureReport = value => formatTenureDate(value, 'DD MMM YYYY');
const formatPrice = price => `$${Commons.numberWithCommas(price)}`;
const formatDistance = distance => `${distance}`;
const formatSalePrice = (salePrice, isAgentAdvice, isPriceWithheld) => {
    if (salePrice === undefined) return '-';
    if (isPriceWithheld) return 'Not Disclosed';
    if (isAgentAdvice && (salePrice === 0 || salePrice === null)) return 'Not Disclosed';
    return formatPrice(salePrice);
};

const formatMinMax = (minMax, formatter, unit = '') => {
    if (!minMax) return '';
    const splitValue = `${minMax}`.split('-');
    if (splitValue.length) {
        const [_min, _max] = splitValue;
        let min = _min;
        let max = _max;
        if (typeof formatter === 'function') {
            min = _min && formatter(`${_min}${unit}`);
            max = _max && formatter(`${_max}${unit}`);
        }
        if (min && max) {
            return `${min} - ${max}`;
        } else if (min && max === undefined) {
            return min;
        } else if (min && max === '') {
            return `${min} - Max`;
        } else if (min === '' && max) {
            return `Min - ${max}`;
        }
    }
    return '';
};

const formatMinMaxArea = minMax => formatMinMax(minMax, SearchFilterHelper.convertM2ToHa, 'm2');

const formatMinMaxPrice = minMax => formatMinMax(minMax, formatPrice);

const formatMinMaxDate = minMax => formatMinMax(minMax, formatDate);

export const formatMinMaxTenureDate = minMax => formatMinMax(minMax, formatTenureReport);

const formatDOM = dom => {
    return dom === 0 || (dom && !isNaN(dom) && dom > 0) ? `${dom}+ days` : ''
}

const formatMinMaxDistance = minMax => formatMinMax(minMax, formatDistance,'km');
const formatMinMaxDistanceMeter = minMax => formatMinMax(minMax, formatDistance,'m');
const setYesNo = isYes => isYes ? 'Yes' : 'No';

// resolves missing leading zeroes in CSV when value starts with zero.
// See https://github.com/react-csv/react-csv/issues/128#issuecomment-508771837
const handleLeadingZeroInCsv = numericalValue => `=""${numericalValue}""`;

const formatContactData = (contact, key) => {
    switch (key) {
        case 'phone': {
            const { isDoNotCall = false, doNotCall = false, phoneNumber = '' } = Commons.get(contact, key);
            const getPhoneNumber = () => phoneNumber ? handleLeadingZeroInCsv(phoneNumber) : '-';
            return (isDoNotCall || doNotCall)
                ? 'Do Not Call'
                : getPhoneNumber();
        }
        case 'streetAddress': {
            const { line1 = '', line2 = '' } = Commons.get(contact, 'mailingAddress');
            return [line1, line2]
                .filter(notEmptyLine => !!notEmptyLine)
                .join(', ') || '-';
        }
        case 'gender': {
            const gender = Commons.get(contact, key);
            if (!gender) return '-';
            return gender === 'M' ? 'Male' : 'Female';
        }
        case 'mailingAddress.doNotMail':
        case 'mailingAddress.isDoNotMail': {
            const isYes = Commons.get(contact, key);
            return setYesNo(isYes);
        }
        case 'mailTo': {
            const value = Commons.get(contact, key) || '';
            if (value === 'Not Selected') return '';
            return value;
        }
        default:
            return Commons.get(contact, key) || '-';
    }
};

const formatPropertyData = (property, key, allowVictorianPrint = false, deed7 = false) => {
    const {
        owners = [], parcelList = [], titles = [], previousOwners = [],
        isCsv = false, id, addressComplete, type, subType, salesLastSoldPrice,
        salesLastSaleSource, salesLastSoldPriceIsWithheld, addressState,
        salesLastSaleTypeDescription,
        rentalLastCampaignFirstListedPrice,
        rentalLastCampaignLastListedPrice,
        rentalLastCampaignLastListedPeriod,
    } = property;
    const isAgentAdvice = SearchResultsFunctions.isRapidAgentAdvice(salesLastSaleSource);
    let excludedStates = ['VIC', 'SA', 'ACT', 'TAS', 'NT'];
    if (allowVictorianPrint && deed7) {
        excludedStates = excludedStates.filter(state => state !== 'VIC');
    }
    const isStateExcluded = excludedStates.includes(addressState);
    switch (key) {
        case 'type':
            return PropertyAttrFormatter.formatPropertyType(type, subType) || '-';
        case 'ownerName1':
        case 'ownerName2':
        case 'ownerName3': {
            if (isStateExcluded) return '-'
            return getOwnerNames(owners)[key] || '-';
        }
        case 'previousOwnerName1':
        case 'previousOwnerName2':
        case 'previousOwnerName3': {
            const [recentPreviousOwner = {}] = previousOwners;
            const ownerList = recentPreviousOwner.owners || [];
            if (ownerList.length && !isStateExcluded) {
                return getOwnerNames(ownerList, 'previousOwnerName')[key] || '-';
            }
            return '-';
        }
        case 'salesLastSaleSettlementDate': {
            if(!isAgentAdvice) {
                return formatDate(Commons.get(property, key)) || '-';
            }
            return '-';
        }
        case 'salesLastSaleContractDate':
        case 'salesLastCampaignStartDate':
        case 'salesLastCampaignEndDate':
        case 'rentalLastCampaignStartDate':
        case 'valuationAssessmentDate':
        case 'rentalLastCampaignEndDate':
            return formatDate(Commons.get(property, key)) || '-';
        case 'salesLastSoldPrice':
            return formatSalePrice(salesLastSoldPrice, isAgentAdvice, salesLastSoldPriceIsWithheld);
        case 'salesLastSaleTypeDescription':
            return SearchResultsFunctions
                .displayRapidSaleType(salesLastSaleTypeDescription, isAgentAdvice);
        case 'floorArea':
        case 'landArea': {
            // rapid area values are always in m2 http://confluence.ad.corelogic.asia/x/ywOxCg
            const area = Commons.get(property, key);
            if (!area) return '-';

            // prevents the warning issue in the xlsx files
            return isCsv ? Commons.numberWithCommas(area) : area;
        }
        case 'parcelList':
            if (isAU) {
                return parcelList.length ? Parcels.reduceParcels(parcelList, Strings.conjunct).displayValue : '-';
            }

            return titles.length ?
                PropertyAttrFormatter.formatLegalDescription(titles)
                : '-';
        case 'salesLastCampaignFirstListedPriceDescription':
        case 'salesLastCampaignLastListedPriceDescription':
            return PropertyAttrFormatter.formatPriceDescription(Commons.get(property, key));
        case 'isActiveForSaleCampaign':
        case 'isActiveForRentCampaign':
            return Commons.get(property, key) ? 'True' : 'False';
        case 'goToPropertyGuru':
        case 'goToRPData':
            return `${window.location.origin}${routeCodes.PROPERTY.path(addressComplete, id)}`;
        case 'thumbnailPhotoUrl':
            return Commons.get(property, 'imageUrls.thumbnailImageUrl');
        case 'rentalLastCampaignFirstListedPriceDescription':
            return PropertyAttrFormatter
                .formatRentalPrice(rentalLastCampaignFirstListedPrice)
                .trim();
        case 'rentalLastCampaignLastListedPriceDescription':
            return PropertyAttrFormatter.formatRentalPrice(
                rentalLastCampaignLastListedPrice,
                rentalLastCampaignLastListedPeriod,
            ).trim();
        case 'valuationCapitalValue':
        case 'valuationLandValue':
            const value = Commons.get(property, key);
            return (value && formatPrice(Commons.get(property, key))) || '-';
        case 'addressSuburb':
            let addressSuburb = Commons.get(property, key);
            const addressTown = Commons.get(property, 'addressTown');
            if (addressSuburb && addressTown) {
                addressSuburb += `, ${addressTown}`;
            } else if (addressTown) {
                addressSuburb = `${addressTown}`;
            }
            return addressSuburb || '-';
        case 'salesLastCampaignDaysOnMarket':
        case 'rentalLastCampaignDaysOnMarket':
            const dom = Commons.get(property, key);
            return PropertyAttrFormatter.formatDaysOnMarket(dom, '-');
        default:
            return Commons.get(property, key) || '-';
    }
};

const rebuildSearchFilters = {
    [ListPropertyPreview.SEARCH_TYPE.BUILDING]: (filters) => {
        const {
            addressState,
            addressBuildingName,
            addressSuburb,
        } = JSON.parse(JSON.stringify(filters));
        return {
            addressBuildingName: addressBuildingName.value,
            addressState,
            addressSuburb,
            exactSearch: addressBuildingName.exactSearch,
        };
    },
};

const downloadCsv = (csvRows, filename) => {
    const csv = csvRows.join('\n');
    const csvFile = new Blob(
        [new Uint8Array([0xEF, 0xBB, 0xBF]), csv],
        { type: 'text/csv;charset=utf-8' },
    );
    FileHelper.downloadBlob(csvFile, `${filename}.csv`);
}

export default {
    getExportDisplayOptions,
    getOwnerNames,
    formatDate,
    formatTenureDate,
    formatTenureReport,
    formatPrice,
    formatSalePrice,
    formatMinMax,
    formatPropertyData,
    formatMinMaxArea,
    formatMinMaxPrice,
    formatMinMaxDate,
    formatMinMaxDistance,
    formatMinMaxDistanceMeter,
    formatMinMaxTenureDate,
    formatDOM,
    formatContactData,
    downloadCsv,
    rebuildSearchFilters,
    getSelectedFields,
};
