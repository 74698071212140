import {
    ADDRESS_COLLECTIVE_PARAM,
    EXACT_ADDRESS_PARAM,
    IS_ACTIVE_PARAM,
    RESULTS_FORMAT,
    RESULTS_FORMAT_SORT,
    BEDS_PARAM,
    CAR_SPACES_PARAM,
    BATHS_PARAM,
    LAND_AREA_PARAM,
    YEAR_BUILT_PARAM,
    TYPE_PARAM,
    SUB_TYPE_PARAM,
    DEVELOPMENT_ZONE_DESCRIPTION_PARAM,
    LAND_USE_PARAM,
    SALES_CLASSIFICATION_PARAM,
    SALES_LAST_SOLD_PRICE_PARAM,
    SALES_LAST_SALE_CONTRACT_DATE_PARAM,
    SALES_LAST_SALE_SOURCE_PARAM,
    SALES_LAST_SALE_IS_ARMS_LENGTH_PARAM,
    SALES_LAST_CAMPAIGN_LAST_LISTED_PRICE_PARAM,
    SALES_LAST_CAMPAIGN_END_DATE_PARAM,
    SALES_LAST_CAMPAIGN_START_DATE_PARAM,
    IS_ACTIVE_FOR_SALE_CAMPAIGN_PARAM,
    RENTAL_LAST_CAMPAIGN_END_DATE_PARAM,
    RENTAL_LAST_CAMPAIGN_START_DATE_PARAM,
    IS_ACTIVE_FOR_RENT_CAMPAIGN_PARAM,
    RENTAL_LAST_CAMPAIGN_LAST_LISTED_PRICE_PARAM,
    RADIUS_SEARCH_FORMAT,
    LISTING_DATA_PARAM,
    ADDRESS_PARCEL_SUBURB_STATE_PARAM,
    TITLE_REFERENCE_PARAM,
    LEGAL_DESCRIPTION_PARAM,
    ADDRESS_BUILDING_NAME_PARAM,
    VALUATION_REFERENCE_PARAM,
    CAPITAL_VALUE_PARAM,
    VALUATION_DATE_PARAM,
    VOLUME_FOLIO_DATA_PARAM,
    SALES_LAST_CAMPAIGN_LISTED_TYPE_PARAM,
    ALL_WEATHER_ACCESS_TO_LAND_PARAM,
    ALL_WEATHER_ROADS_TO_FARM_PARAM,
    HOUSES_ON_FARM_PARAM,
    IRRIGATED_AREA_PARAM,
    DAMS_BORES_PARAM,
    PADDOCKS_YARDS_PARAM,
    PERMANENT_WATER,
    SEALED_ROADS_TO_PROPERTY,
    BOAT_RAMP_MOORING_SPACE,
    FENCED,
    GRANNY_FLAT_SELF_CONTAINED,
    LOCKUP_GARAGES,
    MATERIALS_IN_EXTERNAL_WALLS,
    MATERIALS_IN_ROOF,
    SWIMMING_POOL,
    NUMBER_OF_TENNIS_COURTS,
    TREES_ON_LAND,
    ACCESS_AVAILABLE_24_HOURS_PARAM,
    ACCESS_SECURITY_INSTALLED_PARAM,
    CLOSE_TO_PUBLIC_TRANSPORT_PARAM,
    LIFT_INSTALLED_PARAM,
    ON_MAIN_ROAD_PARAM,
    SEWAGE_PARAM,
    CURRENT_UNIMPROVED_LAND_VALUE_PARAM,
    UNIMPROVED_CAPITAL_VALUE_PARAM,
    UNIMPROVED_CAPITAL_VALUE_DATE_PARAM,
    VALUATION_LAND_VALUE,
    VALUATION_IMPROVEMENTS_VALUE,
    DISTANCE_RIVER_FRONTAGE,
    DISTANCE_SCHOOL,
    DISTANCE_SEA_RIVER_CANAL,
    DISTANCE_SHOPS,
    TOWN_SEWERAGE_AVAILABLE_PARAM,
    TOWN_WATER_AVAILABLE_PARAM,
    WATER_PARAM,
    CANAL_FRONTAGE,
    COAST_FRONTAGE,
    GOLF_FRONTAGE,
    LAKE_FRONTAGE,
    RIVER_FRONTAGE,
    SCENIC_VIEW,
    WATER_FRONTAGE,
    HIGH_EXPOSURE,
    DISTANCE_HOSPITAL,
    FACTORY_AREA_PARAM,
    MEZZANINE_AREA_PARAM,
    OFFICE_SHOWROOM_AREA_PARAM,
    OTHER_AREA_PARAM,
    SHOW_ROOM_AREA_PARAM,
    WAREHOUSE_AREA_PARAM,
    WORKSHOP_AREA_PARAM,
    FEE_CODE_PARAM,
    SHOP_AREA_PARAM,
    AIR_CONDITIONED,
    BUILT_IN_WARDROBES,
    DUCTED_HEATING,
    DUCTED_VACUUM,
    FIREPLACE,
    OPEN_PLAN,
    POLISHED_FLOORS,
    RENOVATED,
    DINING_ROOMS,
    FAMILY_RUMPUS_ROOMS,
    FLOOR_LEVELS_INSIDE,
    LOUNGE_ROOMS,
    LOUNGE_DINING_ROOMS_COMBINED,
    KITCHENS,
    NUMBER_OF_STUDY_ROOMS,
    TOILETS,
    HERITAGE_OTHER_CLASSIFICATION,
    BUILDING_AREA,
    EQUIVALENT_BUILDING_AREA,
    FLOOR_LEVEL_OF_UNIT,
    FREE_STANDING_BUILDING,
    FLOOR_AREA,
    FLOOR_LEVEL_IS_INSIDE_UNIT,
    FLATS,
    STREET_FRONTAGE,
    TOTAL_FLOORS_IN_BUILDING,
    YEAR_BUILDING_REFURBISHED,
    YEAR_ESTABLISHED,
    SALES_LAST_CAMPAIGN_DAYS_ON_MARKET,
    IS_FOR_SALE,
    SALES_LAST_SALE_SETTLEMENT_DATE_PARAM,
    TENURE_DATA_PARAM,
    ADDRESS_CONTAINS_PARAM,
    TERRITORIES_SEARCH_FORMAT,
} from '../../../constants/batchSearch/batchSearch';
import Builder from './SearchBuilder';

const getBuilderFn = (lookupObject, defaultCase = '_default') =>
    (expression, args = {}) => (lookupObject[expression] || lookupObject[defaultCase])(args);

const paramBuilders = {
    [RESULTS_FORMAT]: Builder.resultFormatBuilder,
    [RESULTS_FORMAT_SORT]: Builder.resultFormatSortBuilder,
    [ADDRESS_COLLECTIVE_PARAM]: Builder.addressCollectiveBuilder,
    [ADDRESS_CONTAINS_PARAM]: Builder.addressContainsBuilder,
    [EXACT_ADDRESS_PARAM]: Builder.exactAddressBuilder,
    [IS_ACTIVE_PARAM]: Builder.isActiveFilterBuilder,
    [BEDS_PARAM]: Builder.rangeFilterBuilder,
    [CAR_SPACES_PARAM]: Builder.rangeFilterBuilder,
    [BATHS_PARAM]: Builder.rangeFilterBuilder,
    [LAND_AREA_PARAM]: Builder.rangeFilterBuilder,
    [YEAR_BUILT_PARAM]: Builder.rangeFilterBuilder,
    [SALES_CLASSIFICATION_PARAM]: Builder.containFilterBuilder,
    [CURRENT_UNIMPROVED_LAND_VALUE_PARAM]: Builder.rangeFilterBuilder,
    [UNIMPROVED_CAPITAL_VALUE_PARAM]: Builder.rangeFilterBuilder,
    [UNIMPROVED_CAPITAL_VALUE_DATE_PARAM]: Builder.dateRangeFilterBuilder,
    [VALUATION_LAND_VALUE]: Builder.rangeFilterBuilder,
    [VALUATION_IMPROVEMENTS_VALUE]: Builder.rangeFilterBuilder,
    [TYPE_PARAM]: Builder.typeFilterBuilder,
    [SUB_TYPE_PARAM]: Builder.commaConcatenatedToJsonBuilder,
    [DEVELOPMENT_ZONE_DESCRIPTION_PARAM]: Builder.commaConcatenatedToJsonBuilder,
    [LAND_USE_PARAM]: Builder.commaConcatenatedToJsonBuilder,
    [SALES_LAST_SOLD_PRICE_PARAM]: Builder.rangeFilterBuilder,
    [CAPITAL_VALUE_PARAM]: Builder.rangeFilterBuilder,
    [SALES_LAST_SALE_CONTRACT_DATE_PARAM]: Builder.dateRangeFilterBuilder,
    [VALUATION_DATE_PARAM]: Builder.dateRangeFilterBuilder,
    [SALES_LAST_SALE_SOURCE_PARAM]: Builder.typeFilterBuilder,
    [SALES_LAST_SALE_IS_ARMS_LENGTH_PARAM]: Builder.checkboxFilterBuilder,
    [SALES_LAST_CAMPAIGN_LAST_LISTED_PRICE_PARAM]: Builder.rangeFilterBuilder,
    [SALES_LAST_CAMPAIGN_END_DATE_PARAM]: Builder.dateRangeFilterBuilder,
    [SALES_LAST_CAMPAIGN_START_DATE_PARAM]: Builder.dateRangeFilterBuilder,
    [IS_ACTIVE_FOR_SALE_CAMPAIGN_PARAM]: Builder.isActiveListingsFilterBuilder,
    [RENTAL_LAST_CAMPAIGN_LAST_LISTED_PRICE_PARAM]: Builder.rangeFilterBuilder,
    [RENTAL_LAST_CAMPAIGN_END_DATE_PARAM]: Builder.dateRangeFilterBuilder,
    [RENTAL_LAST_CAMPAIGN_START_DATE_PARAM]: Builder.dateRangeFilterBuilder,
    [IS_ACTIVE_FOR_RENT_CAMPAIGN_PARAM]: Builder.isActiveListingsFilterBuilder,
    [RADIUS_SEARCH_FORMAT]: Builder.radiusFormatBuilder,
    [LISTING_DATA_PARAM]: Builder.companyNameFilterBuilder,
    [VOLUME_FOLIO_DATA_PARAM]: Builder.volumeFolioFilterBuilder,
    [ADDRESS_PARCEL_SUBURB_STATE_PARAM]: Builder.searchFieldFilterBuilder,
    [LEGAL_DESCRIPTION_PARAM]: Builder.searchFieldFilterBuilder,
    [TITLE_REFERENCE_PARAM]: Builder.searchFieldFilterBuilder,
    [ADDRESS_BUILDING_NAME_PARAM]: Builder.buildingNameFilterBuilder,
    [TITLE_REFERENCE_PARAM]: Builder.searchFieldFilterBuilder,
    [VALUATION_REFERENCE_PARAM]: Builder.startsWithFilterBuilder,
    [SALES_LAST_CAMPAIGN_LISTED_TYPE_PARAM]: Builder.typeFilterBuilder,
    [ALL_WEATHER_ACCESS_TO_LAND_PARAM]: Builder.checkboxFilterBuilder,
    [ALL_WEATHER_ROADS_TO_FARM_PARAM]: Builder.checkboxFilterBuilder,
    [HOUSES_ON_FARM_PARAM]: Builder.rangeFilterBuilder,
    [IRRIGATED_AREA_PARAM]: Builder.rangeFilterBuilder,
    [DAMS_BORES_PARAM]: Builder.rangeFilterBuilder,
    [PADDOCKS_YARDS_PARAM]: Builder.rangeFilterBuilder,
    [PERMANENT_WATER]: Builder.checkboxFilterBuilder,
    [SEALED_ROADS_TO_PROPERTY]: Builder.checkboxFilterBuilder,
    [BOAT_RAMP_MOORING_SPACE]: Builder.isActiveListingsFilterBuilder,
    [FENCED]: Builder.isActiveListingsFilterBuilder,
    [GRANNY_FLAT_SELF_CONTAINED]: Builder.isActiveListingsFilterBuilder,
    [LOCKUP_GARAGES]: Builder.rangeFilterBuilder,
    [MATERIALS_IN_EXTERNAL_WALLS]: Builder.containFilterBuilder,
    [MATERIALS_IN_ROOF]: Builder.containFilterBuilder,
    [SWIMMING_POOL]: Builder.isActiveListingsFilterBuilder,
    [NUMBER_OF_TENNIS_COURTS]: Builder.rangeFilterBuilder,
    [TREES_ON_LAND]: Builder.isActiveListingsFilterBuilder,
    [ACCESS_AVAILABLE_24_HOURS_PARAM]: Builder.checkboxFilterBuilder,
    [ACCESS_SECURITY_INSTALLED_PARAM]: Builder.checkboxFilterBuilder,
    [CLOSE_TO_PUBLIC_TRANSPORT_PARAM]: Builder.checkboxFilterBuilder,
    [LIFT_INSTALLED_PARAM]: Builder.checkboxFilterBuilder,
    [ON_MAIN_ROAD_PARAM]: Builder.checkboxFilterBuilder,
    [SEWAGE_PARAM]: Builder.checkboxFilterBuilder,
    [TOWN_SEWERAGE_AVAILABLE_PARAM]: Builder.checkboxFilterBuilder,
    [TOWN_WATER_AVAILABLE_PARAM]: Builder.checkboxFilterBuilder,
    [WATER_PARAM]: Builder.checkboxFilterBuilder,
    [CANAL_FRONTAGE]: Builder.checkboxFilterBuilder,
    [COAST_FRONTAGE]: Builder.checkboxFilterBuilder,
    [GOLF_FRONTAGE]: Builder.checkboxFilterBuilder,
    [HIGH_EXPOSURE]: Builder.checkboxFilterBuilder,
    [LAKE_FRONTAGE]: Builder.checkboxFilterBuilder,
    [RIVER_FRONTAGE]: Builder.checkboxFilterBuilder,
    [SCENIC_VIEW]: Builder.checkboxFilterBuilder,
    [WATER_FRONTAGE]: Builder.checkboxFilterBuilder,
    [DISTANCE_HOSPITAL]: Builder.rangeFilterBuilder,
    [DISTANCE_RIVER_FRONTAGE]: Builder.rangeFilterBuilder,
    [DISTANCE_SCHOOL]: Builder.rangeFilterBuilder,
    [DISTANCE_SEA_RIVER_CANAL]: Builder.rangeFilterBuilder,
    [DISTANCE_SHOPS]: Builder.rangeFilterBuilder,
    [FACTORY_AREA_PARAM]: Builder.rangeFilterBuilder,
    [MEZZANINE_AREA_PARAM]: Builder.rangeFilterBuilder,
    [OFFICE_SHOWROOM_AREA_PARAM]: Builder.rangeFilterBuilder,
    [OTHER_AREA_PARAM]: Builder.rangeFilterBuilder,
    [SHOW_ROOM_AREA_PARAM]: Builder.rangeFilterBuilder,
    [WAREHOUSE_AREA_PARAM]: Builder.rangeFilterBuilder,
    [WORKSHOP_AREA_PARAM]: Builder.rangeFilterBuilder,
    [SHOP_AREA_PARAM]: Builder.rangeFilterBuilder,
    [FEE_CODE_PARAM]: Builder.containFilterBuilder,
    [AIR_CONDITIONED]: Builder.checkboxFilterBuilder,
    [BUILT_IN_WARDROBES]: Builder.checkboxFilterBuilder,
    [DUCTED_HEATING]: Builder.checkboxFilterBuilder,
    [DUCTED_VACUUM]: Builder.checkboxFilterBuilder,
    [FIREPLACE]: Builder.checkboxFilterBuilder,
    [OPEN_PLAN]: Builder.checkboxFilterBuilder,
    [POLISHED_FLOORS]: Builder.checkboxFilterBuilder,
    [RENOVATED]: Builder.checkboxFilterBuilder,
    [DINING_ROOMS]: Builder.rangeFilterBuilder,
    [FAMILY_RUMPUS_ROOMS]: Builder.rangeFilterBuilder,
    [FLOOR_LEVELS_INSIDE]: Builder.rangeFilterBuilder,
    [LOUNGE_ROOMS]: Builder.rangeFilterBuilder,
    [LOUNGE_DINING_ROOMS_COMBINED]: Builder.rangeFilterBuilder,
    [KITCHENS]: Builder.rangeFilterBuilder,
    [NUMBER_OF_STUDY_ROOMS]: Builder.rangeFilterBuilder,
    [TOILETS]: Builder.rangeFilterBuilder,
    [HERITAGE_OTHER_CLASSIFICATION]: Builder.containFilterBuilder,
    [BUILDING_AREA]: Builder.rangeFilterBuilder,
    [EQUIVALENT_BUILDING_AREA]: Builder.rangeFilterBuilder,
    [FLOOR_LEVEL_OF_UNIT]: Builder.rangeFilterBuilder,
    [FREE_STANDING_BUILDING]: Builder.checkboxFilterBuilder,
    [FLOOR_AREA]: Builder.rangeFilterBuilder,
    [FLOOR_LEVEL_IS_INSIDE_UNIT]: Builder.rangeFilterBuilder,
    [FLATS]: Builder.rangeFilterBuilder,
    [STREET_FRONTAGE]: Builder.rangeFilterBuilder,
    [TOTAL_FLOORS_IN_BUILDING]: Builder.rangeFilterBuilder,
    [YEAR_BUILDING_REFURBISHED]: Builder.rangeFilterBuilder,
    [YEAR_ESTABLISHED]: Builder.rangeFilterBuilder,
    [SALES_LAST_CAMPAIGN_DAYS_ON_MARKET]: Builder.isGreaterThanOrEqual,
    [IS_FOR_SALE]: Builder.isOperation,
    [SALES_LAST_SALE_SETTLEMENT_DATE_PARAM]: Builder.dateRangeFilterBuilder,
    [TENURE_DATA_PARAM]: Builder.tenureFilterBuilder,
    [TERRITORIES_SEARCH_FORMAT]: Builder.territoryFormatBuilder,
    default: Builder.defaultBuilder,
};

const withdrawnParamBuilders = {
    ...paramBuilders,
    [SALES_LAST_CAMPAIGN_END_DATE_PARAM]: Builder.withdrawnDateRangeFilterBuilder,
    [SALES_LAST_SALE_CONTRACT_DATE_PARAM]: Builder.withdrawnAndNotDateRangeFilterBuilder,
};

const batchParamBuilder = getBuilderFn(paramBuilders, 'default');
const batchWithdrawnParamBuilder = getBuilderFn(withdrawnParamBuilders, 'default');

export default {
    batchParamBuilder,
    batchWithdrawnParamBuilder,
};
