import { isAU } from './crux';

export const UNSUBSCRIBED = 'This feature is not available on your subscription';
export const NOT_IN_HISTORICAL = 'This feature is not available for historical properties';
export const NO_SUBURB_STATISTICS_REPORT = 'Sorry, the Suburb Statistics Report is not available for this property';
export const ENTITLED_EXCLUDE_VIC = 'This feature is not available for Victorian properties';
export const UNAVAILABLE = 'This feature is not currently available please try again later';
export const INITIALIZE = 'Please wait while the page completely loads';
export const TENURE_STREET_MULTILOCALITY_NOT_AVAILABLE = 'Tenure is not available for street or multiple location searches';
export const HOLD_PERIOD_INFO = 'The average number of years a property has been held between sales. The calculation includes all properties sold over the last year and is simply the difference between the most recent date of sale and the previous date of sale.';
const SUBURB_INFO = isAU ? "e.g. 'Br' will return 'Brisbane' and 'Brighton', etc." : "e.g. 'Pa' will return 'Palmerston' and 'Parnell', etc.";
export default {
    TEXT: {
        NAME_SEARCH: {
            LAST_NAME: `If you do not know the exact last name, untick 'Exact name search' and enter the first few letters to return all properties relating to that name.<br />
            <br />e.g. 'Smi' will return 'Smith' and 'Smithson', etc. <br />
            <br />*Last name is a required field.`,
            FIRST_NAME: `If you do not know the exact first name, enter the first few letters to return all properties relating to that name.<br />
            <br />e.g. 'Jo' will return 'John' and 'Joanne', etc. `,
            STATE: 'Name search is not available for properties in ACT, NT, SA, TAS and VIC.',
            STATE_VIC_ENABLED: 'Name search is not available for properties in ACT, NT, SA and TAS.',
            SUBURB: `If you do not know the exact suburb, enter the first few letters to return all properties relating to that string. <br />
            <br />${SUBURB_INFO}`,
            OWNERNAME: 'Tick to return only properties that the person currently owns',
            EXACT_NAME: 'Tick to return only properties that exactly match the search terms entered',
        },
        COMPANY_SEARCH: {
            COMPANY_NAME: `If you do not know the exact company name, 
                enter the first few letters to return all properties relating to that name.<br />
                <br />e.g. 'Sam' will return 'Sams PTY' and 'Sample Ltd', etc.<br />
                <br />*Company name is a required field.`,
            SUBURB: `If you do not know the exact suburb, enter the first few letters 
                to return all properties relating to that string.<br />
                <br />${SUBURB_INFO}`,
            STATE: 'Company search is not available for properties in ACT, NT, TAS and VIC.',
            STATE_VIC_ENABLED: 'Company search is not available for properties in ACT, NT, and TAS.',
            OWNER_NAME: 'Tick to return only properties that the person currently owns',
        },
        BUILDING_SEARCH: {
            BUILDING_NAME: `If you do not know the exact building name, 
                and enter the first few letters to return all properties relating to that name.<br />
                <br />e.g. 'Oce' will return 'Ocean' and 'Ocean Breeze', etc.<br />
                <br />*Building name is a required field.`,
            SUBURB: `If you do not know the exact suburb, enter the first few letters 
                to return all properties relating to that string.<br />
                <br />${SUBURB_INFO}`,
            EXACT_SEARCH: 'Tick to return only properties that exactly match the search terms entered',
        },
        ROLL_VALUATION_SEARCH: {
            ROLL_VALUATION: `If you do not know the exact Roll number or Valuation reference,
                enter the first few number to return all records starting with those numbers<br />
                <br />e.g. ‘123' will return '123/123' or  '1234/1’ with return '1234/123', etc.<br />
                <br />*Roll number or Valuation reference is a required field.`,
            SUBURB: `If you do not know the exact suburb, enter the first few letters 
                to return all properties relating to that string.<br />
                <br />${SUBURB_INFO}`,
        },
        VOLUME_FOLIO_SEARCH: {
            VOLUME: `If you do not know the exact volume number, 
                enter the first few numbers to return all records starting with that volume number.<br />
                <br />e.g. '123' will return  '123/123' and "1234/123', etc.<br />
                <br />*Volume number is a required field.`,
            FOLIO: `If you do not know the exact folio number,  enter the first few numbers to 
                return all records starting with the volume number and ending with that folio number.<br />
                <br />e.g. '123/12' will return '123/123' and "123/124', etc.`,
            STATE: 'Volume/Folio search is not available for properties in NSW, QLD, SA and TAS.',
            SUBURB: `If you do not know the exact suburb, enter the first few letters 
                to return all properties relating to that string.<br />
                <br />e.g. 'Br' will return 'Brisbane' and 'Brighton', etc.`,
        },
        LAND_SIZE: 'Estimated Land Size',
        TIMELINE: {
            DEVELOPMENT_APPLICATION: 'Building Permits are provided for council approved development applications.<br>' +
                '<br>Where available the permits contain the type of development, date of ' +
                'submission, the date of approval, estimated build cost, estimated development area, builder and materials listed.',
        },
        RADIUS_SEARCH: {
            DISTANCE: 'Locate properties within a specified distance from the target property, either by selecting an option from the dropdown or manually enter in a value.',
            SUBURB: 'The \'Target suburb only\' shows the closest properties only within the suburb of the Target property.',
            INCLUDE_HISTORIC: 'Tick to view original property records that have since been sub-divided or amalgamated.',
        },
        HOUSEHOLD_INFORMATION: '<p class="disclaimer-header">CoreLogic scores the occupancy type of a property using a ' +
            'number of key identifiers including the following data types; Rental history, Sales history and ' +
            'Contact/Marketing details.</p>' +
            '<p class="disclaimer-body">When available, the Occupancy Type will return 1 of the following 4 types: ‘Owner Occupied’, ‘Rented’, ‘Government Owned – Rented’, ‘Government Owned – Other’</p>' +
            '<p class="mb-0 disclaimer-footer">n.b. reference to Government owned is only available in NSW, QLD, SA & WA</p>',
        HOUSEHOLD_INFORMATION_NZ: '<p class="disclaimer-header">CoreLogic scores the occupancy type of a property using a ' +
            'number of key identifiers including the following data types; Sales ' +
            'history and Contact/Marketing details.</p>' +
            '<p class="disclaimer-body">When available the Occupancy Type will return 1 of the following 4 types: ‘Owner Occupied’, ‘Rented’, ‘Government Owned – Rented’, ‘Government Owned – Other’</p>',
        RECENT_SALE: 'This property has been sold within the last 3 months.',
        RECENT_ADVICE: 'This property has a sale provided by a source other than a government authority.',
        FOR_SALE: 'This property has been advertised for sale within the last 60 days.',
        FOR_RENT: 'This property has been advertised for rent within the last 30 days.',
        HISTORICAL: 'This property is a historic record that has since been sub-divided or amalgamated.',
        MULTISELECT: {
            COMING_SOON: 'Coming soon',
            MAX_PROPERTIES_REACH: 'This report is limited to a maximum of 500 properties.',
            WAITING_FOR_PROPERTIES: 'Fetching all the details needed for the report.',
            UNSUBSCRIBED,
            INITIALIZE,
        },
        PROPERTY_NOTES: 'Property Notes are private',
        MENU: {
            UNAVAILABLE,
            UNSUBSCRIBED,
            INITIALIZE,
        },
        MARKET_INSIGHTS: {
            AVERAGE_DAYS_ON_MARKET: 'The average number of days properties are actively listed, within the specified region. The Days on Market calculation compares the first advertised date with either the advised contract or withdrawn date on the property to calculate this metric.',
            AVERAGE_VENDOR_DISCOUNT: 'The average difference between the first and last advertised price or contract price (where provided) on a property, within the specified region. Auction listings and listings without advertised prices are excluded from the calculation.',
            WITHDRAWN_ADVICE: 'The number of for sale or for rent properties that have been withdrawn from the market and captured by CoreLogic, within the specified region. Only those sales or rental listings which CoreLogic has been able to match to an address within its database of property information have been included in this metric.',
            TOTAL_ACTIVE_RENTAL_LISTINGS: 'The number of properties that have been advertised For Rent and captured by CoreLogic within the specified region. Only those rental listings which CoreLogic has been able to match to an address within its database of property information have been included in this metric.',
            NEW_SALE_LISTINGS: 'The number of properties that have been newly advertised For Sale and captured by CoreLogic within the specified region. A new listing is one that hasn’t been previously advertised across any monitored source. Only those listings which CoreLogic has been able to match to an address within its database of property information have been included in this metric.',
            RECENTLY_ADVISED_SALES: 'The number of properties that have had a sale provided by a source other than a government authority within the specified region. Non-government sources include real estate agents and agencies, advertising portals and results collected via our auction reporting. Only those sales which CoreLogic has been able to match to an address within its database of property information have been included in this metric.',
        },
        RENTAL_ESTIMATE_CONFIDENCE: `The Confidence is based on a statistical calculation as to the probability of the Estimated Rent being accurate compared to market value. An Estimated Rent with a ‘High’ confidence is considered more reliable than an Estimated Rent with a ‘Medium’ or ‘Low’ confidence. The Confidence is a reflection of the amount of data we have on the property and similar properties in the surrounding areas. Generally, the more data we have for the local real estate market and subject property, the higher the Confidence’ level will be. Confidence should be considered alongside the Estimated Rent.<br />
        <br />Confidence is displayed as a colour coded range with red representing low confidence, through to amber which represents medium confidence to green for higher confidence.`,
        PRIVACY_COMPLIANCE: 'This is privacy compliant marketing data with contact details including name and telephone number. The data also contains indicators on any restrictions in using this information to contact prospects for sales and marketing campaigns.',
        RATING_VALUATION_TYPES: 'CV = Capital Value<br/>UCV = Land Value<br/>IV = Improvement Value',
        HISTORIC: 'Tick to view original property records that have since been sub-divided or amalgamated',
        MULTISELECT_OPTION_UNAVAILABLE: 'This option is not currently available. Please manually select your properties or use the \'Select All Displayed\' option',
        TRAINING_LINK_UNAVAILABLE: 'Training is unavailable at the moment. Please try again later.',
        COPY_TO_CLIPBOARD: 'Copy to Clipboard',
        COPIED: 'Copied',
        NON_ADMIN_TOOLTIP: 'This feature is only available to the account administrators. To access this feature, please contact your administrator.',
        ACCOUNT_DETAILS: {
            TRADING_NAME: 'This is your Corelogic Account Name',
            ACCOUNT_NUMBER: 'This is your Corelogic Account Number',
            ORGANISATION_EMAIL: 'This is your organisation\'s Primary email address',
            ORGANISATION_PHONE: 'This is your organisation\'s best contact number',
            ORGANISATION_ADDRESS: `This address will be shown on all ${isAU ? 'RP Data' : 'Property Guru'} generated reports (eg. CMA) and on invoices we email to our Payments contact unless you have specified otherwise`,
            INVOICE_ADDRESS: 'This address will be shown on invoices we email to our Payments contact unless you have specified otherwise',
            PAYMENT_CONTACT_AND_EMAIL: 'This contact will be used for billing and financial related correspondence. For example invoices will be sent here',
            SAME_PRIMARY_AND_PAYMENT_CONTACT: 'This contact will be used for account, billing and financial related correspondence. For example invoices and advice of price changes will be sent here',
            DIFF_PRIMARY_AND_PAYMENT_CONTACT: 'This contact will be used for account related correspondence. For example advice of price changes will be sent here',
        },
        MANAGE_USERS: {
            NO_ADMIN: 'This feature is only available to administrators. If you believe you should have access to this feature, please contact one of the below administrator.',
        },
        SAVED_SEARCH: {
            COMING_SOON: 'Coming soon',
            LIMIT_REACHED: 'You will only be notified of updates to 10,000 properties in this search. ' +
                'Please adjust your search criteria to reduce results below 10,000.',
        },
        TENURE: {
            NO_AVERAGE_HOLD: 'The number of properties in your search locality is too small to calculate a reliable average tenure.',
        },
    },
};
export const PANEL_NO_INFORMATION = 'There is no information available for this panel';
export const PANEL_API_ERROR = 'Sorry, there was an error fetching the statistical data. Please refresh the page to try again';
export const ACCOUNT_API_ERROR = 'Sorry, there was an error fetching your accounts. Please refresh the page to try again.';
export const MAX_SAVED_TERRITORIES_REACHED = 'You have reached the maximum number of territories (25) that can be saved.';
