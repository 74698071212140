import React from 'react';
import PropTypes from 'prop-types';

const QuestionIcon = props => (
    <span style={props.style}>
        <i className="fa fa-question-circle-o md-left-5" />
    </span>
);

QuestionIcon.propTypes = {
    style: PropTypes.object,
};

export default QuestionIcon;
