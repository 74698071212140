import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';

import { getRoute, routeCodes } from '../../../../constants/routes';
import Option from '../../../../constants/options/dropdownOptions';
import { getAssetDomain } from '../../../../constants/assets';
import TOOLTIP from '../../../../constants/tooltipText';
import SearchValidation from '../SearchValidation';
import Search from '../../../../constants/rapidSearch';
import { trackEvent } from '../../../../actions/segment';
import SEGMENT from '../../../../constants/segment';
import Commons from '../../../../helpers/Commons';
import LocalStorageHelper, { SEARCH_BAR_TOOLTIP_KEY } from '../../../../helpers/LocalStorageHelper';
import listPropertyPreview from '../../../../constants/listPropertyPreview';
import FieldTooltip from '../FieldTooltip';
import { conjunctUrlParams } from '../../../../helpers/search/ParamsBuilder';
import RollValuationParamsBuilder from '../../../../helpers/search/RollValuationParamsBuilders';
import { saveSearchType } from '../../../../actions/componentAccess';
import { cruxLoader, cruxUnloader } from '../../../../actions/crux';
import { cruxAppError } from '../../../../actions/errorHandler';
import ErrorMsg from '../../../../constants/errorMsg';
import { ErrorType } from '../../../../constants/redirect';
import API from '../../../../api/clapi';
import Segment from '../../../../helpers/Segment';
import StyledSearchButton from '../../../common/styledMUI/StyledSearchButton';
import SearchField from '../../../common/textFields/SearchField';

class RollValuationSearch extends Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            valuationReference: '',
            isRollValuationFieldError: false,
            rollValuationFieldErrorMessage: '',
            addressSuburb: '',
            anchorEl: [],
            open: [],
            isViewed: [],
            queryString: '',
            width: null,
        };
        this.textFieldRefs = [];
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        const { route } = this.props;
        if (route.id !== routeCodes.ROLL_VALUATION_SEARCH_RESULT.id &&
            prevProps.route !== route) {
            this.clearAllFields();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.updateDimensions);
        this.clearAllFields();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {
            isRollValuationFieldError: prevState.isRollValuationFieldError,
        };
        const { location: { search } = {} } = nextProps;

        // Field Validations
        // Revert field to normal state upon receiving valid input
        if (prevState.isRollValuationFieldError && prevState.valuationReference !== '') {
            nextState.isRollValuationFieldError = false;
        }

        const routePath = getRoute(nextProps.match.path);
        const formattedState = {};
        if (search && search !== prevState.queryString &&
            routePath.id === routeCodes.ROLL_VALUATION_SEARCH_RESULT.id) {
            nextState.queryString = search;
            const queryParams = RollValuationParamsBuilder(search);
            Object.keys(queryParams).forEach((key) => {
                formattedState[key] = decodeURIComponent(queryParams[key]);
            });
        }

        return { ...nextState, ...formattedState };
    }

    clearAllFields = () => {
        this.setState({
            valuationReference: '',
            addressSuburb: '',
        });
    }

    validateRollValuationField(valuationReference) {
        // Validate whether valuationReference field is empty or not
        if (valuationReference.trim() === '') {
            this.setState({
                isRollValuationFieldError: true,
                rollValuationFieldErrorMessage: Search.ERROR_MESSAGE.rollValuationRequired,
            });
        }
    }

    redirectPage = (uri) => {
        const { history, dispatch } = this.props;
        history.push(uri);
        if (uri.includes('property')) {
            this.clearAllFields();
            dispatch(saveSearchType(Option.ROLL_VALUATION));
        }
    };

    rollValuationSearchSubmit = () => {
        const {
            valuationReference, addressSuburb,
        } = this.state;

        const { dispatch } = this.props;

        const pathName = routeCodes.ROLL_VALUATION_SEARCH_RESULT.path;
        const searchParams = this.constructQueryParams();

        this.validateRollValuationField(valuationReference);

        // valuationReference should not be empty before proceeding
        if (valuationReference.trim() !== '') {
            dispatch(cruxLoader());
            API.getBatchSearch({
                valuationReference,
                addressSuburb,
            }).then((result) => {
                const totalElements = Commons.get(result, 'metadata.totalElements');
                const [firstData] = Commons.get(result, 'data');
                if (totalElements === 1) {
                    this.redirectPage(routeCodes.PROPERTY.path(firstData.addressComplete, firstData.id));
                } else {
                    this.redirectPage(`${pathName}?${searchParams}`);
                }
                this.trackMixpanelEvent();
            }).catch((e) => {
                console.log(e);
                dispatch(cruxAppError(ErrorMsg.FAILED_TO_FETCH, ErrorType.PAGE_ERROR));
            }).finally(() => {
                dispatch(cruxUnloader());
            });
        }
    }

    trackMixpanelEvent = () => {
        const { dispatch, route: { pageContext } = {} } = this.props;

        const {
            valuationReference,
            addressSuburb,
        } = this.state;

        const _addressSuburb = addressSuburb ? `, ${addressSuburb}` : '';
        dispatch(trackEvent(Segment.trackRollValuationSearch({
            pageContext,
            searchString: `${valuationReference}${_addressSuburb}`,
            entryPoint: SEGMENT.EVENT_PROPERTIES.TYPED,
            searchType: SEGMENT.EVENT_PROPERTIES.ROLL_VALUATION,
        })));
    };

    constructQueryParams = () => {
        const {
            valuationReference, addressSuburb,
        } = this.state;

        const formattedParams = {
            valuationReference,
            addressSuburb,
        };
        return conjunctUrlParams(formattedParams);
    };

    handleFocusPopper = (event, index) => {
        this.updateDimensions();
        if (Commons.get(LocalStorageHelper.get(SEARCH_BAR_TOOLTIP_KEY), `is-${listPropertyPreview.SEARCH_TYPE.ROLL_VALUATION}-${index}-viewed`)) return;
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: true,
            },
        }));
    };

    handleClosePopper = (event, index) => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: false,
            },
            isViewed: {
                ...state.isViewed,
                [index]: true,
            },
        }));
    };

    handleClickAway = (event, index) => {
        setTimeout(() => {
            this.setState(state => ({
                open: {
                    ...state.open,
                    [index]: false,
                },
            }));
        }, 150);
    };

    handleScroll = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    };

    updateDimensions() {
        const newWidth = this.textFieldRefs[1];
        newWidth && this.setState({ width: newWidth.clientWidth });
    }

    render() {
        const { location } = this.props;
        const {
            isRollValuationFieldError, rollValuationFieldErrorMessage,
            anchorEl, open, isViewed, width,
        } = this.state;
        const {
            SUBURB, ROLL_VALUATION,
        } = TOOLTIP.TEXT.ROLL_VALUATION_SEARCH;

        const inputProps = [
            {
                field: 'valuationReference',
                placeholder: 'Enter a roll number or a valuation reference *',
                onChange: event => this.setState({ valuationReference: event.target.value }),
                tooltipTxt: ROLL_VALUATION,
                validation: {
                    message: rollValuationFieldErrorMessage,
                    onError: isRollValuationFieldError,
                },
                containerSize: 9,
                placement: 'bottom-start',
            },
            {
                field: 'addressSuburb',
                placeholder: 'Enter a Suburb',
                onChange: event => this.setState({ addressSuburb: event.target.value }),
                tooltipTxt: SUBURB,
                containerSize: 3,
                placement: 'bottom-start',
            },
        ];
        return (
            <div className="search-inputs">
                <div className="roll-valuation-search-primary-fields">
                    <Grid container className="grid-container">
                        {
                            inputProps.map(({
                                field, placeholder, onChange, tooltipTxt, validation, containerSize, placement,
                            }, index) => (
                                <Grid key={`grid-${index}-${field}`} item className="item" xs={containerSize}>
                                    <div
                                        className={`form-input-search-field ${field}-field ${(validation && validation.onError) && 'error'}`}
                                    >
                                        <SearchField
                                            ref={ref => this.textFieldRefs[index] = ref}
                                            value={this.state[field]}
                                            type="text"
                                            className="form-control"
                                            id={field}
                                            placeholder={placeholder}
                                            onChange={onChange}
                                            onFocus={event => this.handleFocusPopper(event, index)}
                                            autoComplete="off"
                                            onBlur={event => this.handleClickAway(event, index)}
                                            error={!!validation?.onError}
                                            color="primary"
                                        />

                                        <FieldTooltip
                                            id={index}
                                            key={`roll-valuation-search-popper-${index}-${field}`}
                                            className={`roll-valuation-search-tooltip ${field}-tooltip`}
                                            open={(open[index] && !isViewed[index]) || false}
                                            anchorEl={anchorEl[index] || null}
                                            handleClose={event => this.handleClosePopper(event, index)}
                                            tooltipTxt={tooltipTxt}
                                            searchType={listPropertyPreview.SEARCH_TYPE.ROLL_VALUATION}
                                            placement={placement}
                                            width={width}
                                        />

                                        {
                                            validation &&
                                                <SearchValidation
                                                    message={validation.message}
                                                    onError={validation.onError}
                                                />
                                        }

                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <StyledSearchButton
                        data-testid="roll-valuation-search-button"
                        className="button-primary name-search-btn"
                        onClick={this.rollValuationSearchSubmit}
                        disabled={location.search === `?${this.constructQueryParams()}`}
                    >
                        <img
                            src={getAssetDomain('search_icon_white.png')}
                            className="search-icon"
                            alt="roll/valuation search"
                        />
                    </StyledSearchButton>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    clUsrId: state.claud.get('session')?.clusrId,
}))(withRouter(RollValuationSearch))
