import {
    ADDRESS_COMPLETE_PARAM,
    COLLECTIVE_OPERATOR,
    EQUALS_OPERATOR,
    IS_ACTIVE_PARAM,
    IS_OPERATOR,
    OR_OPERATOR,
    AND_OPERATOR,
    GREATER_THAN_OPERATOR,
    LESS_THAN_OPERATOR,
    DOUBLE_EQUALS_OPERATOR,
    DATE_FROM_OPERATOR,
    DATE_TO_OPERATOR,
    GEODISTANCE_OPERATOR,
    LOCATION_PARAM,
    ADDRESS_PARCEL_SUBURB_STATE_PARAM,
    LEGAL_DESCRIPTION_PARAM,
    TITLE_REFERENCE_PARAM,
    NESTED_OPERATOR,
    CONTAINS_OPERATOR,
    STARTS_WITH_OPERATOR,
    AND_NOT_OPERATOR,
    COMPARE_OPERATOR,
    SALES_LAST_SALE_CONTRACT_DATE_PARAM,
    DISTINCT_FIELDS_PARAM,
    MAX_PROPERTIES_PER_PAGE,
    MAX_PAGE_PER_BATCH,
    GEOPOLYGON_OPERATOR,
} from '../../../constants/batchSearch/batchSearch';
import Commons from '../../Commons';
import Export from '../../Export';
import { RAPID_PROPERTY_TYPE_V2 } from '../../Localization';

const RANGE_OPERATOR = [GREATER_THAN_OPERATOR, LESS_THAN_OPERATOR];
const DATE_RANGE_OPERATOR = [DATE_FROM_OPERATOR, DATE_TO_OPERATOR];
const SEARCH_FIELDS = {
    [ADDRESS_PARCEL_SUBURB_STATE_PARAM]: 'parcelList.suggestions',
    [LEGAL_DESCRIPTION_PARAM]: 'titles.estates.legalDescription',
    [TITLE_REFERENCE_PARAM]: 'titles.titleReference',
};

const buildFilter = (field, operation, value) => ({
    field,
    operation,
    value,
});

const defaultBuilder = ({ param, paramValue }) => buildFilter(param, EQUALS_OPERATOR, paramValue);

const resultFormatBuilder = ({ resultsFormat, param, paramValue }) => ({
    ...resultsFormat,
    [param]: param === DISTINCT_FIELDS_PARAM ? paramValue.split(',') : paramValue,
});

const resultFormatSortBuilder = ({ resultsFormat, param, paramValue }) => {
    let sort = paramValue;
    if (Array.isArray(paramValue)) {
        sort = paramValue.map(item => (`${item.order}${item.attr}`));
    }
    return resultFormatBuilder({ resultsFormat, param, paramValue: sort.toString() });
};

const addressCollectiveBuilder = ({ paramValue }) => buildFilter(
    ADDRESS_COMPLETE_PARAM,
    COLLECTIVE_OPERATOR,
    paramValue,
);

const addressContainsBuilder = ({ paramValue }) => buildFilter(
    ADDRESS_COMPLETE_PARAM,
    CONTAINS_OPERATOR,
    paramValue,
);

const exactAddressBuilder = ({ paramValue }) => buildFilter(
    ADDRESS_COMPLETE_PARAM,
    EQUALS_OPERATOR,
    paramValue,
);

const isActiveFilterBuilder = ({ paramValue = 'true' }) => {
    // should set the paramValue to string to handle the boolean issue
    const activeValues = `${paramValue}`.split(',');
    return {
        operation: OR_OPERATOR,
        filters: activeValues.map(isActive =>
            buildFilter(IS_ACTIVE_PARAM, IS_OPERATOR, isActive === 'true')),
    };
};

const rangeFilterBuilder = ({ param, paramValue }) => {
    const filter = [];
    if (typeof paramValue === 'string' && paramValue.includes('-')) {
        const activeValues = paramValue.split('-');
        activeValues.forEach((value, index) => {
            if (value !== '') {
                filter.push(buildFilter(param, RANGE_OPERATOR[index], value.trim()));
            }
        });
    } else {
        filter.push(buildFilter(param, DOUBLE_EQUALS_OPERATOR, paramValue));
    }
    return filter.length > 1 ?
        {
            operation: AND_OPERATOR,
            filters: filter,
        }
        : filter[0];
};

const containFilterBuilder = ({ param, paramValue }) => buildFilter(param, CONTAINS_OPERATOR, paramValue);

const multiValuesBuilder = (param, values) => ({
    operation: OR_OPERATOR,
    filters: values.map(value =>
        defaultBuilder({ param, paramValue: value.trim() })),
});

const splitValuesBuilder = (param, splitValues) => splitValues.length > 1
    ? multiValuesBuilder(param, splitValues)
    : defaultBuilder({ param, paramValue: splitValues[0].trim() });

const typeFilterBuilder = ({ param, paramValue }) => {
    const trimmedValue = paramValue?.replace(/"/g, ''); // MSG Saved Search v2 - v1 issue
    const values = trimmedValue.split(',');
    return splitValuesBuilder(param, values);
};

const buildWithdrawnCompare = () => ({
    operation: AND_NOT_OPERATOR,
    filters: [{
        operation: COMPARE_OPERATOR,
        condition: 'salesLastSaleContractDate > salesLastCampaignStartDate',
    }],
});

const commaConcatenatedToJsonBuilder = ({ param, paramValue }) => {
    const values = paramValue
        .replace(/"(.*)"/g, '$1')
        .split('","');
    return splitValuesBuilder(param, values);
};

const dateRangeFilterBuilder = ({ param, paramValue }) => {
    const values = paramValue.split('-');
    return {
        operation: AND_OPERATOR,
        filters: values.map((value, index) =>
            buildFilter(param, DATE_RANGE_OPERATOR[index], Commons.formatDate(value.trim(), 'YYYY-MM-DD'))),
    };
};

const withdrawnDateRangeFilterBuilder = ({ param, paramValue }, isAndNot = false ) => {
    const values = paramValue.split('-');
    return {
        operation: isAndNot ? AND_NOT_OPERATOR : AND_OPERATOR,
        filters: [{
            operation: AND_OPERATOR,
            filters: values.map((value, index) =>
                buildFilter(param, DATE_RANGE_OPERATOR[index], Commons.formatDate(value.trim(), 'YYYY-MM-DD'))),
        }],
    }
};

const withdrawnAndNotDateRangeFilterBuilder = ({ param, paramValue }) =>
    withdrawnDateRangeFilterBuilder({ param, paramValue }, true);

const checkboxFilterBuilder = ({ param, paramValue }) => {
    return buildFilter(param, IS_OPERATOR, paramValue);
};

const isActiveListingsFilterBuilder = ({ param, paramValue }) => buildFilter(
    param,
    IS_OPERATOR,
    paramValue,
);

const radiusFormatBuilder = ({ radiusParams, param, paramValue }) => {
    radiusParams[param] = parseFloat(paramValue, 10);
    return buildFilter(LOCATION_PARAM, GEODISTANCE_OPERATOR, radiusParams);
};

const territoryFormatBuilder = ({ paramValue }) => {
    return buildFilter(LOCATION_PARAM, GEOPOLYGON_OPERATOR, paramValue);
};

const tenureDateRangeBuilder = ({ paramValue, type }) => {
    const filters = [];
    const values = paramValue.split('-');
    filters.push(buildFilter('type', EQUALS_OPERATOR, type));
    filters.push(...values.filter(value => value).reverse().map((value, index) => {
        const _value = Export.formatTenureDate(value, 'YYYY-MM-DD');
        return buildFilter(
            SALES_LAST_SALE_CONTRACT_DATE_PARAM,
            DATE_RANGE_OPERATOR[index],
            _value,
        );
    }));
    return {
        operation: AND_OPERATOR,
        filters,
    };
};

const COMPANY_OWNER_NAME_FILTER = {
    companyOwner: {
        ownerFirstName: (prefix, paramValue, isExact) =>
            (buildFilter(`${prefix}.firstName`, isExact ? EQUALS_OPERATOR : STARTS_WITH_OPERATOR, paramValue)),
        ownerLastName: (prefix, paramValue, isExact) =>
            (buildFilter(`${prefix}.lastName`,  isExact ? EQUALS_OPERATOR : STARTS_WITH_OPERATOR, paramValue)),
        companyName: (prefix, paramValue, isExact) => {
            const operator = isExact ? EQUALS_OPERATOR : CONTAINS_OPERATOR;
            return buildFilter(`${prefix}.companyName`, operator, paramValue);
        },
    },
    address: {
        addressState: (param, paramValue) => (defaultBuilder({ param, paramValue })),
        addressSuburb: (param, paramValue) => (defaultBuilder({ param, paramValue })),
    },
};

const VOLUME_FOLIO_FILTER = {
    volumeFolio: {
        volumeNumber: (prefix, paramValue) =>
            (buildFilter(`${prefix}.volume`, STARTS_WITH_OPERATOR, paramValue)),
        folioNumber: (prefix, paramValue) =>
            (buildFilter(`${prefix}.folio`, STARTS_WITH_OPERATOR, paramValue)),
    },
    address: {
        addressState: (param, paramValue) => (defaultBuilder({ param, paramValue })),
        addressSuburb: (param, paramValue) => (defaultBuilder({ param, paramValue })),
    },
};

const TENURE_FILTER = {
    tenure: {
        holdPeriodHouse: (paramValue, type) => {
            const _house = RAPID_PROPERTY_TYPE_V2[0].value;
            return type.includes(_house)
                ? tenureDateRangeBuilder({ paramValue, type: _house.toLowerCase() })
                : null;
        },
        holdPeriodUnit: (paramValue, type) => {
            const _unit = RAPID_PROPERTY_TYPE_V2[1].value;
            return type.includes(_unit)
                ? tenureDateRangeBuilder({ paramValue, type: _unit.toLowerCase() })
                : null;
        },
    },
};

const buildCompanyOwnerName = (parentPath, paramValue) => {
    const companyOwnerNameFilters = [];
    Object.keys(COMPANY_OWNER_NAME_FILTER.companyOwner).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            companyOwnerNameFilters
                // eslint-disable-next-line max-len
                .push(COMPANY_OWNER_NAME_FILTER.companyOwner[keys](parentPath, paramValue[keys], paramValue?.exactSearch));
        }
    });
    return {
        operation: NESTED_OPERATOR,
        parentPath,
        filter: {
            operation: AND_OPERATOR,
            filters: companyOwnerNameFilters,
        },
    };
};

const companyNameFilterBuilder = ({ paramValue }) => {
    const companyOwnerNameSearchFilters = [];
    const companyOwnerName = [];
    Object.keys(COMPANY_OWNER_NAME_FILTER.address).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            companyOwnerNameSearchFilters
                .push(COMPANY_OWNER_NAME_FILTER.address[keys](keys, paramValue[keys]));
        }
    });
    const isCurrentOwnerOnly = paramValue.isCurrentOwner === 'true';
    companyOwnerName
        .push(buildCompanyOwnerName('owners', paramValue));
    if (!isCurrentOwnerOnly) {
        companyOwnerName
            .push(buildCompanyOwnerName('previousOwners.owners', paramValue));
    }

    companyOwnerNameSearchFilters.push({
        operation: OR_OPERATOR,
        filters: companyOwnerName,
    });

    return companyOwnerNameSearchFilters;
};

const searchFieldFilterBuilder = ({ param, paramValue }) =>
    defaultBuilder({ param: SEARCH_FIELDS[param], paramValue });

const buildingNameFilterBuilder = ({ param, paramValue }) => {
    const _paramValue = paramValue.constructor === Object ? paramValue : JSON.parse(paramValue);
    const _operation = _paramValue.exactSearch ? EQUALS_OPERATOR : STARTS_WITH_OPERATOR;
    return buildFilter(param, _operation, _paramValue.value);
};

const startsWithFilterBuilder = ({ param, paramValue }) =>
    buildFilter(param, STARTS_WITH_OPERATOR, paramValue);

const volumeFolioFilterBuilder = ({ paramValue }) => {
    const volumeFolioSearchFilters = [];
    Object.keys(VOLUME_FOLIO_FILTER.address).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            volumeFolioSearchFilters
                .push(VOLUME_FOLIO_FILTER.address[keys](keys, paramValue[keys]));
        }
    });

    const parentPath = 'volumeFolioList';
    Object.keys(VOLUME_FOLIO_FILTER.volumeFolio).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            const outerFilter = {
                operation: NESTED_OPERATOR,
                parentPath,
                filter: VOLUME_FOLIO_FILTER.volumeFolio[keys](parentPath, paramValue[keys]),
            };
            volumeFolioSearchFilters.push(outerFilter);
        }
    });

    return volumeFolioSearchFilters;
};

const tenureFilterBuilder = ({ paramValue }) => {
    let tenureCriteria;
    const tenureFilters = [];
    const hasHoldPeriodHouse = paramValue?.holdPeriodHouse;
    const hasHoldPeriodUnit = paramValue?.holdPeriodUnit;
    let propertyType = paramValue?.type;

    if (!propertyType) {
        if (hasHoldPeriodHouse && hasHoldPeriodUnit) {
            propertyType = `${RAPID_PROPERTY_TYPE_V2[0].value},${RAPID_PROPERTY_TYPE_V2[1].value}`
        } else {
            propertyType = hasHoldPeriodHouse
                ? RAPID_PROPERTY_TYPE_V2[0].value
                : RAPID_PROPERTY_TYPE_V2[1].value;
        }
    }
    Object.keys(TENURE_FILTER.tenure).forEach((keys) => {
        if (paramValue[keys] && paramValue[keys] !== '') {
            tenureCriteria = TENURE_FILTER.tenure[keys](paramValue[keys], propertyType);
            if (tenureCriteria !== null) {
                tenureFilters.push(tenureCriteria);
            }
        }
    });

    return {
        operation: OR_OPERATOR,
        filters: tenureFilters,
    }
};

const isGreaterThanOrEqual = ({ param, paramValue }) =>
    buildFilter(param, GREATER_THAN_OPERATOR, paramValue);
const isOperation = ({ param, paramValue }) => buildFilter(param, IS_OPERATOR, paramValue);

// This converts the page limit into 10 properties per page and build start and end page accordingly
// regardless of the page limit selected, i.e. 20, 30, 40 or 50.
// The reason behind this is because 10 is the common factor
// that we could get exactly 10k properties including the initially displayed ones
const getBatchStartPage = (currentOffset, limit) =>
    ((currentOffset + 1) * limit) / MAX_PROPERTIES_PER_PAGE;

const getBatchEndPage = (totalElementCount) => {
    const initTotalPage = Math.trunc(totalElementCount / MAX_PROPERTIES_PER_PAGE)
    return initTotalPage > MAX_PAGE_PER_BATCH ? MAX_PAGE_PER_BATCH : initTotalPage;
};

export default {
    defaultBuilder,
    resultFormatBuilder,
    buildWithdrawnCompare,
    resultFormatSortBuilder,
    addressCollectiveBuilder,
    exactAddressBuilder,
    isActiveFilterBuilder,
    rangeFilterBuilder,
    typeFilterBuilder,
    commaConcatenatedToJsonBuilder,
    dateRangeFilterBuilder,
    withdrawnAndNotDateRangeFilterBuilder,
    isActiveListingsFilterBuilder,
    radiusFormatBuilder,
    searchFieldFilterBuilder,
    buildingNameFilterBuilder,
    companyNameFilterBuilder,
    startsWithFilterBuilder,
    volumeFolioFilterBuilder,
    containFilterBuilder,
    checkboxFilterBuilder,
    buildFilter,
    isGreaterThanOrEqual,
    isOperation,
    withdrawnDateRangeFilterBuilder,
    tenureFilterBuilder,
    tenureDateRangeBuilder,
    addressContainsBuilder,
    getBatchStartPage,
    getBatchEndPage,
    territoryFormatBuilder,
};
