import { takeLatest, put, call, select, take, race, fork, spawn, all } from 'redux-saga/effects';
import ClapiApi from '../api/clapi';
import * as ClapiAction from '../actions/clapi';
import * as ComponentAction from '../actions/componentAccess';
import AnalyticsGateway from '../lib/AnalyticsGateway';
import { CRUX_APP_ERROR, addCruxCmptError, rmCruxCmptError } from '../actions/errorHandler';
import Bugsnag from '../bugsnag';
import Constant from '../constants/constant';
import Commons from '../helpers/Commons';
import * as MSGSaga from './msgApi';
import { saveRecentAddressSearches } from '../actions/msgApi';
import Sanitizer from '../helpers/Sanitizer';
import * as CruxSaga from './crux';
import * as CruxAction from '../actions/crux';
import PropertyDetailCommonsResponseBody from '../model/PropertyDetailCommonsResponseBody';
import * as RapidAction from '../actions/rapid';
import { selectSelectedAccount } from '../selectors/linkedAccount';

import { LGA_KEY } from '../helpers/Localization';
import SegmentHelper from '../helpers/Segment';
import { isAU } from '../constants/crux';
import UserPreferences from 'js/api/userPreferences';
import { savePanelDetails } from '../actions/printPdp';

// -------------- CLAPI Suggestion
function getSuggestionsFn() {
    return function* (successType, failType, action) {
        const {
            payload: {
                suggestionTypes = [],
                keyword,
                includeHistoric,
                limit,
            } = {},
        } = action;
        // Currently, market insights is the only one who is
        // passing custom suggestion type ['locality', 'postcode'],
        // it renders a loader when getting suggestions, in order to identify that,
        // suggestionTypes has been used if it should render the loader
        const shouldToggleSuggestionLoader = suggestionTypes?.length;
        try {
            if (shouldToggleSuggestionLoader) {
                yield put(ComponentAction.toggleSuggestionLoader(true));
            }

            // cancel getSuggestions if clearSuggestion is dispatched while fetching
            const suggestions = yield race({
                task: call(
                    ClapiApi.getSuggestions,
                    keyword,
                    suggestionTypes,
                    includeHistoric,
                    limit,
                ),
                cancel: take(ClapiAction.CLEAR_SUGGESTION),
            });

            // suggestions is undefined if cancelled
            if (suggestions?.task) {
                yield put({ type: successType, payload: suggestions.task });
            }
            if (shouldToggleSuggestionLoader) {
                yield put(ComponentAction.toggleSuggestionLoader(false));
            }
        } catch (error) {
            if (shouldToggleSuggestionLoader) {
                yield put(ComponentAction.toggleSuggestionLoader(false, true));
            }
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: failType });
        }
    };
}

// -------------- CLAPI Suggestion returnSuggestion = detail
function getSuggestionsWithDetailsFn() {
    return function* (successType, failType, action) {
        const {
            payload: {
                suggestionTypes = [],
                keyword,
                limit,
            } = {},
        } = action;

        try {
            const state = yield select();

            // cancel getSuggestions if clearSuggestion is dispatched while fetching
            const { task } = yield race({
                task: call(
                    ClapiApi.getSuggestionsWithDetails,
                    state.claud.get('session')?.clusrId,
                    keyword,
                    suggestionTypes,
                    limit,
                ),
                cancel: take(ClapiAction.CLEAR_SUGGESTION),
            });

            // task is undefined if cancelled
            if (task?.suggestions) {
                // Finding suggestions with similar localityId, collecting their
                // councilAreaId to create a single suggestion;
                const newSuggestions = {
                    suggestions: task.suggestions.reduce((a, c) => {
                        const similarSuggestion = a
                            .find(suggestion => suggestion.localityId === c.localityId)
                        if (similarSuggestion) {
                            Object.assign(similarSuggestion, {
                                lgaIds: [
                                    ...similarSuggestion?.lgaIds || [],
                                    c[LGA_KEY],
                                ],
                            });
                        } else {
                            Object.assign(c, { ...c, lgaIds: [c[LGA_KEY]] });
                            delete c[LGA_KEY];
                            a.push(c);
                        }

                        return a;
                    }, [])
                }
                yield put({ type: successType, payload: newSuggestions });
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: failType });
        }
    };
}

function getParcelSuggestionsFn() {
    return function* (successType, failType, action) {
        const {
            payload: {
                keyword,
            } = {},
        } = action;
        try {
            const state = yield select();
            // cancel getParcelSuggestions if clearParcelSuggestion is dispatched while fetching
            const suggestions = yield race({
                task: call(
                    ClapiApi.getParcelSuggestions,
                    state,
                    keyword,
                ),
                cancel: take(ClapiAction.CLEAR_PARCEL_SUGGESTION),
            });

            // suggestions is undefined if cancelled
            if (suggestions.task) {
                yield put({ type: successType, payload: suggestions.task });
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: failType });
        }
    };
}

function getLegalSuggestionsFn() {
    return function* (successType, failType, action) {
        const {
            payload: {
                keyword,
                suggestionTypes,
            } = {},
        } = action;
        try {
            const state = yield select();
            const suggestions = yield race({
                task: call(
                    ClapiApi.getLegalSuggestions,
                    state,
                    keyword,
                    suggestionTypes,
                ),
                cancel: take(ClapiAction.CLEAR_LEGAL_SUGGESTION),
            });

            // suggestions is undefined if cancelled
            if (suggestions.task) {
                yield put({ type: successType, payload: suggestions.task });
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: failType });
        }
    };
}

function getTitleRefSuggestionsFn() {
    return function* (successType, failType, action) {
        const {
            payload: {
                keyword,
                suggestionTypes,
            } = {},
        } = action;
        try {
            const state = yield select();
            const suggestions = yield race({
                task: call(
                    ClapiApi.getTitleRefSuggestions,
                    state,
                    keyword,
                    suggestionTypes,
                ),
                cancel: take(ClapiAction.CLEAR_TITLE_REF_SUGGESTION),
            });

            // suggestions is undefined if cancelled
            if (suggestions.task) {
                yield put({ type: successType, payload: suggestions.task });
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: failType });
        }
    };
}

function getRentalAvmFn() {
    return function* (action) {
        try {
            const { payload } = action;
            const rentalAvm = yield call(ClapiApi.getRentalAvm, payload.propertyId);

            yield put({ type: ClapiAction.CLAPI_GET_RENTAL_AVM_SUCCESS, payload: rentalAvm });
            yield put(rmCruxCmptError(ClapiAction.CLAPI_GET_RENTAL_AVM_FAIL));
        } catch (error) {
            yield put({ type: ClapiAction.CLAPI_GET_RENTAL_AVM_FAIL });
            yield put(addCruxCmptError(ClapiAction.CLAPI_GET_RENTAL_AVM_FAIL, error));
            Bugsnag.notify(error);
        }
    };
}

function getIntellivalValuationAvmFn() {
    return function* (action) {
        try {
            const { payload } = action;

            const intellivalValuationAvm = yield call(
                ClapiApi.getIntellivalValuationAvm,
                payload.propertyId,
            );
            intellivalValuationAvm.source = Constant.Intellival.Source;

            yield put({
                type: ClapiAction.CLAPI_GET_INTELLIVAL_VALUATION_AVM_SUCCESS,
                payload: intellivalValuationAvm,
            });
            yield put(rmCruxCmptError(ClapiAction.CLAPI_GET_INTELLIVAL_VALUATION_AVM_FAIL));
        } catch (error) {
            Bugsnag.notify(error);
            yield put(addCruxCmptError(ClapiAction.CLAPI_GET_INTELLIVAL_VALUATION_AVM_FAIL, error));
            yield put({ type: ClapiAction.CLAPI_GET_INTELLIVAL_VALUATION_AVM_FAIL });
        }
    };
}

export function* handleSavePanelDetails(advertisements, panelDetailsKey, type) {
    let panelDetailsPayload = { [panelDetailsKey]: { noData: true } };
    const advertisementList = advertisements[type];
    if (advertisementList && advertisementList.length) {
        const recentListingDescription = advertisementList
            .find(description => !!description.advertisementDescription);
        if (recentListingDescription) {
            panelDetailsPayload = {
                [panelDetailsKey]: { data: recentListingDescription },
            };
        }
    }
    yield put(savePanelDetails(panelDetailsPayload));
}

// -------------- CLAPI Property Advertisement List Description
function getLatestAdvertisementDescFn() {
    return function* (action) {
        try {
            const state = yield select();
            const { propertyId } = action.payload;
            const recentAdvertisement = yield call(
                ClapiApi.getLatestAdvertisementDesc,
                state,
                propertyId,
            );

            const actionSuccess = {
                type: ClapiAction.GET_LATEST_ADVERTISEMENT_DESC_SUCCESS,
                payload: recentAdvertisement,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: ClapiAction.GET_LATEST_ADVERTISEMENT_DESC_FAIL });
        }
    };
}

function getAdvertisementSaleDescFn() {
    return function* (action) {
        try {
            const state = yield select();
            const { propertyId } = action.payload;
            const advertisements = yield call(
                ClapiApi.getAdvertisementSaleDesc,
                state,
                propertyId,
            );
            yield call(handleSavePanelDetails, advertisements, 'recentAdvertisementForSale', 'forSaleAdvertisementList');

            const actionSuccess = {
                type: ClapiAction.GET_PROPERTY_FOR_SALE_ADVERTISEMENT_SUCCESS,
                payload: advertisements,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: ClapiAction.GET_PROPERTY_FOR_SALE_ADVERTISEMENT_FAIL });
            yield put(savePanelDetails({ recentAdvertisementForSale: { isError: true } }));
        }
    };
}

// -------------- CLAPI Property Advertisement Rent Description
function getAdvertisementRentDescFn() {
    return function* (action) {
        try {
            const state = yield select();
            const { propertyId } = action.payload;
            const advertisements = yield call(
                ClapiApi.getAdvertisementRentDesc,
                state,
                propertyId,
            );
            yield call(handleSavePanelDetails, advertisements, 'recentAdvertisementForRent', 'forRentAdvertisementList');

            const actionSuccess = {
                type: ClapiAction.GET_PROPERTY_FOR_RENT_ADVERTISEMENT_SUCCESS,
                payload: advertisements,
            };
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: ClapiAction.GET_PROPERTY_FOR_RENT_ADVERTISEMENT_FAIL });
            yield put(savePanelDetails({ recentAdvertisementForRent: { isError: true } }));
        }
    };
}

function getUserDetailFn() {
    return function* (action) {
        const { callback } = action || {};
        try {
            const { selectedClAppAccountUserGuid } = yield select(selectSelectedAccount);
            const usrDetail = yield call(UserPreferences.getUserSummary, selectedClAppAccountUserGuid);
            const profilePhoto = Commons.get(usrDetail, 'userAccountDetail.profilePhoto');
            yield put(ClapiAction.getUserDetailSuccess(Sanitizer
                .userPrefSummaryToUserDetail(usrDetail)));
            yield put(ClapiAction.updateUserPhoto(profilePhoto))
            if (callback) callback();
        } catch (error) {
            Bugsnag.notify(error);
            yield put(addCruxCmptError(ClapiAction.GET_USER_DETAIL_FAIL, error));
        }
    };
}

// -------------- CLAPI get Content Disclaimer/Copyright
function getLegalContentFn() {
    const lookup = {
        [ClapiAction.GET_GENERAL_DISCLAIMER]: 'generalDisclaimer',
        [ClapiAction.GET_CONTENT_DISCLAIMER]: 'contentDisclaimer',
        [ClapiAction.GET_VALUATION_DISCLAIMER]: 'valuationDisclaimer',
        [ClapiAction.GET_STATE_DISCLAIMER]: 'stateDisclaimer',
        [ClapiAction.GET_CENSUS_DISCLAIMER]: 'censusDisclaimer',
        [ClapiAction.GET_COPYRIGHTS]: 'copyRights',
    };
    return function* (successType, failType, action) {
        try {
            const { category, key } = action.payload;
            const keys = Array.isArray(key) ? key : [key];

            const contentCalls = keys.map(_key =>
                call(ClapiApi.getLegalContent, category, _key)
            );

            const response = yield all(contentCalls);
            let content = '';
            if (response) {
                response.forEach((_response, index) => {
                    content += `${_response.content}${index === response.length - 1 ? '' : '<br><br>'}`;
                });
            }
            const filterHreftags = content.replace('href=', 'href=//');
            const actionSuccess = {
                type: successType,
                payload: filterHreftags,
            };
            yield put(rmCruxCmptError(failType));
            yield put(actionSuccess);

            yield put(savePanelDetails({
                [lookup[action.type]]: filterHreftags,
            }));
        } catch (error) {
            Bugsnag.notify(error);
            yield put(addCruxCmptError(failType, error));
        }
    };
}
function getPropertyDetailsLocationFn() {
    return function* (action) {
        try {
            const state = yield select();
            const { propertyId } = action.payload;
            const propertyDetailsLocation = yield call(
                ClapiApi.getPropertyDetailsLocation,
                state,
                propertyId,
            );

            yield put({
                type: ClapiAction.GET_PROPERTY_DETAILS_LOCATION_SUCCESS,
                payload: propertyDetailsLocation,
            });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: ClapiAction.GET_PROPERTY_DETAILS_LOCATION_FAIL });
            yield put(addCruxCmptError(ClapiAction.GET_STATE_DISCLAIMER_FAIL, error));
        }
    };
}

function getTitleDescriptionFn() {
    return function* (action) {
        try {
            const { payload } = action;
            const titleDescription = yield call(
                ClapiApi.getTitleInformation,
                payload.propertyId,
            );
            if (titleDescription) {
                yield put({
                    type: ClapiAction.GET_TITLE_DESCRIPTION_SUCCESS,
                    payload: titleDescription.titles[0],
                });
            } else {
                yield put({ type: ClapiAction.GET_TITLE_DESCRIPTION_FAIL });
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: ClapiAction.GET_TITLE_DESCRIPTION_FAIL });
        }
    };
}


export const getSuggestions = getSuggestionsFn();
export const getSuggestionsWithDetails = getSuggestionsWithDetailsFn();
export const getParcelSuggestions = getParcelSuggestionsFn();
export const getLegalSuggestions = getLegalSuggestionsFn();
export const getTitleRefSuggestions = getTitleRefSuggestionsFn();
export const getRentalAvm = getRentalAvmFn();
export const getIntellivalValuationAvm = getIntellivalValuationAvmFn();
export const getAdvertisementSaleDesc = getAdvertisementSaleDescFn();
export const getAdvertisementRentDesc = getAdvertisementRentDescFn();
export const getLatestAdvertisementDesc = getLatestAdvertisementDescFn();
export const getUserDetail = getUserDetailFn();
export const getLegalContent = getLegalContentFn();
export const getPropertyDetailsLocation = getPropertyDetailsLocationFn();
export const getTitleDescription = getTitleDescriptionFn();

// -------------- get complete property details
const getPropertyInStore = state => PropertyDetailCommonsResponseBody.parse(state.crux.get('property').get('commons'));
const getSearchDetailsFromSLAS = state => state.segment.get('searchDetails');
const PROPERTY_SEARCH_CONTEXT = 'property';
export function* saveRecentPropertySearch(suggestionTypeId) {
    const reduxState = yield select();
    const searchDetailsFromSLAS = getSearchDetailsFromSLAS(reduxState);
    if (searchDetailsFromSLAS &&
        searchDetailsFromSLAS.searchContext.toLowerCase() === PROPERTY_SEARCH_CONTEXT &&
        searchDetailsFromSLAS.searchString) {
        yield fork(MSGSaga.saveRecentAddressSearches, saveRecentAddressSearches(
            searchDetailsFromSLAS.searchString[0],
            suggestionTypeId,
        ));
    }
}
function getPartialPropertyDetailsFn() {
    return function* (action) {
        const {
            propertyId, suggestionTypeId, entryPoint, callback,
            isActiveProperty,
        } = action.payload;
        // putting property commons here but when time comes that this SAGA
        // will be put out of commission then we need to place property commons
        // at Property.jsx and will be saved as local state.
        // Reason behind is to not make the loading of components complicated
        // (handling 2 blocking api calls at 2 separate location)
        yield call(CruxSaga.getPropertyCommons, CruxAction.getPropertyCommons(propertyId));

        const store = yield select();
        const propertyCommons = getPropertyInStore(store);
        const location = Commons.get(propertyCommons, 'location');
        const propertyType = Commons.get(propertyCommons, 'attrCore.propertyType');

        AnalyticsGateway.sendEvent(SegmentHelper
            .viewPropertyDetailPage({ propertyId, propertyType, entryPoint }));

        if ((location && !Commons.isObjectEmpty(location)) ||
            (propertyCommons && !Commons.isObjectEmpty(propertyCommons))) {
            if (!Commons.isHistoricProperty({ isActiveProperty })) {
                yield spawn(saveRecentPropertySearch, suggestionTypeId);
            }
            yield put(ClapiAction.getIntelliValValuationAvm(propertyId));
            yield put(ClapiAction.getRentalAvm(propertyId));
            yield put(ComponentAction.setNearbySchoolActiveTab(0));
            yield put(RapidAction.getPropertyByID(propertyId));
            if (callback) callback();
        }

        yield put({
            type: ClapiAction.GET_PARTIAL_PROPERTY_DETAILS_SUCCESS,
        });
    };
}
export const getPartialPropertyDetails = getPartialPropertyDetailsFn();

export function* getSuggestionsWatcher() {
    yield takeLatest(
        ClapiAction.GET_SUGGESTIONS,
        getSuggestions,
        ClapiAction.GET_SUGGESTIONS_SUCCESS,
        ClapiAction.GET_SUGGESTIONS_FAIL,
    );
}

export function* getSuggestionsWithDetailsWatcher() {
    yield takeLatest(
        ClapiAction.GET_SUGGESTIONS_WITH_DETAILS,
        getSuggestionsWithDetails,
        ClapiAction.GET_SUGGESTIONS_SUCCESS,
        ClapiAction.GET_SUGGESTIONS_FAIL,
    );
}

export function* getParcelSuggestionsWatcher() {
    yield takeLatest(
        ClapiAction.GET_PARCEL_SUGGESTIONS,
        getParcelSuggestions,
        ClapiAction.GET_PARCEL_SUGGESTIONS_SUCCESS,
        ClapiAction.GET_PARCEL_SUGGESTIONS_FAIL,
    );
}

export function* getLegalSuggestionsWatcher() {
    yield takeLatest(
        ClapiAction.GET_LEGAL_SUGGESTIONS,
        getLegalSuggestions,
        ClapiAction.GET_LEGAL_SUGGESTIONS_SUCCESS,
        ClapiAction.GET_LEGAL_SUGGESTIONS_FAIL,
    );
}

export function* getTitleRefSuggestionsWatcher() {
    yield takeLatest(
        ClapiAction.GET_TITLE_REF_SUGGESTIONS,
        getTitleRefSuggestions,
        ClapiAction.GET_TITLE_REF_SUGGESTIONS_SUCCESS,
        ClapiAction.GET_TITLE_REF_SUGGESTIONS_FAIL,
    );
}

export function* getRentalAvmWatcher() {
    yield takeLatest(ClapiAction.CLAPI_GET_RENTAL_AVM, getRentalAvm);
}
export function* getIntellivalValuationAvmWatcher() {
    yield takeLatest(ClapiAction.CLAPI_GET_INTELLIVAL_VALUATION_AVM, getIntellivalValuationAvm);
}

export function* getAdvertisementSaleDescWatcher() {
    yield takeLatest(ClapiAction.GET_PROPERTY_FOR_SALE_ADVERTISEMENT, getAdvertisementSaleDesc);
}

export function* getLatestAdvertisementDescWatcher() {
    yield takeLatest(ClapiAction.GET_LATEST_ADVERTISEMENT_DESC, getLatestAdvertisementDesc);
}

export function* getAdvertisementRentDescWatcher() {
    yield takeLatest(ClapiAction.GET_PROPERTY_FOR_RENT_ADVERTISEMENT, getAdvertisementRentDesc);
}

export function* handleRecentSearchWatcher() {
    yield takeLatest(
        ClapiAction.HANDLE_RECENT_SEARCH,
        getSuggestions,
        ClapiAction.HANDLE_RECENT_SEARCH_SUCCESS,
        ClapiAction.HANDLE_RECENT_SEARCH_FAIL,
    );
}


export function* getGeneralDisclaimerWatcher() {
    yield takeLatest(
        ClapiAction.GET_GENERAL_DISCLAIMER,
        getLegalContent,
        ClapiAction.GET_GENERAL_DISCLAIMER_SUCCESS,
        ClapiAction.GET_GENERAL_DISCLAIMER_FAIL,
    );
}

export function* getContentDisclaimerWatcher() {
    yield takeLatest(
        ClapiAction.GET_CONTENT_DISCLAIMER,
        getLegalContent,
        ClapiAction.GET_CONTENT_DISCLAIMER_SUCCESS,
        ClapiAction.GET_CONTENT_DISCLAIMER_FAIL,
    );
}

export function* getValuationDisclaimerWatcher() {
    yield takeLatest(
        ClapiAction.GET_VALUATION_DISCLAIMER,
        getLegalContent,
        ClapiAction.GET_VALUATION_DISCLAIMER_SUCCESS,
        ClapiAction.GET_VALUATION_DISCLAIMER_FAIL,
    );
}

export function* getStateDisclaimerWatcher() {
    if (isAU) {
        yield takeLatest(
            ClapiAction.GET_STATE_DISCLAIMER,
            getLegalContent,
            ClapiAction.GET_STATE_DISCLAIMER_SUCCESS,
            ClapiAction.GET_STATE_DISCLAIMER_FAIL,
        );
    }
}

export function* getCensusDisclaimerWatcher() {
    yield takeLatest(
        ClapiAction.GET_CENSUS_DISCLAIMER,
        getLegalContent,
        ClapiAction.GET_CENSUS_DISCLAIMER_SUCCESS,
        ClapiAction.GET_CENSUS_DISCLAIMER_FAIL,
    );
}

export function* getUserDetailWatcher() {
    yield takeLatest(ClapiAction.GET_USER_DETAIL_ASYNC, getUserDetail);
}

export function* getPropertyDetailsLocationWatcher() {
    yield takeLatest(ClapiAction.GET_PROPERTY_DETAILS_LOCATION, getPropertyDetailsLocation);
}

export function* getCopyRightsWatcher() {
    yield takeLatest(
        ClapiAction.GET_COPYRIGHTS,
        getLegalContent,
        ClapiAction.GET_COPYRIGHTS_SUCCESS,
        ClapiAction.GET_COPYRIGHTS_FAIL,
    );
}

export function* getPartialPropertyDetailsWatcher() {
    yield takeLatest(ClapiAction.GET_PARTIAL_PROPERTY_DETAILS, getPartialPropertyDetails);
}


export function* getTitleDescriptionWatcher() {
    yield takeLatest(
        ClapiAction.GET_TITLE_DESCRIPTION,
        getTitleDescription,
    );
}

export default [
    getSuggestionsWatcher(),
    getSuggestionsWithDetailsWatcher(),
    getParcelSuggestionsWatcher(),
    getLegalSuggestionsWatcher(),
    getTitleRefSuggestionsWatcher(),
    getRentalAvmWatcher(),
    getIntellivalValuationAvmWatcher(),
    getLatestAdvertisementDescWatcher(),
    getAdvertisementSaleDescWatcher(),
    getAdvertisementRentDescWatcher(),
    handleRecentSearchWatcher(),
    getUserDetailWatcher(),
    getGeneralDisclaimerWatcher(),
    getContentDisclaimerWatcher(),
    getValuationDisclaimerWatcher(),
    getStateDisclaimerWatcher(),
    getCensusDisclaimerWatcher(),
    getPropertyDetailsLocationWatcher(),
    getCopyRightsWatcher(),
    getPartialPropertyDetailsWatcher(),
    getTitleDescriptionWatcher(),
];
