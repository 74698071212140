import {
    Component,
    createRef,
    lazy,
    Fragment,
    Suspense,
} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Container from '@mui/material/Container';
import SearchBarV2 from '../searchBar/SearchBarV2';
import RapidBreadcrumbs from '../../../components/global/RapidBreadcrumbs';
import ReportMenu from '../../reports/ReportMenu';
import SidebarMenu from '../SidebarMenu';
import PropertyCMA from '../../reports/PropertyCMA';
import CustomPrintButton from '../../property/print/CustomPrintButton';
import PropertyPrintHeader from '../PropertyPrintHeader';

import {
    routeCodes,
    getRoute,
    isHomePage,
    isBeforeHomepagePathname,
    isBeforeTnCPathname,
    routeConfigs,
} from '../../../constants/routes';
import Segment from 'js/constants/segment';
import { viewHomeEvent } from '../../../actions/segment';
import { entryPoints } from '../../../constants/entryPoint';
import Commons from '../../../helpers/Commons';
import { floatHeader } from '../../../actions/componentAccess';
import ReportHelper from '../../../helpers/report/ReportHelper';
import { GET_USER_DETAIL_FAIL } from '../../../actions/clapi';
import Sanitizer from '../../../helpers/Sanitizer';


import LocalStorageHelper, { PORTAL_SIDEBAR_KEY } from '../../../helpers/LocalStorageHelper';
import LDHelper from '../../../helpers/LaunchDarklyHelper';
import { isAU, isNZ } from '../../../constants/crux';
import PropertyDetailCommonsResponseBody from '../../../model/PropertyDetailCommonsResponseBody';
import { invalidateCruxAppError } from '../../../actions/errorHandler';
import { getRecentAddressSearches } from '../../../actions/msgApi';
import { PROPERTY_COMMONS } from '../../../actions/crux';
import NotificationBell from '.././NotificationBell';

import '../../../../css/crux/components/global/menu.scss';
import RadiusSearchButton from './RadiusSearchButton';
import WatchListDropdown from '../../watchList/dropdown/WatchListDropdown';
import Featured from '../../common/Featured';
import Roles from 'js/constants/entitlementsRole';
import Flags from 'js/constants/featureFlags';
import { LOGOS } from 'js/constants/assets';
import FormsDropdown from 'js/components/property/reiForms/formsDropdown/FormsDropdown';
import { STATE_UPPERCASE } from 'js/constants/states';

const CORELOGIC_LOGO = isAU ? LOGOS.RP_HEADER : LOGOS.PG_HEADER;

const UserPanel = lazy(() => import('../UserPanel'));

const { SAVED_LIST_STAR, PROPERTY_DETAIL } = Segment.EVENT_PROPERTIES;

const {
    SEARCH_RESULT,
    NAME_SEARCH_RESULT,
    ACCOUNT,
    PROPERTY,
    HOME,
    MARKET_INSIGHTS,
    PROPERTY_PRINT,
    USER_FAVORITES,
    SAVED_SEARCH,
    TITLE_ORDERING,
    SAVED_LIST,
    SAVED_TERRITORY,
} = routeCodes;


export class Menu extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        propertyCommons: PropTypes.object,
        error: PropTypes.object,
        history: PropTypes.any,
        clapiUsrDetail: PropTypes.object,
        featureFlag: PropTypes.object,
        avmDetails: PropTypes.object,
        targetProperty: PropTypes.object,
        userSummaryApiSuccess: PropTypes.bool,
        propertyCommonsSuccess: PropTypes.bool,
        location: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.object,
        ]),
        includeHistoric: PropTypes.bool,
        isHeaderFloating: PropTypes.bool,
        alertedPropertiesCount: PropTypes.number,
        newUserActivityFeeds: PropTypes.object,
    };
    static isVictoria(property) {
        const state = Commons.get(property, 'location.state');
        return state === STATE_UPPERCASE.VIC;
    }

    constructor(props) {
        super(props);
        this.state = {
            shouldRenderMenuSearchBar: false,
            shouldRenderBreadcrumb: false,
            shouldRenderActions: false,
            shouldRenderHistoricalBanner: false,
            route: null,
            propertyId: null,
            displayRentalEstimate: false,
            searchType: 'address',
            isPropertyExist: true,
            shouldRenderPropertyPrintHeader: false,
        };
        this.RPDSidebarRef = createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const route = getRoute(nextProps.match.path);
        const { rentalAvm, propertyCommons } = nextProps;
        const displayRentalEstimate = ReportHelper.isRentalEstimateToBeDisplayed(rentalAvm);
        const isPropertyExist = propertyCommons && propertyCommons.isValidProperty();

        if (route && route !== prevState.route) {
            return routeConfigs(route, {
                displayRentalEstimate,
                isPropertyExist,
                propertyCommonsSuccess: nextProps.propertyCommonsSuccess,
            });
        } else if (route === PROPERTY) {
            const propertyId = Commons.get(nextProps, 'propertyCommons.propertyId');
            if (propertyId !== prevState.propertyId) {
                return {
                    propertyId,
                    displayRentalEstimate,
                    isPropertyExist,
                };
            }
            return {
                shouldRenderBreadcrumb: true,
                displayRentalEstimate,
                isPropertyExist,
                shouldRenderHistoricalBanner: true,
            };
        }

        return {
            displayRentalEstimate,
            isPropertyExist,
        };
    }

    componentDidMount() {
        this.setPageTitle();
        this.setScrollListener();
        this.toggleSideBar();
    }

    shouldComponentUpdate() {
        const { error, isHeaderFloating } = this.props;
        const { shouldRenderMenuSearchBar, route } = this.state;
        switch (route.pageContext) {
            case PROPERTY.pageContext:
            case SEARCH_RESULT.pageContext:
            case NAME_SEARCH_RESULT.pageContext:
            case ACCOUNT.pageContext: {
                if (error && !shouldRenderMenuSearchBar && !isHeaderFloating) {
                    return false;
                }
                break;
            }
            case USER_FAVORITES.pageContext:
            case PROPERTY_PRINT.pageContext:
            case TITLE_ORDERING.pageContext:
            case HOME.pageContext: {
                return true;
            }
            default: {
                if (error) {
                    return false;
                }
                break;
            }
        }
        return true;
    }

    componentDidUpdate(prevProps) {
        const { match, dispatch } = this.props;
        this.setPageTitle();
        if (prevProps.match && prevProps.match.url !== match.url) {
            this.removeWindowScrollEventListener();
            this.setScrollListener();
            // will open the sidebar upon accessing the homepage for the first time
            // e.g RPP PDP to RPD PDP -> RPD HOME
            this.toggleSideBar();
            dispatch(getRecentAddressSearches());

            if (prevProps.isHeaderFloating) {
                this.toggleStickyHeader(false);
            }

            if (this.RPDSidebarRef.current) {
                this.RPDSidebarRef.current.setActivePane(false);
            }
        }
    }

    getStickyHeaderClassName = () => {
        const route = getRoute(this.props.match.path);
        let stickyHeaderClass = '';
        if (this.props.isHeaderFloating) {
            stickyHeaderClass = 'sticky-header';
            if (route === routeCodes.SEARCH_RESULT ||
                route === routeCodes.NAME_SEARCH_RESULT ||
                route === routeCodes.RADIUS_SEARCH_RESULT ||
                route === routeCodes.COMPANY_SEARCH_RESULT ||
                route === routeCodes.VOLUME_FOLIO_SEARCH_RESULT ||
                route === routeCodes.ACCOUNT ||
                route === routeCodes.MARKET_INSIGHTS) {
                stickyHeaderClass = `${stickyHeaderClass} sticky-header-shadow-less`;
            }
        }
        return stickyHeaderClass;
    }

    setPageTitle() {
        const { route } = this.state;
        switch (route.id) {
            case PROPERTY_PRINT.id:
            case PROPERTY.id: {
                const location = Commons.get(this.props, 'propertyCommons.location');
                const pageTitle = Commons.getSingleLineAddress(location);
                if (pageTitle) {
                    document.title = route.title(pageTitle);
                }
                break;
            }
            case routeCodes.SEARCH_RESULT.id: {
                const { location } = this.props;
                if (location.state && location.state.suggestion) {
                    const title = location.state.suggestion.suggestion ||
                        location.state.suggestion[location.state.suggestion.length - 1]?.suggestion
                    document.title = route.title(title);
                }
                break;
            }
            default: document.title = route.title; break;
        }
    }

    toggleStickyHeader = state => this.props.dispatch(floatHeader(state));

    handleScroll = () => {
        const atTop = window.scrollY <= 0;
        if (!atTop && !this.props.isHeaderFloating) {
            this.toggleStickyHeader(true);
        } else if (atTop && this.props.isHeaderFloating) {
            this.toggleStickyHeader(false);
        }
    };

    addWindowScrollEventListener = () => {
        window.addEventListener('scroll', this.handleScroll);
    }

    removeWindowScrollEventListener = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    setScrollListener = () => {
        const { route } = this.state;
        if ([PROPERTY.pageContext,
            SEARCH_RESULT.pageContext,
            NAME_SEARCH_RESULT.pageContext,
            ACCOUNT.pageContext,
            PROPERTY_PRINT.pageContext,
            MARKET_INSIGHTS.pageContext,
            TITLE_ORDERING.pageContext,
            SAVED_SEARCH.pageContext,
            SAVED_LIST.pageContext,
            SAVED_TERRITORY.pageContext]
            .includes(route.pageContext)) {
            this.addWindowScrollEventListener();
        } else {
            this.removeWindowScrollEventListener();
        }
    }

    setSearchType = (type = '') => {
        this.setState({ searchType: type.toLowerCase() });
    };

    setTooltip = id => (
        <ReactTooltip
            id={id}
            className="custom-tool-tip custom-tooltip-disabled tooltip-text"
            place="bottom"
            effect="solid"
            event="mouseover"
            eventOff="mouseleave"
        />
    );

    handleLogoOnClick = () => {
        const { dispatch } = this.props;
        const payload = {
            event: 'Click CoreLogic Header Logo',
            route: this.state.route,
        };
        dispatch(viewHomeEvent(payload));
        dispatch(invalidateCruxAppError());
    }

    toggleSideBar = () => {
        const { route } = this.state;
        const isOpened = LocalStorageHelper.get(PORTAL_SIDEBAR_KEY);

        if (this.RPDSidebarRef.current &&
            isHomePage(route) &&
            !isOpened) {
            // delay is required to avoid race condition
            Commons.delay(1).then(() => this.RPDSidebarRef?.current?.setActivePane(true));
            LocalStorageHelper.add(PORTAL_SIDEBAR_KEY, true);
        }
    };

    render() {
        const {
            match, propertyCommons,
            dispatch, history, clapiUsrDetail,
            userSummaryApiSuccess, featureFlag,
            includeHistoric, avmDetails,
            targetProperty,
        } = this.props;
        const {
            route, displayRentalEstimate, searchType,
            isPropertyExist, shouldRenderBreadcrumb, shouldRenderMenuSearchBar,
            shouldRenderActions, shouldRenderHistoricalBanner, shouldRenderPropertyPrintHeader,
        } = this.state;
        const { value } = targetProperty || {};
        const rapidV3Response = !!value && Sanitizer.rapidSearchToPropertyDetail(value);
        const formattedPropertyNode = Sanitizer.propertySummaryToUpdateProperty(propertyCommons);
        const isHistoricProperty = Commons.isHistoricProperty(propertyCommons);
        const displayAvmDetails = ReportHelper.isAVMDetailToBeDisplayed({ avmDetails });
        const searchTypeClass = searchType.replace(' ', '-');
        const isOnTitlesNZ = isNZ && route.path === TITLE_ORDERING.path;
        const addressState = value?.addressState;

        let address;
        let propertyId;
        if (propertyCommons) {
            address = Commons.getAddress(propertyCommons.location);
            propertyId = propertyCommons.propertyId;
        }

        return (
            <header
                id="crux-nav"
                className={`search-type-${searchTypeClass}${includeHistoric ? ' historic' : ''} ${this.getStickyHeaderClassName()}`}
            >
                <div className={`crux-header ${searchTypeClass}`}>
                    <div className="container-fluid heading-container">
                        <div className="nav-section">
                            {
                                !isBeforeHomepagePathname() &&
                                <SidebarMenu
                                    ref={this.RPDSidebarRef}
                                    featureFlag={featureFlag}
                                    pageContext={route.pageContext}
                                    searchType={searchType}
                                    route={route}
                                    match={match}
                                />
                            }
                            <div className="logo">
                                <Link to={isBeforeHomepagePathname() ? "#" : routeCodes.HOME.path} className="cl-logo" onClick={this.handleLogoOnClick}>
                                    <img className="cl-logo-img" alt="CoreLogic" src={CORELOGIC_LOGO} />
                                </Link>
                            </div>
                        </div>
                        {
                            shouldRenderMenuSearchBar &&
                            <SearchBarV2
                                route={route}
                                entryPoint={entryPoints[route.id].entryPoint}
                                setSearchType={this.setSearchType}
                                dispatch={dispatch}
                                featureFlag={featureFlag}
                                isTab={false}
                            />
                        }
                        {
                            !isBeforeTnCPathname() &&
                            <div className="menu-extras">
                                {
                                    !isOnTitlesNZ && <NotificationBell
                                        pageContext={route.pageContext}
                                        history={history}
                                        clapiUsrDetail={clapiUsrDetail}
                                        featureFlag={featureFlag}
                                    />
                                }
                                <Suspense fallback={""}>
                                    <UserPanel dispatch={dispatch} route={route} history={history} />
                                </Suspense>
                            </div>
                        }
                    </div>
                    {
                        shouldRenderBreadcrumb &&
                        <Container
                            maxWidth={false}
                            className="bread-crumb-container"
                        >
                            <div className="breadcrumb-print-container">
                                <RapidBreadcrumbs
                                    propertyId={formattedPropertyNode &&
                                        formattedPropertyNode.id}
                                    history={history}
                                />
                                {
                                    shouldRenderActions &&
                                    <div className="activity-bar-actions">
                                        <RadiusSearchButton
                                            rapidV3Response={rapidV3Response}
                                            dispatch={dispatch}
                                            route={route}
                                            propertyCommons={propertyCommons}
                                            targetProperty={targetProperty}
                                            clapiUsrDetail={clapiUsrDetail}
                                            isPropertyExist={isPropertyExist}
                                            match={match}
                                        />
                                        <Fragment>
                                            <CustomPrintButton
                                                addressComplete={address}
                                                propertyId={propertyId}
                                            />
                                        </Fragment>

                                        <PropertyCMA
                                            userSummaryApiSuccess={userSummaryApiSuccess}
                                            clapiUsrDetail={clapiUsrDetail}
                                            isPropertyExist={isPropertyExist}
                                            setTooltip={() => this.setTooltip('cma-report-button-tooltip')}
                                            isHistoricProperty={isHistoricProperty}
                                            pageContext={route.pageContext}
                                            propertyId={this.state.propertyId}
                                            dispatch={dispatch}
                                        />
                                        <Featured
                                            role={Roles.REI_FORMS}
                                            flag={Flags.FORMS_INTEGRATION}
                                            forceHide={isNZ || addressState === STATE_UPPERCASE.ACT}
                                        >
                                            <FormsDropdown
                                                addressState={addressState}
                                                historical={isHistoricProperty}
                                            />
                                        </Featured>
                                        <ReportMenu
                                            tooltipId="btnMenuTooltip"
                                            userSummaryApiSuccess={userSummaryApiSuccess}
                                            reportRoles={ReportHelper.getRolesWithExcludedRoles(clapiUsrDetail)}
                                            isVictoria={Menu.isVictoria(propertyCommons)}
                                            displayRentalEstimate={displayRentalEstimate}
                                            match={match}
                                            property={formattedPropertyNode}
                                            route={route}
                                            isPropertyExist={isPropertyExist}
                                            isHistoricProperty={isHistoricProperty}
                                            displayAvmDetails={displayAvmDetails}
                                            avmDetails={avmDetails}
                                            featureFlag={featureFlag}
                                        />
                                        <Featured
                                            role={Roles.SAVED_LISTS}
                                            flag={Flags.SAVED_LISTS}
                                        >
                                            <WatchListDropdown
                                                properties={[
                                                    propertyId,
                                                ]}
                                                page={PROPERTY_DETAIL}
                                                entryPoint={SAVED_LIST_STAR}
                                                isHistoricProperty={isHistoricProperty}
                                                isPropertyExist={isPropertyExist}
                                                shouldCallListInfo
                                            />
                                        </Featured>
                                    </div>
                                }
                            </div>
                        </Container>
                    }
                    {
                        isHistoricProperty && shouldRenderHistoricalBanner &&
                        <div className="historical-property-banner">
                            This property is a historic record that has since been sub-divided or amalgamated.
                        </div>
                    }
                    {
                        address && shouldRenderPropertyPrintHeader &&
                        <div className="container-fluid property-print-header-container">
                            <PropertyPrintHeader
                                address={address}
                                dispatch={dispatch}
                                match={match}
                            />
                        </div>
                    }
                </div>
            </header >
        );
    }
}

export default connect(state => ({
    propertyCommons: PropertyDetailCommonsResponseBody.parse(state.crux.get('property').get('commons')),
    error: state.errorHandler.get('error'),
    user: state.claud.get('user'),
    isConfigInitialized: state.config.get('isInitialized'),
    featureFlag: state.launchDarkly.get('featureFlag'),
    rentalAvm: state.clapi.get('rentalAvm'),
    avmDetails: state.clapi.get('avmDetails'),
    config: state.config,
    clUserGuid: '',
    clapiUsrDetail: state.clapi.get('usrDetail'),
    userSummaryApiSuccess: !state.errorHandler.get('componentErrors').get(GET_USER_DETAIL_FAIL),
    propertyCommonsSuccess: !state.errorHandler.get('componentErrors').get(PROPERTY_COMMONS),
    includeHistoric: isAU && LDHelper.featureFlagSelector(state, 'crux.historic.property.data', false),
    targetProperty: state.rapid.get('targetProperty'),
    isHeaderFloating: state.componentAccess.get('isHeaderFloating'),
}))(Menu);
