import { Map as IMap } from 'immutable';
import * as TYPE from '../../../source/js/actions/printPdp';
import { isAU, isNZ } from '../constants/crux';

export const initialState = IMap({
    imageGallery: null,
    map: {
        noData: true,
    },
    propertySummary: null,
    propertyNotes: null,
    householdInformation: null,
    propertyTimeline: null,
    marketTrends: null,
    nearbySchools: null,
    similarProperty: null,
    report: {
        fetching: true,
        error: false,
        pdfBytes: null,
    },
    additionalInformation: null,
    ratingValuations: null,
    titleInformation: null,
    panelInitialized: {
        similarProperties: false,
        nearbySchools: isNZ,
        ratingValuation: isAU,
        valuationEstimate: false,
        rentalEstimate: isAU,
        propertyHistory: false,
        propertyNotes: false,
        additionalInformation: false,
        titleInformation: isAU,
        marketTrends: false,
        householdInformation: false,
        propertySummary: false,
        imageGallery: false,
        map: false,
    },
});

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case TYPE.SAVE_PANEL_DETAILS:
            return state.merge(IMap({
                ...action.payload,
            }));
        case TYPE.CONVERT_HTML_TO_PDF_PDP:
            return state.merge(IMap({
                report: {
                    ...JSON.parse(JSON.stringify(state.get('report'))),
                    fetching: true,
                },
            }));
        case TYPE.CONVERT_HTML_TO_PDF_PDP_SUCCESS:
            return state.merge(IMap({
                report: {
                    fetching: false,
                    error: false,
                    pdfBytes: action.payload,
                },
            }));
        case TYPE.CONVERT_HTML_TO_PDF_PDP_FAIL:
            return state.merge(IMap({
                report: {
                    fetching: false,
                    error: true,
                    pdfBytes: null,
                },
            }));
        case TYPE.RESET_PANEL_DETAILS:
            return state.merge(initialState);
        case TYPE.PANEL_INITIALIZED:
            return state.merge(IMap({
                panelInitialized: {
                    ...state.get('panelInitialized'),
                    ...action.payload,
                },
            }));
        case TYPE.RESET_INITIALIZATION:
            return state.merge(IMap({
                panelInitialized: initialState.get('panelInitialized'),
            }));
        case TYPE.SET_SELECTED_SIMILAR_PROPERTY_THUMBNAIL: {
            const { selectedTabKey, thumbnailIndex, thumbnailPhotoIndex } = action.payload;
            const similarProperty = JSON.parse(JSON.stringify(state.get('similarProperty')));
            similarProperty
                .data[selectedTabKey][thumbnailIndex]
                .photoIndexToPrint = thumbnailPhotoIndex;
            return state.merge(IMap({ similarProperty }));
        }
        default:
            return state;
    }
};

