export const SEGMENT_INIT = 'SEGMENT_INIT';
export const SEGMENT_INIT_FAIL = 'SEGMENT_INIT_FAIL';
export const SEGMENT_INIT_SUCCESS = 'SEGMENT_INIT_SUCCESS';

export const SEGMENT_UPDATE_USER = 'SEGMENT_UPDATE_USER';
export const SEGMENT_UPDATE_USER_FAIL = 'SEGMENT_UPDATE_USER_FAIL';
export const SEGMENT_UPDATE_USER_SUCCESS = 'SEGMENT_UPDATE_USER_SUCCESS';

export const EVENT_VIEW_HOME = 'EVENT_VIEW_HOME';
export const EVENT_VIEW_HOME_FAIL = 'EVENT_VIEW_HOME_FAIL';
export const EVENT_VIEW_HOME_SUCCESS = 'EVENT_VIEW_HOME_SUCCESS';

export const EVENT_TRACKER_SUGGESTIONS = 'EVENT_TRACKER_SUGGESTIONS';
export const EVENT_TRACKER_SUGGESTIONS_FAIL = 'EVENT_TRACKER_SUGGESTIONS_FAIL';
export const EVENT_TRACKER_SUGGESTIONS_SUCCESS = 'EVENT_TRACKER_SUGGESTIONS_SUCCESS';

export const EVENT_TRACKER_IMAGE_CHANGE = 'EVENT_TRACKER_IMAGE_CHANGE';
export const EVENT_TRACKER_IMAGE_CHANGE_FAIL = 'EVENT_TRACKER_IMAGE_CHANGE_FAIL';
export const EVENT_TRACKER_IMAGE_CHANGE_SUCCESS = 'EVENT_TRACKER_IMAGE_CHANGE_SUCCESS';

export const EVENT_TRACKER_IMAGE_FULLSCREEN = 'EVENT_TRACKER_IMAGE_FULLSCREEN';
export const EVENT_TRACKER_IMAGE_FULLSCREEN_FAIL = 'EVENT_TRACKER_IMAGE_FULLSCREEN_FAIL';
export const EVENT_TRACKER_IMAGE_FULLSCREEN_SUCCESS = 'EVENT_TRACKER_IMAGE_FULLSCREEN_SUCCESS';

export const EVENT_TRACKER_BREADCRUMB_NAVIGATION = 'EVENT_TRACKER_BREADCRUMB_HOME_NAVIGATION';
export const EVENT_TRACKER_BREADCRUMB_NAVIGATION_FAIL = 'EVENT_TRACKER_BREADCRUMB_HOME_NAVIGATION_FAIL';
export const EVENT_TRACKER_BREADCRUMB_NAVIGATION_SUCCESS = 'EVENT_TRACKER_BREADCRUMB_HOME_NAVIGATION_SUCCESS';

export const EVENT_TRACKER_COPY_ICON = 'EVENT_TRACKER_COPY_ICON';
export const EVENT_TRACKER_COPY_ICON_FAIL = 'EVENT_TRACKER_COPY_ICON_FAIL';
export const EVENT_TRACKER_COPY_ICON_SUCCESS = 'EVENT_TRACKER_COPY_ICON_SUCCESS';

export const EVENT_TRACKER_SCROLL_FLOOR_PLAN = 'EVENT_TRACKER_SCROLL_FLOOR_PLAN';
export const EVENT_TRACKER_SCROLL_FLOOR_PLAN_FAIL = 'EVENT_TRACKER_SCROLL_FLOOR_PLAN_FAIL';
export const EVENT_TRACKER_SCROLL_FLOOR_PLAN_SUCCESS = 'EVENT_TRACKER_SCROLL_FLOOR_PLAN_SUCCESS';

export const EVENT_TRACKER_SEND_DOWNLOAD_REPORT = 'EVENT_TRACKER_SEND_DOWNLOAD_REPORT';
export const EVENT_TRACKER_SEND_DOWNLOAD_REPORT_FAIL = 'EVENT_TRACKER_SEND_DOWNLOAD_REPORT_FAIL';
export const EVENT_TRACKER_SEND_DOWNLOAD_REPORT_SUCCESS = 'EVENT_TRACKER_SEND_DOWNLOAD_REPORT_SUCCESS';

export const EVENT_TRACKER_SEND_PRINT_REPORT = 'EVENT_TRACKER_SEND_PRINT_REPORT';
export const EVENT_TRACKER_SEND_PRINT_REPORT_FAIL = 'EVENT_TRACKER_SEND_PRINT_REPORT_FAIL';
export const EVENT_TRACKER_SEND_PRINT_REPORT_SUCCESS = 'EVENT_TRACKER_SEND_PRINT_REPORT_SUCCESS';

export const EVENT_NAVIGATE_TO_RPP = 'EVENT_NAVIGATE_TO_RPP';
export const EVENT_NAVIGATE_TO_RPP_SUCCESS = 'EVENT_NAVIGATE_TO_RPP_SUCCESS';
export const EVENT_NAVIGATE_TO_RPP_FAIL = 'EVENT_NAVIGATE_TO_RPP_FAIL';

export const SHOW_MORE = 'SHOW_MORE';
export const SHOW_MORE_SUCCESS = 'SHOW_MORE_SUCCESS';
export const SHOW_MORE_FAIL = 'SHOW_MORE_FAIL';

export const EVENT_VIEW_LISTING_DESCRIPTION = 'EVENT_VIEW_LISTING_DESCRIPTION';
export const EVENT_VIEW_LISTING_DESCRIPTION_SUCCESS = 'EVENT_VIEW_LISTING_DESCRIPTION_SUCCESS';
export const EVENT_VIEW_LISTING_DESCRIPTION_FAIL = 'EVENT_VIEW_LISTING_DESCRIPTION_FAIL';

export const EVENT_VIEW_SIMILAR_PROPERTIES = 'EVENT_VIEW_SIMILAR_PROPERTIES';
export const EVENT_VIEW_SIMILAR_PROPERTIES_SUCCESS = 'EVENT_VIEW_SIMILAR_PROPERTIES_SUCCESS';
export const EVENT_VIEW_SIMILAR_PROPERTIES_FAIL = 'EVENT_VIEW_SIMILAR_PROPERTIES_FAIL';

export const EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS = 'EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS';
export const EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS_SUCCESS = 'EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS_SUCCESS';
export const EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS_FAIL = 'EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS_FAIL';

export const EVENT_TAB_CHANGE_SIMILAR_PROPERTIES = 'EVENT_TAB_CHANGE_SIMILAR_PROPERTIES';
export const EVENT_TAB_CHANGE_SIMILAR_PROPERTIES_SUCCESS = 'EVENT_TAB_CHANGE_SIMILAR_PROPERTIES_SUCCESS';
export const EVENT_TAB_CHANGE_SIMILAR_PROPERTIES_FAIL = 'EVENT_TAB_CHANGE_SIMILAR_PROPERTIES_FAIL';

export const EVENT_TRACKER_APPLY_FILTER = 'EVENT_TRACKER_APPLY_FILTER';
export const EVENT_TRACKER_APPLY_FILTER_SUCCESS = 'EVENT_TRACKER_APPLY_FILTER_SUCCESS';
export const EVENT_TRACKER_APPLY_FILTER_FAIL = 'EVENT_TRACKER_APPLY_FILTER_FAIL';

export const EVENT_TAB_CHANGE_NEARBY_SCHOOLS = 'EVENT_TAB_CHANGE_NEARBY_SCHOOLS';
export const EVENT_TAB_CHANGE_NEARBY_SCHOOLS_SUCCESS = 'EVENT_TAB_CHANGE_NEARBY_SCHOOLS_SUCCESS';
export const EVENT_TAB_CHANGE_NEARBY_SCHOOLS_FAIL = 'EVENT_TAB_CHANGE_NEARBY_SCHOOLS_FAIL';

export const EVENT_MOBILE_BADGE_CLICK = 'EVENT_MOBILE_BADGE_CLICK';
export const EVENT_MOBILE_BADGE_CLICK_FAIL = 'EVENT_MOBILE_BADGE_CLICK_FAIL';
export const EVENT_MOBILE_BADGE_CLICK_SUCCESS = 'EVENT_MOBILE_BADGE_CLICK_SUCCESS';

export const EVENT_MAP_MODAL_OPEN = 'EVENT_MAP_MODAL_OPEN';
export const EVENT_MAP_MODAL_OPEN_SUCCESS = 'EVENT_MAP_MODAL_OPEN_SUCCESS';
export const EVENT_MAP_MODAL_OPEN_FAIL = 'EVENT_MAP_MODAL_OPEN_FAIL';

export const EVENT_UPDATE_DEFAULT_DASHBOARD = 'EVENT_UPDATE_DEFAULT_DASHBOARD';
export const EVENT_UPDATE_DEFAULT_DASHBOARD_SUCCESS = 'EVENT_UPDATE_DEFAULT_DASHBOARD_SUCCESS';
export const EVENT_UPDATE_DEFAULT_DASHBOARD_FAIL = 'EVENT_UPDATE_DEFAULT_DASHBOARD_FAIL';

export const EVENT_TRACKER_NAME_SEARCH = 'EVENT_TRACKER_NAME_SEARCH';
export const EVENT_TRACKER_NAME_SEARCH_SUCCESS = 'EVENT_TRACKER_NAME_SEARCH_SUCCESS';
export const EVENT_TRACKER_NAME_SEARCH_FAIL = 'EVENT_TRACKER_NAME_SEARCH_FAIL';

export const EVENT_TRACKER_COMPANY_SEARCH = 'EVENT_TRACKER_COMPANY_SEARCH';
export const EVENT_TRACKER_COMPANY_SEARCH_SUCCESS = 'EVENT_TRACKER_COMPANY_SEARCH_SUCCESS';
export const EVENT_TRACKER_COMPANY_SEARCH_FAIL = 'EVENT_TRACKER_COMPANY_SEARCH_FAIL';

export const EVENT_TRACKER_ROLL_VALUATION_SEARCH = 'EVENT_TRACKER_ROLL_VALUATION_SEARCH';
export const EVENT_TRACKER_ROLL_VALUATION_SEARCH_SUCCESS = 'EVENT_TRACKER_ROLL_VALUATION_SEARCH_SUCCESS';
export const EVENT_TRACKER_ROLL_VALUATION_SEARCH_FAIL = 'EVENT_TRACKER_ROLL_VALUATION_SEARCH_FAIL';

export const EVENT_TRACKER_VOLUME_FOLIO_SEARCH = 'EVENT_TRACKER_VOLUME_FOLIO_SEARCH';
export const EVENT_TRACKER_VOLUME_FOLIO_SEARCH_SUCCESS = 'EVENT_TRACKER_VOLUME_FOLIO_SEARCH_SUCCESS';
export const EVENT_TRACKER_VOLUME_FOLIO_SEARCH_FAIL = 'EVENT_TRACKER_VOLUME_FOLIO_SEARCH_FAIL';

export const EVENT_TRACKER_INTELLIVAL_REPORT = 'EVENT_TRACKER_INTELLIVAL_REPORT';
export const EVENT_TRACKER_INTELLIVAL_REPORT_SUCCESS = 'EVENT_TRACKER_INTELLIVAL_REPORT_SUCCESS';
export const EVENT_TRACKER_INTELLIVAL_REPORT_FAIL = 'EVENT_TRACKER_INTELLIVAL_REPORT_FAIL';

export const EVENT_TRACK_PROPERTY_PROFILE_REPORT = 'EVENT_TRACK_PROPERTY_PROFILE_REPORT';
export const EVENT_TRACK_PROPERTY_PROFILE_REPORT_FAIL = 'EVENT_TRACK_PROPERTY_PROFILE_REPORT_FAIL';
export const EVENT_TRACK_PROPERTY_PROFILE_REPORT_SUCCESS = 'EVENT_TRACK_PROPERTY_PROFILE_REPORT_SUCCESS';

export const EVENT_TRACKER_HOME_ON_MAP = 'EVENT_TRACKER_HOME_ON_MAP';
export const EVENT_TRACKER_HOME_ON_MAP_SUCCESS = 'EVENT_TRACKER_HOME_ON_MAP_SUCCESS';
export const EVENT_TRACKER_HOME_ON_MAP_FAIL = 'EVENT_TRACKER_HOME_ON_MAP_FAIL';

export const EVENT_TRACKER_MAP_BUBBLE_CLICK = 'EVENT_TRACKER_MAP_BUBBLE_CLICK';
export const EVENT_TRACKER_MAP_BUBBLE_CLICK_SUCCESS = 'EVENT_TRACKER_MAP_BUBBLE_CLICK_SUCCESS';
export const EVENT_TRACKER_MAP_BUBBLE_CLICK_FAIL = 'EVENT_TRACKER_MAP_BUBBLE_CLICK_FAIL';

export const EVENT_NAVIGATE_TO_DEP_CALCULATOR = 'EVENT_NAVIGATE_TO_DEP_CALCULATOR';
export const EVENT_NAVIGATE_TO_DEP_CALCULATOR_SUCCESS = 'EVENT_NAVIGATE_TO_DEP_CALCULATOR_SUCCESS';
export const EVENT_NAVIGATE_TO_DEP_CALCULATOR_FAIL = 'EVENT_NAVIGATE_TO_DEP_CALCULATOR_FAIL';

export const EVENT_TRACKER = 'EVENT_TRACKER';
export const EVENT_TRACKER_SUCCESS = 'EVENT_TRACKER_SUCCESS';
export const EVENT_TRACKER_FAIL = 'EVENT_TRACKER_FAIL';

export function initialize() {
    return {
        type: SEGMENT_INIT,
    };
}

export function updateSegmentUser() {
    return {
        type: SEGMENT_UPDATE_USER,
    };
}

export function createEventSuggestion(
    pageContext, entryPoint, searchContext,
    searchString, searchType, timeFrame, historic,
    searchTab,
) {
    // * Page: {Page context} e.g. Home, Property Detail, Search Result
    // * Entry Point: Recent, Typed
    // * Search Context: e.g. Property, Postcode, Suburb, Street
    // * Search String:{Search string}
    // * Search Type: e.g. Address
    return {
        type: EVENT_TRACKER_SUGGESTIONS,
        payload: {
            entryPoint,
            searchContext,
            searchString,
            searchType,
            timeFrame,
            historic,
            searchTab,
        },
        pageContext,
    };
}

export function viewHomeEvent(payload) {
    return {
        type: EVENT_VIEW_HOME,
        payload,
    };
}

export function navigateImages(propertyId, panel, pageContext, objectContext) {
    return {
        type: EVENT_TRACKER_IMAGE_CHANGE,
        payload: propertyId,
        panel,
        pageContext,
        objectContext,
    };
}

export function imageFullScreenEvents(propertyId) {
    return {
        type: EVENT_TRACKER_IMAGE_FULLSCREEN,
        payload: propertyId,
    };
}

export function navigateBreadCrumb(payload) {
    return {
        type: EVENT_TRACKER_BREADCRUMB_NAVIGATION,
        payload,
    };
}

export function sendClickCopyIconEvent(propertyId, pageContext) {
    return {
        type: EVENT_TRACKER_COPY_ICON,
        payload: propertyId,
        pageContext,
    };
}

export function scrollToFloorPlanEvent(payload, pageContext) {
    return {
        type: EVENT_TRACKER_SCROLL_FLOOR_PLAN,
        payload,
        pageContext,
    };
}

export function sendDownloadReportEvent(propertyId, pageContext, reportFormat, reportType, preparedByChecked, entryPoint = null) {
    return {
        type: EVENT_TRACKER_SEND_DOWNLOAD_REPORT,
        payload: propertyId,
        pageContext,
        reportFormat,
        reportType,
        preparedByChecked,
        entryPoint,
    };
}

export function sendPrintReportEvent(propertyId, pageContext, reportType, preparedByChecked, entryPoint = null) {
    return {
        type: EVENT_TRACKER_SEND_PRINT_REPORT,
        payload: propertyId,
        pageContext,
        reportType,
        preparedByChecked,
        entryPoint,
    };
}

export function navigateToRppEvent(pageContext, propertyId) {
    return {
        type: EVENT_NAVIGATE_TO_RPP,
        payload: propertyId,
        pageContext,
    };
}

export function viewListingDescEvent(pageContext, propertyId, panelType) {
    return {
        type: EVENT_VIEW_LISTING_DESCRIPTION,
        payload: propertyId,
        pageContext,
        panelType,
    };
}

export function showMoreListingDesc(pageContext, propertyId, panelType) {
    return {
        type: SHOW_MORE,
        payload: { propertyId, pageContext, panelType },
    };
}

export function viewSimilarPropertiesEvent(pageContext, propertyId, panelType, tab) {
    return {
        type: EVENT_VIEW_SIMILAR_PROPERTIES,
        payload: propertyId,
        pageContext,
        panelType,
        tab,
    };
}

export function viewOnMapNearbySchoolsEvent(pageContext, propertyId, panelType, tab) {
    return {
        type: EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS,
        payload: propertyId,
        pageContext,
        panelType,
        tab,
    };
}

export function handleTabChangesEvent(pageContext, propertyId, panelType, tab) {
    return {
        type: EVENT_TAB_CHANGE_SIMILAR_PROPERTIES,
        payload: propertyId,
        pageContext,
        panelType,
        tab,
    };
}

export function trackApplyFilter(eventProps) {
    return {
        type: EVENT_TRACKER_APPLY_FILTER,
        payload: { eventProps },
    };
}

export function handleTabChangesNearbySchoolsEvent(pageContext, propertyId, panelType, tab) {
    return {
        type: EVENT_TAB_CHANGE_NEARBY_SCHOOLS,
        payload: propertyId,
        pageContext,
        panelType,
        tab,
    };
}

export function mobileBadgeClickEvent(brand, pageContext) {
    return {
        type: EVENT_MOBILE_BADGE_CLICK,
        payload: {
            brand,
            pageContext,
        },
    };
}

export function trackMapModalOpen(pageContext, propertyId, panelType) {
    return {
        type: EVENT_MAP_MODAL_OPEN,
        payload: propertyId,
        pageContext,
        panelType,
    };
}

export function trackDefaultDashboardUpdate(pageContext, newDefaultDashboard) {
    return {
        type: EVENT_UPDATE_DEFAULT_DASHBOARD,
        payload: newDefaultDashboard,
        pageContext,
    };
}

export function trackNameSearch(payload) {
    return {
        type: EVENT_TRACKER_NAME_SEARCH,
        payload,
    };
}

export function trackCompanySearch(payload) {
    return {
        type: EVENT_TRACKER_COMPANY_SEARCH,
        payload,
    };
}

export function trackRollValuation(payload) {
    return {
        type: EVENT_TRACKER_ROLL_VALUATION_SEARCH,
        payload,
    };
}

export function trackVolumeFolioSearch(payload) {
    return {
        type: EVENT_TRACKER_VOLUME_FOLIO_SEARCH,
        payload,
    };
}

export function trackIntellivalAvmReport(payload) {
    return {
        type: EVENT_TRACKER_INTELLIVAL_REPORT,
        payload,
    };
}

export function trackPropertyProfileReport(payload) {
    return {
        type: EVENT_TRACK_PROPERTY_PROFILE_REPORT,
        payload,
    };
}

export function trackHomeOnMap(payload) {
    return {
        type: EVENT_TRACKER_HOME_ON_MAP,
        payload,
    };
}

export function trackBubbleClickOnMap(payload) {
    return {
        type: EVENT_TRACKER_MAP_BUBBLE_CLICK,
        payload,
    };
}

export function navigateToDepCalculator(eventProps) {
    return {
        type: EVENT_NAVIGATE_TO_DEP_CALCULATOR,
        payload: eventProps,
    };
}

/**
 * Track Mixpanel event
 * @param payload
 * @returns {{payload: { eventName, properties }, type: string}}
 */
export function trackEvent(payload) {
    return {
        type: EVENT_TRACKER,
        payload,
    };
}
