import React, { PureComponent, Fragment, cloneElement } from 'react';
import PDF from 'react-pdf-infinite';
import BlockUi from 'react-block-ui';
import Button from '@mui/material/Button';
import b64toBlob from 'b64-to-blob';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ScrollableContainer from 'js/components/common/ScrollableContainer';
import Commons from 'js/helpers/Commons';
import { clearReports } from 'js/actions/pdfReports';
import { sendDownloadReportEvent, sendPrintReportEvent } from 'js/actions/segment';
import { getAssetDomain } from 'js/constants/assets';
import RetryReport from './RetryReport';
import { rmCruxCmptError } from 'js/actions/errorHandler';
import ReportHelper from 'js/helpers/report/ReportHelper';
import PDFViewerUtil from './PDFViewerUtil';

export class PDFViewer extends PureComponent {
    static propTypes = {
        propertyId: PropTypes.number.isRequired,
        trigger: PropTypes.object.isRequired,
        title: PropTypes.string,
        reports: PropTypes.object,
        dispatch: PropTypes.func,
        reportType: PropTypes.string,
        triggerHandler: PropTypes.func,
        filename: PropTypes.string,
        toggleReportModal: PropTypes.func, // can be found on menu.jsx
        clearBg: PropTypes.bool,
        preparedByChecked: PropTypes.bool,
        manualOpenModal: PropTypes.bool,
        entryPoint: PropTypes.string,
        clUsrId: PropTypes.string,
    };

    static defaultProps = {
        toggleReportModal: () => {/* do nothing */},
        manualOpenModal: false,
        entryPoint: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            remoteUrl: null,
            openPdf: false,
            pdfBytes: null,
            pdfLoaded: false,
            base64Blob: null,
            base64Url: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { reports } = nextProps;
        const newState = { ...prevState };
        if (reports) {
            const origin = reports[reports.key] || null;
            newState.base64Blob = null;
            newState.base64Url = null;
            // check if origin is not a url
            if (PDFViewerUtil.isNotValidUrl(origin)) {
                // return state with constructed base64 mimeType and url
                newState.base64Blob = b64toBlob(origin, 'application/pdf');
                newState.base64Url = URL.createObjectURL(newState.base64Blob);
            }

            if (!Commons.isObjectEmpty(reports) && !reports.success) {
                newState.pdfLoaded = false;
            }
            return newState;
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reports !== this.props.reports) {
            this.fetchPdfUrl(this.props);
        }
    }

    onPrint = () => {
        ReportHelper.printByClassname('.crux-print-dialog-body canvas');
        const {
            dispatch, propertyId, reportType, preparedByChecked, entryPoint,
        } = this.props;
        // send mix panel event
        dispatch(sendPrintReportEvent(propertyId, 'Report Preview', reportType, preparedByChecked, entryPoint));
    };

    onDownload = (e) => {
        const {
            dispatch, propertyId, reportType, preparedByChecked, entryPoint,
        } = this.props;
        // send mix panel event
        dispatch(sendDownloadReportEvent(propertyId, 'Report Preview', 'PDF', reportType, preparedByChecked, entryPoint));
        const ua = window.navigator.userAgent;
        if (PDFViewerUtil.isSafari() || PDFViewerUtil.isIE(ua)) {
            e.preventDefault();
            this.download([this.state.pdfBytes], this.getFilename());
        }
    };

    onGenerateReportClick = () => {
        if (this.props.manualOpenModal) {
            const pristine = this.props.triggerHandler();
            if (pristine) {
                this.openPdfModal();
            }
        } else {
            this.props.triggerHandler();
            this.openPdfModal();
        }
    };

    openPdfModal() {
        this.setState({ openPdf: true });
    }

    getFilename() {
        const { title, filename } = this.props;
        return ReportHelper.getFilename(title, filename);
    }

    clearReports = () => {
        const { remoteUrl, base64Url } = this.state;
        this.props.dispatch(clearReports());
        const blobUrl = base64Url || remoteUrl;
        if (blobUrl) {
            URL.revokeObjectURL(blobUrl);
        }
        this.setState({
            openPdf: false, remoteUrl: null, pdfBytes: null, pdfLoaded: false,
        });
    };

    download = (data, name) => {
        const ua = window.navigator.userAgent;
        const { base64Blob, base64Url } = this.state;
        const blob = base64Blob || new Blob(data, { type: 'octet/stream' });

        // is IE
        if (PDFViewerUtil.isIE(ua)) {
            window.navigator.msSaveOrOpenBlob(blob, name);
        }
        if (PDFViewerUtil.isSafari()) {
            const url = base64Url || URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.cssText = 'display: none';

            a.href = url;
            a.download = name;
            a.click();

            if (!base64Url) {
                URL.revokeObjectURL(url);
            }
        }
    };

    handleClose() {
        this.clearReports();
        this.props.toggleReportModal();
    }

    fetchPdfUrl(nextProps) {
        const { reports } = nextProps;
        const { remoteUrl } = nextProps;
        const origin = reports[reports.key] || null;
        if (PDFViewerUtil.isUseOriginUrl(origin, remoteUrl)) {
            // update remoteUrl when origin is link
            // get blob from link && blob is byte: which we want if the pdf needs to be download
            PDFViewerUtil.getBlobLink(origin
                , (newValue) => this.setState(newValue));
        } else {
            // clear generated url for 1 page report
            this.setState({ remoteUrl: null });
        }
    }

    isPDFLoading() {
        const { reports } = this.props;
        // get report endpoint with specific key
        const origin = reports[reports.key] || null;
        const { remoteUrl, base64Url } = this.state;
        return PDFViewerUtil.hasRemoteOrOriginUrl(origin, remoteUrl, base64Url);;
    }

    activateBtns = () => {
        this.setState({ pdfLoaded: true });
    };

    renderTriggerComponent() {
        const { trigger } = this.props;
        // recreate trigger from props and add onPrint handler
        return cloneElement(trigger, {
            onClick: this.onGenerateReportClick,
        });
    }

    retryReport = async () => {
        const { dispatch, reports } = this.props;
        try {
            dispatch(rmCruxCmptError(reports.key));
            this.props.triggerHandler();
        } catch (error) {
            console.log('Retry PDF Report: ', error);
        }
    };

    renderCruxModal() {
        if (this.state.openPdf) {
            const { error } = this.props;
            const { remoteUrl, pdfLoaded, base64Url } = this.state;
            const {
                title,
                clearBg,
            } = this.props;
            // total space between the screen top and bottom from the modal body
            const verticalScreenSpacing = '261px';
            // disable onClose click on backdrop for rental estimate report
            return (
                <Dialog
                    open={this.state.openPdf}
                    maxWidth="md"
                    onClose={(event, reason) => reason !== 'backdropClick' && this.handleClose()}
                    classes={{
                        root: `file crux-print-dialog ${clearBg ? 'modal-clear-bg' : ''}`,
                        paper: 'scroll-body'
                    }}
                >
                    <DialogTitle className="crux-print-dialog-title"
                                 sx={{
                                     lineHeight: 1.2,
                                 }}
                    >
                        { title || '' }
                        <CloseIcon className="close-button-mui pull-right" onClick={() => this.handleClose()} cursor="pointer"/>
                    </DialogTitle>
                    <DialogContent className="crux-print-dialog-body">
                        {
                            error ?  <RetryReport onClick={() => this.retryReport()} /> :
                                <BlockUi tag="div" blocking={this.isPDFLoading()} renderChildren={false}>
                                    <ScrollableContainer
                                        heightRelativeToParent={`calc(100vh - ${verticalScreenSpacing})`}
                                        allowOuterScroll
                                        keepAtBottom
                                        data-testid="scrollable-container"
                                    >
                                        <PDF
                                            file={base64Url || remoteUrl}
                                            fullWidth
                                            onDocumentComplete={this.activateBtns}
                                            loading={<BlockUi tag="div" blocking/>}
                                            scale={1.5}
                                        />
                                    </ScrollableContainer>
                                </BlockUi>
                        }
                    </DialogContent>
                    <DialogActions className="pdf-viewer-footer crux-print-dialog-footer">
                        {
                            pdfLoaded &&
                            <Fragment>
                                <Button
                                    variant="outlined"
                                    className={`crux-modal-print-btn pdf-btn${!pdfLoaded ? ' pdf-btn-disabled' : ''}`}
                                    component="span"
                                    target="_blank"
                                    disabled={!pdfLoaded}
                                    onClick={this.onPrint}
                                >
                                    <img src={getAssetDomain('print@2x.png')} alt="print" width="16px"/>
                                    Print
                                </Button>
                                <Button
                                    variant="outlined"
                                    className={`crux-modal-download-btn pdf-btn${!pdfLoaded ? ' pdf-btn-disabled' : ''}`}
                                    component="a"
                                    target="_blank"
                                    disabled={!pdfLoaded}
                                    href={`${base64Url || remoteUrl || 'javascript:;'}`}
                                    download={this.getFilename()}
                                    onClick={this.onDownload}
                                >
                                    <img src={getAssetDomain('download@2x.png')} alt="" width="16px"/>
                                    Download
                                </Button>
                            </Fragment>
                        }
                    </DialogActions>
                </Dialog>
            );
        }
        return null;
    }

    render() {
        return (
            <Fragment>
                {this.renderTriggerComponent()}
                {this.renderCruxModal()}
            </Fragment>
        );
    }
}

export default connect(state => {
    const reports = state.pdfReports.get('reports');
    return {
        reports,
        error: state.errorHandler.get('componentErrors').get(reports.key),
        clUsrId: state.claud.get('session')?.clusrId,
    }
})(PDFViewer);
