import Commons from './Commons';
import RapidLocalisationHelper from './search/RapidLocalisationHelper';

const formatFromCamelCase = str => Array.from(new Set(str.split(/([A-Z]?[a-z]+)/g))).join(' ').trim();

const transformToLowerCase = (obj) => {
    if (obj || typeof obj === 'boolean' || typeof obj === 'number') {
        return String(obj).toLowerCase();
    }
    return '';
};

const featureListSorter = (prev, next) => {
    const prevName = prev.name.toLowerCase();
    const nextName = next.name.toLowerCase();
    if (prevName < nextName) {
        return -1;
    } else if (prevName > nextName) {
        return 1;
    }
    return 0;
};

const featureListFilter = feature => (transformToLowerCase(feature.name) !== 'development zone'
    && transformToLowerCase(feature.value) !== 'no'
    && transformToLowerCase(feature.value) !== 'false'
    && transformToLowerCase(feature.name) !== 'year built'
    && transformToLowerCase(feature.value) !== '0');

function placesGeoToSchool(place) {
    const [school] = Commons.get(place, 'schoolDetails.placeMetaData.school') || [];
    // address' rule/s:
    // when placeSingleLineAddress is not available then use
    // schoolDetails.placeMetaData.school[0].schoolAddressSupplied
    // when both are available then use placeSingleLineAddress
    const address = Commons.get(place, 'schoolDetails.placeSingleLineAddress') ||
        (school && (school.schoolAddressSupplied || null));
    return {
        id: place.placeId || null,
        type: school && (school.schoolType || null),
        coordinate: {
            lat: place.latitude || null,
            lng: place.longitude || null,
        },
        address,
        name: school && (school.schoolName || null),
        distanceFromTarget: place.distanceFromTarget || null,
    };
}

function propertySummaryToUpdateProperty(propertySummaryByIds, searchResult) {
    let attributes = null;
    const attrCore = Commons.get(propertySummaryByIds, 'attrCore');
    const attrAdditional = Commons.get(propertySummaryByIds, 'attrAdditional');
    const floorArea = Commons.get(propertySummaryByIds, 'attrAdditional.floorArea');

    if (attrCore) {
        const {
            attrCore: {
                beds,
                baths,
                ...rest
            } = {},
        } = propertySummaryByIds || {};
        attributes = {
            bedrooms: beds,
            bathrooms: baths,
            floorArea,
            ...rest,
        };
    } else if (attrAdditional) {
        attributes = {
            floorArea,
        };
    }

    return {
        id: Commons.get(searchResult, 'id') || Commons.get(propertySummaryByIds, 'propertyId'),
        address: {
            singleLine: Commons.get(searchResult, 'address.singleLineAddress') || Commons.get(propertySummaryByIds, 'location.singleLine'),
            street: {
                locality: {
                    id: Commons.get(searchResult, 'locationIdentifiers.localityId') || Commons.get(propertySummaryByIds, 'location.locality.id') || Commons.get(searchResult, 'location.locality.id'),
                    singleLine: Commons.get(propertySummaryByIds, 'location.locality.singleLine') || Commons.get(searchResult, 'location.locality.singleLine'),
                    postcode: {
                        state: Commons.get(propertySummaryByIds, 'location.state') || Commons.get(searchResult, 'location.state'),
                    },
                    name: Commons.get(propertySummaryByIds, 'location.locality.name') || Commons.get(searchResult, 'location.locality.name'),
                },
            },
            councilAreaId: Commons.get(propertySummaryByIds, 'location.councilAreaId')
        },
        coordinate: {
            latitude: Commons.get(propertySummaryByIds, 'location.latitude') || Commons.get(searchResult, 'location.latitude'),
            longitude: Commons.get(propertySummaryByIds, 'location.longitude') || Commons.get(searchResult, 'location.longitude'),
        },
        attributes,
        isActiveProperty: Commons.get(propertySummaryByIds, 'isActiveProperty'),
    };
}

// todo remove once migration of radius search modal, update property modal and reports is done
// components should access what they need directly instead of passing the whole property data
function rapidSearchToPropertyDetail(rapidSearchData, propertySummaryDetail) {
    const {
        id,
        addressComplete,
        addressLocation: {
            lat,
            lon,
        } = {},
        addressState,
        isActive,
        beds,
        baths,
        carSpaces,
        floorArea,
        landArea,
        type,
    } = rapidSearchData || {};
    const attributes = {
        propertyType: type,
        bedrooms: beds,
        bathrooms: baths,
        carSpaces,
        floorArea,
        landArea,
    };
    const localityId = Commons.get(propertySummaryDetail, 'location.locality.id');
    const localitySingleLine = Commons.get(propertySummaryDetail, 'location.locality.singleLine')
        || RapidLocalisationHelper.getRapidLocalitySingleLine(rapidSearchData);
    const localityName = Commons.get(propertySummaryDetail, 'location.locality.name');
    const singleLineAddress = Commons.get(propertySummaryDetail, 'location.singleLine');
    const councilAreaId = Commons.get(propertySummaryDetail, 'location.councilAreaId');

    // todo Cleanup structure to flat object to avoid remnants of old calls if sanitizing cannot be avoided
    return {
        id,
        address: {
            singleLine: singleLineAddress || addressComplete,
            street: {
                locality: {
                    id: localityId,
                    singleLine: localitySingleLine,
                    postcode: {
                        state: addressState,
                    },
                    name: localityName,
                },
            },
            councilAreaId,
        },
        coordinate: { latitude: lat, longitude: lon },
        attributes,
        isActiveProperty: isActive,
    };
}

function userPrefSummaryToUserDetail(usrDetail) {
    const usrDetailTemp = { ...usrDetail };
    const { userDetail } = usrDetailTemp;
    delete usrDetailTemp.userDetail;

    return {
        ...usrDetailTemp,
        ...userDetail,
    };
}

function buildPropertyFeatureList(
    featureListParam = [],
    featuresParam = [],
    attrCoreParam = {},
    attrAdditionalParam = {},
) {
    const featureList = JSON.parse(JSON.stringify(featureListParam));
    if (!Commons.isObjectEmpty(attrAdditionalParam)) {
        let val;
        Object.keys(attrAdditionalParam).forEach((key) => {
            val = attrAdditionalParam[key];
            if (val) {
                if (typeof attrAdditionalParam[key] === 'boolean' && val) {
                    val = '';
                }
                featureList.push({
                    name: formatFromCamelCase(key),
                    value: val,
                    type: typeof attrAdditionalParam[key],
                });
            }
        });
    }

    if (featuresParam.length > 0) {
        featuresParam.forEach((feature) => {
            featureList.push({
                name: feature,
                value: '',
                type: 'boolean',
            });
        });
    }

    if (attrCoreParam && !Commons.isObjectEmpty(attrCoreParam)) {
        if (attrCoreParam.lockUpGarages) {
            featureList.push({
                name: 'Lockup Garages',
                value: attrCoreParam.lockUpGarages,
                type: 'number',
            });
        }
    }

    featureList.sort(featureListSorter);

    return featureList.filter(featureListFilter);
}

export default {
    placesGeoToSchool,
    propertySummaryToUpdateProperty,
    rapidSearchToPropertyDetail,
    userPrefSummaryToUserDetail,
    buildPropertyFeatureList,
};
