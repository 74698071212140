import { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import withPageErrorHandler from '../higherOrderComponents/WithPageErrorHandler';
import { initialize as initializeConfig } from '../../actions/config';
import { getUserDetail } from '../../actions/claud';
import CLAPI from '../../api/clapi';
import { getWatchList } from '../../actions/watchList';
import { isBeforeTnCPathname } from '../../constants/routes';
import TerritoryMapModal from '../global/fullPageModal/TerritoryMapModal';

export const CruxPageLayout = ({
    header, children, footer,
    isHomePage, isCopyDisabled, history, permission
}) => {
    const store = useSelector(({ clapi, launchDarkly, claud }) => ({
        isFeatureFlagInitialized: launchDarkly.get('isFeatureFlagInitialized'),
        featureFlags: launchDarkly.get('featureFlag'),
        usrDetail: clapi.get('usrDetail'),
        claudUser: claud.get('user'),
    }));
    const dispatch = useDispatch();

    const initCompleted = !!store.usrDetail && !!store.isFeatureFlagInitialized;

    useEffect(() => {
        console.log("Crux/Init -> CruxPageLayout --> UseEffect");

        let selectedClAppAccountUserGuid = CLAPI.getSelectedClAppAccountUserGuid();
        if (selectedClAppAccountUserGuid && !store.claudUser) { // handles opening in a new tab
            dispatch(getUserDetail(selectedClAppAccountUserGuid));
        }

        if (!isBeforeTnCPathname() && !initCompleted) {
            dispatch(initializeConfig(process.env.REACT_APP_VERSION));
        }
    }, [history.location.pathname]);

    useEffect(() => {
        // Making sure usrDetail and featureFlags are already initialized
        // because watchlist depends on both of these properties
        if (store.usrDetail && store.featureFlags) {
            dispatch(getWatchList());
        }
    }, [store.usrDetail, store.featureFlags])

    useLayoutEffect(() => {
        // ON MOUNT
        const keyDownCallback = (e) => {
            // CTRL Key is for windows only particularly on Edge Browser
            // FF, CHROME, SAFARI, and Chromium Edge is working fine
            if (e.ctrlKey) { // "ctrl" key
                if ([67, 88].includes(e.keyCode)) { // "c|x" key
                    e.preventDefault();
                    return false;
                }
            }
            return true;
        };
        if (isCopyDisabled) {
            document.body.addEventListener('keydown', keyDownCallback);
        }
        // UNMOUNTING
        return () => {
            if (isCopyDisabled) {
                document.body.removeEventListener('keydown', keyDownCallback);
            }
        };
    }, [isCopyDisabled]);

    //
    // CruxPageLayout execution logic start:
    //

    if (!isBeforeTnCPathname() && !initCompleted) {
        return <BlockUi tag="div" blocking />;
    }

    if (permission && !permission.hasAccess({
        userDetails: store.usrDetail,
        location: history.location,
        history,
        featureFlags: store.featureFlags,
    })) {
        return <BlockUi tag="div" blocking />;
    }

    return (
        <div
            id="crux-layout"
            className={isHomePage && 'is-home-page'}
            onContextMenu={isCopyDisabled ? (e => e.preventDefault()) : undefined}
            onCopy={isCopyDisabled ? (e => e.preventDefault()): undefined}
            onCut={isCopyDisabled ? (e => e.preventDefault()): undefined}
        >
            {header}
            <div className="wrapper">
                <TerritoryMapModal>
                    {children}
                </TerritoryMapModal>
                {footer}
            </div>
        </div>
    );
};

CruxPageLayout.propTypes = {
    children: PropTypes.element,
    header: PropTypes.element,
    footer: PropTypes.element,
    isHomePage: PropTypes.bool,
    isCopyDisabled: PropTypes.bool,
};

export default withPageErrorHandler(CruxPageLayout);
