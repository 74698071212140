import API from './index';
import methods from '../constants/methods';
import { persistConfig } from '../store/localStoragePersist';
import { purgeStoredState } from 'redux-persist';
import CLAPI from './clapi';
import { IS_PRODUCTION } from 'js/constants/crux';

export const USER_PREF = {
    MARKET_INSIGHTS_AGREEMENT_NAME: 'MARKET_INSIGHTS_AGREEMENT',
    GET_ERROR_MESSAGE: 'Unable to retrieve preference',
    POST_ERROR_MESSAGE: 'Unable to update preference',
};
const REQUESTED_PATH = 'requested_path';
const AUTH_PATH = '/oauth2/authorization/auth';

const saveRequestedPath = () => {
    if (window.location.pathname && window.location.pathname !== '/') {
        const requestedPath = window.location.pathname + window.location.search;
        localStorage.setItem(REQUESTED_PATH, requestedPath);
    }
};

const getAndRemoveRequestedPath = () => {
    const requestedPath = localStorage.getItem(REQUESTED_PATH);
    localStorage.removeItem(REQUESTED_PATH);
    return requestedPath;
};

const redirectToLogin = () => {
    window.location.replace(API.getUrlInit(AUTH_PATH));
};

const getTargetResource = () => {
    const pathParams = AUTH_PATH;
    if (IS_PRODUCTION) {
        return `${window.location.origin}${pathParams}`;
    }
    return `http://${window.location.hostname}:8080${pathParams}`;
};

const logout = async () => {
    purgeStoredState(persistConfig);
    window
        .location
        .replace(`${window.ENV.PING_FEDERATE_DOMAIN}/idp/startSLO.ping?LogoutType=SyncOnly&TargetResource=${getTargetResource()}`);
};

const joinCrux = (params) => API.initiateSessionApiCall(
    {
        path: '/api/join/crux' + params,
        isProxy: true,
        timeout: API.getTimeouts().large,
    },
    {
        method: methods.POST.method,
    },
);

const postJoinCrux = (request) => {
    const requestOptions = {
        path: `/api/post-join/crux?clAppAccountUserGuid=${CLAPI.getSelectedClAppAccountUserGuid()}`,
        protected: true,
    };
    const headerOptions = {
        method: methods.POST.method,
        body: request,
        headers: {
            'Content-Type': 'application/json',
            ...getXsrfHeader(),
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

// Return null if currently there is no post-join request in progress for the current user; otherwise, return the in-progress clAppAccountUserGuid.
const postJoinInProgress = () => API.initiateSessionApiCall(
    {
        path: '/api/post-join/in-progress',
        protected: true,
        timeout: API.getTimeouts().large,
    },
    {
        method: methods.GET.method,
    },
);

const autoLogin = (params) => API.initiateSessionApiCall(
    {
        path: '/api' + params,
        isProxy: true,
        timeout: API.getTimeouts().large, // increase this value if debugging auto-login at backend
    },
    {
        method: methods.POST.method,
    },
);

const getCsrfToken = () => document.cookie.replace(/(?:^|.*;\s*)APP2SESSION-XSRF\s*=\s*([^;]*).*$|^.*$/, '$1');

const getXsrfHeader = () => ({
    'X-XSRF-TOKEN': getCsrfToken(),
});

export default {
    redirectToLogin,
    logout,
    joinCrux,
    postJoinCrux,
    postJoinInProgress,
    autoLogin,
    getXsrfHeader,
    saveRequestedPath,
    getAndRemoveRequestedPath,
};
