import { Map as IMap } from 'immutable';
import * as TYPE from 'js/actions/componentAccess';
import { CRUX_APP_ERROR } from 'js/actions/errorHandler';
import Options from 'js/constants/options/dropdownOptions';

export const initialState = IMap({
    panelModalActive: false,
    activePropertyMarker: null,
    nearbySchoolActiveTab: 0,
    isHeaderFloating: false,
    searchSuggestions: {
        isSuggestionLoading: false,
        hasError: false,
    },
    selectedSearchOption: sessionStorage.getItem('searchType') || Options.ADDRESS,
    snackBar: {
        display: false,
        severity: null,
        message: null,
    }
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case CRUX_APP_ERROR:
            return state.merge(IMap({
                panelModalActive: false,
                activePropertyMarker: null,
            }));
        case TYPE.SET_SELECTED_PROPERTY_MARKER:
            return state.merge(IMap({
                activePropertyMarker: action.payload,
            }));
        case TYPE.SET_NEARBY_SCHOOL_ACTIVE_TAB:
            return state.merge(IMap({
                nearbySchoolActiveTab: action.payload,
            }));
        case TYPE.FLOAT_HEADER:
            return state.merge(IMap({
                isHeaderFloating: action.payload,
            }));
        case TYPE.TOGGLE_SUGGESTIONS_LOADER:
            return state.merge(IMap({
                searchSuggestions: action.payload,
            }));
        case TYPE.SAVE_SEARCH_TYPE:
            return state.merge(IMap({
                selectedSearchOption: action.payload,
            }));
        case TYPE.SET_SNACK_BAR: {
            return state.merge(IMap({
                snackBar: action.payload,
            }));
        }
        default:
            return state;
    }
}
