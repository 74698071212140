import Commons from './Commons';
import { UriBuilder } from './UriBuilder';
import { SUGGEST_FILTERS } from './Localization';
import RapidSearchParamsBuilder from './search/RapidSearchParamsBuilder';
import { isNZ } from '../constants/crux';
import SUGGESTION_TYPES from '../constants/suggestionTypes';
import ClapiApi from '../api/clapi';
import { routeCodes } from '../constants/routes';
import { cruxAppError } from '../actions/errorHandler';
import ErrorMsg from '../constants/errorMsg';
import { ErrorType } from '../constants/redirect';
import { cruxUnloader } from '../actions/crux';
import {
    AND_OPERATOR,
    SORT_PARAM,
    RESULTS_FORMAT, RESULTS_FORMAT_SORT,
    RESULT_FORMAT_FIELDS_PARAMS, RADIUS_SEARCH_FIELDS_PARAMS,
    RADIUS_SEARCH_FORMAT, SEARCH_NAME_PARAMS, LISTING_DATA_PARAM,
    VOLUME_FOLIO_DATA_PARAM, VOLUME_FOLIO_PARAMS, TENURE_PARAMS,
    TENURE_DATA_PARAM, OR_OPERATOR, IS_FOR_SALE, DEFAULT_RESULTS_FORMAT,
    TERRITORIES_SEARCH_FORMAT, IS_ACTIVE_PARAM,
} from '../constants/batchSearch/batchSearch';
import RapidBatchSearch from './search/batch/RapidBatchSearch';
import SearchBuilder from './search/batch/SearchBuilder';
import OTM from '../constants/otm';

const buildAddressSearchParam = (suggestionType, searchString) => {
    const addressParams = searchString.split(',');
    const clonedAddressSearchObj = {};
    let idx = 0;
    if (suggestionType === SUGGESTION_TYPES.STREET) {
        idx = 1;
        clonedAddressSearchObj.addressStreet = addressParams[0] && addressParams[0].trim();
    }
    clonedAddressSearchObj.addressSuburb = addressParams[idx] && addressParams[idx].trim();
    clonedAddressSearchObj.addressTown = addressParams[1 + idx] && addressParams[1 + idx].trim();
    return clonedAddressSearchObj;
};

const buildSuggestionRapidSearchFilter = (suggestion) => {
    const { suggestion: searchString, suggestionType } = suggestion || {};
    if (isNZ) {
        if (suggestionType === SUGGESTION_TYPES.STREET) {
            if (searchString && !searchString.includes(',')) {
                return { [SUGGEST_FILTERS.streetOnly]: searchString };
            }
            return buildAddressSearchParam(suggestionType, searchString);
        } else if (suggestionType === SUGGESTION_TYPES.LOCALITY) {
            return buildAddressSearchParam(suggestionType, searchString);
        }
    }
    // default return
    return searchString && suggestionType ?
        { [SUGGEST_FILTERS[suggestionType]]: searchString } : {};
};

const buildMultiLocalityFilter = (suggestions) => ({
    [SUGGEST_FILTERS[suggestions[0].suggestionType]]: suggestions
        .map(suggestion => suggestion.suggestion)
        .join('|')
});

const buildRapidSearchQueryParams = (searchFilters) => {
    const filters = JSON.parse(JSON.stringify(searchFilters));
    const uri = new UriBuilder('');

    if (filters.radius) filters.radius = parseFloat(filters.radius);
    delete filters.suburbOnly;

    const fieldNames = Object.getOwnPropertyNames(filters);
    // Construct url query params
    RapidSearchParamsBuilder(
        uri,
        fieldNames,
        filters,
    );

    return uri;
};

const flatten = (filters, ownerCompanySearch, volumeFolioSearch) =>
    (filters &&
    (JSON.stringify(ownerCompanySearch) !== '{}'
        || JSON.stringify(volumeFolioSearch) !== '{}')
        ? filters.flat()
        : filters);

// TODO: Find a way to break out specific filters by search type,
//  this method caters a lot of things already. We might need to cater multiple property id as
//  parameter in the future, it will be easier to add another filter if we break things out.
const buildRequest = (searchFilters) => {
    const { statusType, propertyIds, ...params } = JSON.parse(JSON.stringify(searchFilters));
    let outerFilters = [];
    const filters = [];
    let radiusSearchFilters = {};
    let territorySearchFilters = {};
    let resultsFormat = DEFAULT_RESULTS_FORMAT;

    const radiusParams = {};
    const territoriesParams = {};

    // suburbOnly param is not needed because we can determine if we are targeting
    // for suburb only using the addressSuburb & addressPostcodeState params
    delete params.suburbOnly;
    const ownerCompanySearch = {};
    const volumeFolioSearch = {};
    const tenureSearch = {};
    const builder = statusType === OTM.WITHDRAWN
        ? (RapidBatchSearch.batchWithdrawnParamBuilder)
        : RapidBatchSearch.batchParamBuilder;
    Object.keys(params).forEach((param) => {
        const paramValue = params[param];
        if (typeof paramValue === 'string' && paramValue.includes('|')) {
            // build multiple
            const multiParams = paramValue.split('|');
            filters.push({
                operation: OR_OPERATOR,
                filters: multiParams.map((multiParam) => builder(
                    param,
                    { param, paramValue: multiParam },
                )),
            });
        } else if (RESULT_FORMAT_FIELDS_PARAMS.includes(param)) {
            const builderExpression = param === SORT_PARAM ? RESULTS_FORMAT_SORT : RESULTS_FORMAT;
            resultsFormat = builder(
                builderExpression,
                { resultsFormat, param, paramValue },
            );
        } else if (RADIUS_SEARCH_FIELDS_PARAMS.includes(param)) {
            radiusSearchFilters = builder(
                RADIUS_SEARCH_FORMAT,
                { radiusParams, param, paramValue },
            );
        } else if (TERRITORIES_SEARCH_FORMAT.includes(param)) {
            let parsedParamValue = paramValue;
            if (typeof paramValue === 'string') {
                parsedParamValue = paramValue.replace(/[\u201C\u201D]/g, '"');
                parsedParamValue = JSON.parse(parsedParamValue);
            }
            territorySearchFilters = builder(
                TERRITORIES_SEARCH_FORMAT,
                { territoriesParams, param, paramValue: parsedParamValue },
            );
        } else if (SEARCH_NAME_PARAMS.includes(param)) {
            ownerCompanySearch[param] = paramValue;
        } else if (VOLUME_FOLIO_PARAMS.includes(param)) {
            volumeFolioSearch[param] = paramValue;
        } else if (statusType === OTM.TENURE && TENURE_PARAMS.includes(param)) {
            tenureSearch[param] = paramValue;
        } else if (IS_FOR_SALE.includes(param) && paramValue === false) {
            filters.push(builder(
                param,
                { param, paramValue },
            ));
        } else if (IS_ACTIVE_PARAM.includes(param)) {
            if ((typeof paramValue === 'string') && paramValue.includes('false')) {
                filters.push(builder(
                    param,
                    { param, paramValue },
                ));
            }
        }
        else if (paramValue) {
            filters.push(builder(
                param,
                { param, paramValue },
            ));
        }
    });

    if (JSON.stringify(ownerCompanySearch) !== '{}') {
        const paramValue = ownerCompanySearch[LISTING_DATA_PARAM]
            ? ownerCompanySearch[LISTING_DATA_PARAM]
            : ownerCompanySearch;
        filters.push(builder(
            'listingData',
            { paramValue },
        ));
    }

    if (JSON.stringify(volumeFolioSearch) !== '{}') {
        const paramValue = volumeFolioSearch[VOLUME_FOLIO_DATA_PARAM]
            ? volumeFolioSearch[VOLUME_FOLIO_DATA_PARAM]
            : volumeFolioSearch;
        filters.push(builder(
            VOLUME_FOLIO_DATA_PARAM,
            { paramValue },
        ));
    }

    if (JSON.stringify(tenureSearch) !== '{}') {
        const paramValue = tenureSearch[TENURE_DATA_PARAM]
            ? tenureSearch[TENURE_DATA_PARAM]
            : tenureSearch;
        filters.push(builder(
            TENURE_DATA_PARAM,
            { paramValue },
        ));
    }

    if (statusType === OTM.WITHDRAWN) {
        filters.push(SearchBuilder.buildWithdrawnCompare());
    }

    if ('radius' in params) {
        filters.push(radiusSearchFilters);
    }
    if ('geoPolygon' in params) {
        filters.push(territorySearchFilters);
    }
    if (propertyIds?.length) {
        buildBatchSearchParamsUsingIds(propertyIds)?.requests[0]?.filters
            ?.forEach(_filter => filters.push(_filter));
    }

    outerFilters.push({
        operation: AND_OPERATOR,
        filters: flatten(filters, ownerCompanySearch, volumeFolioSearch),
    });

    return {
        resultsFormat,
        filters: outerFilters,
    };
};

const buildBatchSearchParams = searchFilters => ({
    requests: [buildRequest(searchFilters)],
});

const buildBatchSearchParamsUsingIds = idList => ({
    requests: [
        {
            resultsFormat: {
                offset: 0,
                limit: 20,
            },
            filters: [
                {
                    operation: 'or',
                    filters: idList.map(id => ({
                        field: 'id',
                        operation: 'equals',
                        value: id,
                    })),
                },
                {
                    ...SearchBuilder.isActiveFilterBuilder({ paramValue: 'true,false' }),
                },
            ],
        },
    ],
});

const buildBatchSearchMultiPageParams = ({ startPage, endPage, filters, propertyIds }) => {
    const requests = [];
    const _params = new URLSearchParams(filters);
    const params = {};
    _params.forEach((value, key) => {
        params[key] = value.trim();
    });

    for (let offset = startPage; offset <= endPage; offset += 1) {
        requests.push(buildRequest({
            ...params,
            propertyIds,
            offset,
        }));
    }

    return {
        requests,
    };
};

const addParamFromPath = (p, name, value) => value && p.addParam(name, value);

const getRedirectUrl = (listingParams, redirectPage) => {
    let listingData;
    let uri;
    const {
        isCurrentOwner, state, addressSuburb, ownerName,
        volumeNumber, folioNumber, dispatch, pathName, searchParams,
        holdPeriodHouse, holdPeriodUnit, type, exactSearch,
    } = listingParams;
    const { firstName = '', lastName = '' } = ownerName || {};
    const companyName = firstName && Commons.capitalizeFirstLetter(firstName);
    const isNameSearch = lastName && lastName !== '';
    if (isNameSearch) {
        listingData = {
            isCurrentOwner,
            exactSearch,
            ownerFirstName: firstName && Commons.capitalizeFirstLetter(firstName),
            ownerLastName: lastName && Commons.capitalizeFirstLetter(lastName),
            companyName: '',
            addressState: isNZ ? '' : state && state.toUpperCase(),
            addressSuburb,
        };
    } else {
        listingData = {
            exactSearch,
            isCurrentOwner,
            ownerFirstName: '',
            ownerLastName: '',
            companyName,
            addressState: isNZ ? '' : state && state.toUpperCase(),
            addressSuburb,
        };
    }

    let volumeFolioData;
    const isVolumeFolioSearch = volumeNumber && volumeNumber !== '';
    if (isVolumeFolioSearch) {
        volumeFolioData = {
            volumeNumber,
            folioNumber,
            addressState: isNZ ? '' : state && state.toUpperCase(),
            addressSuburb,
        };
    }

    let tenureData;
    const isTenureSearch = (holdPeriodHouse || holdPeriodUnit)
        && (holdPeriodHouse !== '' || holdPeriodUnit !== '');
    if (isTenureSearch) {
        tenureData = {
            holdPeriodHouse,
            holdPeriodUnit,
            type,
            addressState: isNZ ? '' : state && state.toUpperCase(),
            addressSuburb,
        };
    }

    // eslint-disable-next-line max-len
    ClapiApi.getBatchSearch(volumeFolioData ? { volumeFolioData }
        : listingData ? { listingData } : { tenureData }).then((result) => {
        const totalElements = Commons.get(result, 'metadata.totalElements');
        const [firstData] = Commons.get(result, 'data');
        if (totalElements === 1) {
            uri = routeCodes.PROPERTY.path(firstData.addressComplete, firstData.id);
        } else {
            uri = `${pathName}?${searchParams}`;
        }
        redirectPage(uri);
    }).catch(() => {
        dispatch(cruxAppError(ErrorMsg.UNAVAILABLE, ErrorType.PAGE_ERROR));
    }).finally(() => {
        dispatch(cruxUnloader());
    });
};

export default {
    buildRapidSearchQueryParams,
    buildSuggestionRapidSearchFilter,
    buildBatchSearchParams,
    buildBatchSearchMultiPageParams,
    addParamFromPath,
    getRedirectUrl,
    buildBatchSearchParamsUsingIds,
    buildMultiLocalityFilter,
};
