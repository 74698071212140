import API from './index';
import { UriBuilder } from '../helpers/UriBuilder';
import methods from '../constants/methods';
import Commons from '../helpers/Commons';
import { BSG_CONTEXT, RETURN_SUGGESTION } from '../helpers/Localization';
import ApiHelper from '../helpers/ApiHelper';
import SuggestionTypes from '../constants/suggestionTypes';
import cruxProxy from './cruxProxy';
import { joiningUserInstance } from '../helpers/JoiningUser';

const {
    STATE, POSTCODE,
    LOCALITY, ADDRESS, COUNCIL_AREA,
    STREET, LEGAL_DESC, TITLE_REF,
} = SuggestionTypes;

const longApiTimeout = 30000;

const getSelectedClAppAccountUserGuid = (clAppAcctUsrGuidParam) => {
    if (clAppAcctUsrGuidParam) {
        return clAppAcctUsrGuidParam;
    }

    if (joiningUserInstance.clAppAcctUsrGuid) {
        return joiningUserInstance.clAppAcctUsrGuid;
    }

    let clAppAcctUsrGuid = '';
    const persistCrux = window.localStorage.getItem('persist:crux');
    try {
        const cruxStore = JSON.parse(persistCrux);
        if (cruxStore && cruxStore.linkedAccounts) {
            const linkedAccounts = JSON.parse(cruxStore.linkedAccounts);
            if (linkedAccounts) {
                clAppAcctUsrGuid = linkedAccounts.selectedClAppAccountUserGuid;
            }
        }
    } catch (error) {
        console.error(`Unable to parse persist:crux = ${persistCrux} from localStorage due to: ${error}`);
    }

    if (!clAppAcctUsrGuid) {
        console.warn(`Unable to find clAppAcctUsrGuid in persist:crux = ${persistCrux} from localStorage.`);
    }

    return clAppAcctUsrGuid;
};

const getSuggestions = (
    keyword,
    suggestionTypes,
    includeHistoric = false,
    limit = 10,
) => {
    const defaultTypes = [ADDRESS, STREET, COUNCIL_AREA, LOCALITY, POSTCODE];
    const _suggestionTypes = suggestionTypes?.length ? suggestionTypes : defaultTypes.concat(STATE);
    const searchParams = {
        limit,
        q: keyword,
        suggestionTypes: _suggestionTypes.toString(),
        returnSuggestion: RETURN_SUGGESTION,
        includeHistoric,
    };
    return fetchSuggestion(searchParams, null);
};

const getSuggestionsWithDetails = (
    clusrId,
    keyword,
    suggestionTypes,
    limit = 10,
) => {
    const defaultTypes = [ADDRESS, STREET, COUNCIL_AREA, LOCALITY, POSTCODE];
    const _suggestionTypes = suggestionTypes.length ? suggestionTypes : defaultTypes.concat(STATE);
    const searchParams = {
        limit,
        q: keyword,
        suggestionTypes: _suggestionTypes.toString(),
    };
    return fetchSuggestion(searchParams, clusrId);
};

const fetchSuggestion = (params, clUserId) => {
    const uriBuilder = new UriBuilder('/api/clapi/suggestions');

    Object.keys(params).forEach((key) => {
        if (params[key]) {
            uriBuilder.addParam(key, params[key]);
        }
    });

    const requestOptions = {
        path: uriBuilder.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};


const getParcelSuggestions = ({
    clapi, config,
}, keyword) => {
    const uriBuilder = new UriBuilder('/api/clapi/parcel/suggestions')
        .addParam('q', keyword)
        .addParam('clAppAccountUserGuid', getSelectedClAppAccountUserGuid());

    const requestOptions = {
        path: uriBuilder.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getParcelSuggestionByClUserId = (clUserId, keyword) => getParcelSuggestions({ clUserId }, keyword);

const getLegalSuggestions = ({
    clapi, config,
}, keyword, suggestionType) => {
    const uriBuilder = new UriBuilder('/api/clapi/titles/suggestions')
        .addParam('q', keyword)
        .addParam('suggestionTypes', suggestionType)
        .addParam('clAppAccountUserGuid', getSelectedClAppAccountUserGuid());

    const requestOptions = {
        path: uriBuilder.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getLegalSuggestionByClUserId = (clUserId, keyword) => getLegalSuggestions({ clUserId }, keyword, LEGAL_DESC);

const getTitleRefSuggestionByClUserId = (clUserId, keyword) => getLegalSuggestions({ clUserId }, keyword, TITLE_REF);

const getOnePageReport = ({ claud }, payload) => {
    const path = `/api/clapi/property/reports/summary/${payload.propertyId}/pdf?isOwnerNamesAllowed=${payload.isOwnerNameAllowed}`;
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getRentalAvm = (propertyId) => {
    const endpoint = new UriBuilder(`/api/properties/${BSG_CONTEXT}/v1/property/${propertyId}/rentalAvm.json`)
        .build();

    const requestOptions = {
        path: endpoint,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getIntellivalValuationAvm = (propertyId) => {
    const endpoint = `/api/clapi/property/${propertyId}/avm/consumer/static?clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}`;

    const requestOptions = {
        path: endpoint,
        isProxy: true,
    };

    const headerOptions = {
        'Content-Type': 'application/json',
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getPropertyDetailsByIds = (propertyIds, returnFields = []) => {
    const endpoint = new UriBuilder('/api/properties');

    if (returnFields.length > 0) {
        endpoint.addParam('returnFields', returnFields.toString());
    }

    const requestOptions = {
        path: endpoint.build(),
        isProxy: true,
        timeout: 120000, // to cater for 4 possible simultaneous getPropertyDetailsByIds call
    };

    const headerOptions = {
        method: methods.POST.method,
        body: propertyIds,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getLatestAdvertisementDesc = ({ clapi, config, claud }, propertyId) => {
    const params = `/api/properties/${BSG_CONTEXT}/v1/property/{propertyId}/{advertisementMethod}?includeAdvertisementDescription=true`;
    const endpoint = new UriBuilder(params.replace('{propertyId}', propertyId).replace('{advertisementMethod}', 'advertisements.json'))
        .build();

    const requestOptions = {
        path: endpoint,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
}

const getAdvertisementSaleDesc = ({ clapi, config, claud }, propertyId) => {
    const params = `/api/properties/${BSG_CONTEXT}/v1/property/{propertyId}/{advertisementMethod}?includeAdvertisementDescription=true`;
    const endpoint = new UriBuilder(params.replace('{propertyId}', propertyId).replace('{advertisementMethod}', 'for-sale-advertisements.json'))
        .build();

    const requestOptions = {
        path: endpoint,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getAdvertisementRentDesc = ({ clapi, config, claud }, propertyId) => {
    const params = `/api/properties/${BSG_CONTEXT}/v1/property/{propertyId}/{advertisementMethod}?includeAdvertisementDescription=true`;
    const endpoint = new UriBuilder(params.replace('{propertyId}', propertyId).replace('{advertisementMethod}', 'for-rent-advertisements.json'))
        .build();

    const requestOptions = {
        path: endpoint,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getNearbySchools = ({ lat, lon }) => {
    const path = '/api/clapi/nearbySchools';
    const params = {
        lat,
        lon,
        size: 20,
    };
    const endpoint = new UriBuilder(path);
    Object.keys(params).forEach(key => endpoint.addParam(key, params[key]));
    const requestOptions = {
        path: endpoint.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions)
        .then(response => response.nearbySchools);
};

// http://confluence.rpdata.local/display/BA/Property+Profiler+Service
const getPropertyProfile = ({ config, clapi }, reportId, propertyId, customParams) => {
    const endpoint = new UriBuilder(`/api/properties/${BSG_CONTEXT}/v1/profile/property.json`).build();

    const requestOptions = {
        path: endpoint,
        isProxy: true,
        timeout: longApiTimeout,
    };

    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: {
            reportId,
            propertyId,
            ...customParams,
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getPropertyProfileReport = (auth, clusrId, reportId, propertyId, customParams) => {
    let path = `/api/property/${propertyId}/ppr/${reportId}`;
    const selectedClAppAccountUserGuid = getSelectedClAppAccountUserGuid();
    if (selectedClAppAccountUserGuid) {
        path = `${path}?clAppAccountUserGuid=${selectedClAppAccountUserGuid}`;
    }
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };

    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };


    if (customParams) {
        headerOptions.body = Commons.removeEmptyValuesInObj({
            customerName: Commons.get(customParams, 'customerInput.customerFirstName'),
            reportRequester: {
                name: Commons.get(customParams, 'requesterInput.requesterFirstName'),
                email: Commons.get(customParams, 'requesterInput.requesterEmail'),
                phone: Commons.get(customParams, 'requesterInput.requesterPhone'),
            },
            franchiseId: customParams.franchiseId,
            reportType: customParams.reportType,
        });
    }

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

// http://confluence.rpdata.local/display/BA/Suburb+Profile+Service
const getSuburbProfile = (franchiseId = null, locationId, customParams) => {
    let endpoint = '/api/reports/spr';
    const selectedClAppAccountUserGuid = getSelectedClAppAccountUserGuid();
    if (selectedClAppAccountUserGuid) {
        endpoint = `${endpoint}?clAppAccountUserGuid=${selectedClAppAccountUserGuid}`;
    }

    const requestOptions = {
        path: endpoint,
        isProxy: true,
        timeout: longApiTimeout,
    };

    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: Commons.removeEmptyValuesInObj({
            franchiseId,
            locationId,
            ...customParams,
        }),
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getLegalContent = (category, key) => {
    const path = `${category}/${key}`;
    const requestOptions = {
        path: `/api/clapi/legal/${path}`,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getPropertyDetailsLocation = ({ claud }, propertyId) => {
    const path = `/api/clapi/property-details/location/${propertyId}`;
    const requestOptions = {
        path,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getPropertyDetailsLocationByClUsrId = (clusrId, propertyId) => getPropertyDetailsLocation({}, propertyId);

const getPropertyByPoint = (coordinate) => {
    const path = `/api/clapi/property/${coordinate.lat}/${coordinate.lng}`;
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getIntellivalAvmReport = ({ claud }, propertyId) => {
    const path = `/api/property/${propertyId}/avm/consumer/report?clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getOTMCampaign = (propertyId, clUsrId, otmType) => {
    const path = `/api/properties/${propertyId}/otm/campaign/${otmType}`;
    const requestOptions = {
        path,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

// For Sale or For Rent
const getAdvertisementList = (propertyId, clUserId, advertisementType, {
    fromDate = null, toDate = null, agencyName = null, agencyPhoneNumber = null, includeAdvertisementDescription = null,
}) => {
    const path = new UriBuilder(`/api/properties/${propertyId}/otm/${advertisementType}`);
    fromDate && path.addParam('fromDate', fromDate);
    toDate && path.addParam('toDate', toDate);
    agencyName && path.addParam('agencyName', agencyName);
    agencyPhoneNumber && path.addParam('agencyPhoneNumber', agencyPhoneNumber);
    includeAdvertisementDescription && path.addParam('includeAdvertisementDescription', includeAdvertisementDescription);
    const requestOptions = {
        path: path.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getDevelopmentApplicationDetails = (propertyId) => {
    const path = `/api/properties/${propertyId}/developmentApplications`;
    const requestOptions = {
        path,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getForSaleAdvertisement = (propertyId, clUserId, advertisementId) => {
    const path = new UriBuilder(`/api/properties/${propertyId}/otm/for-sale-advertisements/${advertisementId}`);
    const requestOptions = {
        path: path.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getForRentAdvertisement = (propertyId, clUserId, advertisementId) => {
    const path = new UriBuilder(`/api/properties/${propertyId}/otm/for-rent-advertisements/${advertisementId}`);
    const requestOptions = {
        path: path.build(),
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const updatePropertyData = (payload) => {
    const requestOptions = {
        path: '/api/property',
        isProxy: true,
        timeout: longApiTimeout,
    };

    const headerOption = {
        method: methods.PUT.method,
        body: payload,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOption);
};

// microservice
/**
 * @param propertyId
 * @param returnField
 * @param reqParams - key value pairs { includeCommons: true }
 * @returns {Promise<*>}
 */
const getPropertyDetail = (propertyId, returnField, reqParams) => {
    const endpoint = new UriBuilder(`/api/properties/${propertyId}/${returnField}`);
    if (reqParams) {
        Object.keys(reqParams).forEach(key => endpoint.addParam(key, reqParams[key]));
    }
    const requestOptions = {
        path: endpoint.build(),
        isProxy: true,
        timeout: longApiTimeout,
    };

    const headerOption = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOption);
};

const getSaleHistory = (propertyId) => {
    const path = `/api/properties/${propertyId}/sales`;
    const requestOptions = {
        path,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getPhotos = (propertyId) => {
    const path = `/api/properties/${propertyId}/photos`;
    const requestOptions = {
        path,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions).catch(() => null);
};

const getLiveIntellivalAvm = (propertyId, requestBody, slug) => {
    const path = `/api/clapi/property/${propertyId}/avm/consumer/${slug}`;
    const requestOptions = {
        path,
        isProxy: true,
        ...slug.includes('report') ? { timeout: longApiTimeout } : {},
    };

    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: requestBody,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getLiveIntellivalAvmEstimate = (propertyId, requestBody) => getLiveIntellivalAvm(propertyId, requestBody, 'live');

const getLiveIntellivalAvmReport = (propertyId, requestBody) => getLiveIntellivalAvm(propertyId, requestBody, 'live/report');

const getTitleInformation = (propertyId) => {
    const path = `/api/properties/${propertyId}/title/information`;
    const requestOptions = {
        path,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getLinkedAccounts = () => {
    const path = API.LINKED_ACCOUNTS_PATH;
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };

    const headerOptions = {
        method: methods.GET.method,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getBatchSearch = (searchFilters) => {
    let path = `/api/batch/search?clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };
    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: {
            ...ApiHelper.buildBatchSearchParams(searchFilters),
        },
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions)
        .then((response) => {
            const { metadata } = response;
            if (metadata && metadata.length === 1) {
                return {
                    ...response,
                    metadata: metadata[0],
                };
            }

            return response;
        });
};

const getBatchSearchUsingIds = (idList) => {
    const requestOptions = {
        path: `/api/batch/search?clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}`,
        isProxy: true,
        timeout: longApiTimeout,
    };
    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: {
            ...ApiHelper.buildBatchSearchParamsUsingIds(idList),
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions)
        .then((response) => {
            const { metadata } = response;
            if (metadata && metadata.length === 1) {
                return {
                    ...response,
                    metadata: metadata[0],
                };
            }

            return response;
        });
};

const getBatchSearchSavedSearchV2 = (searchFilters) => {
    let path = `/api/batch/search?clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}`;
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };
    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: searchFilters,
    };

    return API.initiateSessionApiCall(requestOptions, headerOptions)
        .then((response) => {
            const { metadata } = response;
            if (metadata && metadata.length === 1) {
                return {
                    ...response,
                    metadata: metadata[0],
                };
            }

            return response;
        });
};

const getBatchSearchMultiPage = (searchFilters, includeGooglePhotos = false) => {
    const requestOptions = {
        path: `/api/batch/search?clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}&includeGooglePhotos=${includeGooglePhotos}`,
        isProxy: true,
        timeout: longApiTimeout,
    };
    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: {
            ...ApiHelper.buildBatchSearchMultiPageParams(searchFilters),
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getTitleDetails = (propertyId) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}`;
    const path = `/api/property/${propertyId}/titles?${appAccountUserGuid}`;
    const requestOptions = {
        path,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getOrchestratedAdvertisements = (propertyId) => {
    const path = `/api/properties/${propertyId}/advertisement`;
    const requestOptions = {
        path,
        isProxy: true,
    };

    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getStatisticsCensusSummary = (locationId) => {
    const path = new UriBuilder(`/api/statistics/census/summary/${locationId}`);
    const requestOptions = {
        path: path.build(),
        isProxy: true,
        timeout: API.getTimeouts().large,
    };
    const headerOptions = {
        method: methods.GET.method,
        headers: {
            'Content-Type': 'application/json',
        },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getStatistics = (body, type) => {
    const path = new UriBuilder('/api/statistics');
    if (type) {
        path.addSlug(type);
    }
    const requestOptions = {
        path: path.build(),
        isProxy: true,
        timeout: API.getTimeouts().large,
    };
    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getStatisticsCharts = (body, type) => {
    const path = new UriBuilder(`/api/ssr/charts`);

    if (type) {
        path.addSlug(type);
    }

    const requestOptions = {
        path: path.build(),
        isProxy: true,
        timeout: API.getTimeouts().large,
    };
    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
}

const getMarketingContactService = ({ propertyIdList = [], ...rest }) => {
    const appAccountUserGuid = `clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}`;
    const path = `/api/property/market/contacts?${appAccountUserGuid}`;
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };
    const headerOptions = {
        method: methods.POST.method,
        headers: {
            'Content-Type': 'application/json',
            ...cruxProxy.getXsrfHeader(),
        },
        body: { propertyIdList, ...rest },
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

const getTitleNZTokens = () => {
    const appAccountUserGuid = `clAppAccountUserGuid=${getSelectedClAppAccountUserGuid()}`;
    const path = `/api/title-nz/tokens?${appAccountUserGuid}`;
    const requestOptions = {
        path,
        isProxy: true,
        timeout: longApiTimeout,
    };
    const headerOptions = {
        method: methods.GET.method,
    };
    return API.initiateSessionApiCall(requestOptions, headerOptions);
};

export default {
    getTitleNZTokens,
    getSelectedClAppAccountUserGuid,
    getSuggestions,
    getParcelSuggestions,
    getLegalSuggestions,
    getOnePageReport,
    getRentalAvm,
    getIntellivalValuationAvm,
    getAdvertisementSaleDesc,
    getAdvertisementRentDesc,
    getNearbySchools,
    getPropertyProfile,
    getLegalContent,
    getSuburbProfile,
    getPropertyDetailsLocation,
    getPropertyByPoint,
    getPropertyDetailsByIds,
    getIntellivalAvmReport,
    getPropertyProfileReport,
    getOTMCampaign,
    getAdvertisementList,
    getDevelopmentApplicationDetails,
    getForSaleAdvertisement,
    getForRentAdvertisement,
    updatePropertyData,
    getPropertyDetail,
    getSaleHistory,
    getLiveIntellivalAvmEstimate,
    getLiveIntellivalAvmReport,
    getBatchSearch,
    getBatchSearchMultiPage,
    getTitleInformation,
    getPhotos,
    getLinkedAccounts,
    getPropertyDetailsLocationByClUsrId,
    getParcelSuggestionByClUserId,
    getLegalSuggestionByClUserId,
    getTitleRefSuggestionByClUserId,
    getTitleDetails,
    getOrchestratedAdvertisements,
    getBatchSearchUsingIds,
    getStatistics,
    getStatisticsCharts,
    getStatisticsCensusSummary,
    getLatestAdvertisementDesc,
    getMarketingContactService,
    getSuggestionsWithDetails,
    getBatchSearchSavedSearchV2,
};
