class FeatureFlag {
    #featureFlagVal;

    constructor(featureFlagVal) {
        this.#featureFlagVal = featureFlagVal;
    }

    isTrue() {
        return this.#featureFlagVal === true || 'true' === this.#featureFlagVal?.toString()?.toLowerCase();
    }

    isEntitlements() {
        return 'entitlements' === this.#featureFlagVal?.toString()?.toLowerCase();
    }

    hasValue() {
        return this.#featureFlagVal !== undefined && this.#featureFlagVal !== null;
    }

    getFeatureFlagVal() {
        return this.#featureFlagVal;
    }
}

export default FeatureFlag;
