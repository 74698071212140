const actionPrefix = '@@clapi/';

export const CLAPI_GET_AUTH = 'CLAPI_GET_AUTH';
export const CLAPI_GET_AUTH_FAIL = 'CLAPI_GET_AUTH_FAIL';
export const CLAPI_GET_AUTH_SUCCESS = 'CLAPI_GET_AUTH_SUCCESS';
export const CLAPI_CLEAR_PROPERTY = 'CLAPI_CLEAR_PROPERTY';
export const CLAPI_GET_RENTAL_AVM = 'CLAPI_GET_RENTAL_AVM';
export const CLAPI_GET_RENTAL_AVM_FAIL = 'CLAPI_GET_RENTAL_AVM_FAIL';
export const CLAPI_GET_RENTAL_AVM_SUCCESS = 'CLAPI_GET_RENTAL_AVM_SUCCESS';
export const CLAPI_GET_INTELLIVAL_VALUATION_AVM = 'CLAPI_GET_INTELLIVAL_VALUATION_AVM';
export const CLAPI_GET_INTELLIVAL_VALUATION_AVM_SUCCESS = 'CLAPI_GET_INTELLIVAL_VALUATION_AVM_SUCCESS';
export const CLAPI_GET_INTELLIVAL_VALUATION_AVM_FAIL = 'CLAPI_GET_INTELLIVAL_VALUATION_AVM_FAIL';
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS';
export const GET_SUGGESTIONS_WITH_DETAILS = 'GET_SUGGESTIONS_WITH_DETAILS';
export const GET_PARCEL_SUGGESTIONS = 'GET_PARCEL_SUGGESTIONS';
export const GET_LEGAL_SUGGESTIONS = 'GET_LEGAL_SUGGESTIONS';
export const GET_TITLE_REF_SUGGESTIONS = 'GET_TITLE_REF_SUGGESTIONS';
export const GET_SUGGESTIONS_FAIL = 'GET_SUGGESTIONS_FAIL';
export const GET_PARCEL_SUGGESTIONS_FAIL = 'GET_PARCEL_SUGGESTIONS_FAIL';
export const GET_LEGAL_SUGGESTIONS_FAIL = 'GET_LEGAL_SUGGESTIONS_FAIL';
export const GET_TITLE_REF_SUGGESTIONS_FAIL = 'GET_TITLE_REF_SUGGESTIONS_FAIL';
export const GET_SUGGESTIONS_SUCCESS = 'GET_SUGGESTIONS_SUCCESS';
export const GET_PARCEL_SUGGESTIONS_SUCCESS = 'GET_PARCEL_SUGGESTIONS_SUCCESS';
export const GET_LEGAL_SUGGESTIONS_SUCCESS = 'GET_LEGAL_SUGGESTIONS_SUCCESS';
export const GET_TITLE_REF_SUGGESTIONS_SUCCESS = 'GET_TITLE_REF_SUGGESTIONS_SUCCESS';
export const CLEAR_SUGGESTION = 'CLEAR_SUGGESTION';
export const CLEAR_PARCEL_SUGGESTION = 'CLEAR_PARCEL_SUGGESTION';
export const CLEAR_LEGAL_SUGGESTION = 'CLEAR_LEGAL_SUGGESTION';
export const CLEAR_TITLE_REF_SUGGESTION = 'CLEAR_TITLE_REF_SUGGESTION';
export const GET_HOUSEHOLD_CONTACTS = 'GET_HOUSEHOLD_CONTACTS';
export const GET_HOUSEHOLD_CONTACTS_FAIL = 'GET_HOUSEHOLD_CONTACTS_FAIL';
export const GET_HOUSEHOLD_CONTACTS_SUCCESS = 'GET_HOUSEHOLD_CONTACTS_SUCCESS';
export const GET_PROPERTY_FOR_SALE_ADVERTISEMENT = 'GET_PROPERTY_FOR_SALE_ADVERTISEMENT';
export const GET_PROPERTY_FOR_SALE_ADVERTISEMENT_SUCCESS = 'GET_PROPERTY_FOR_SALE_ADVERTISEMENT_SUCCESS';
export const GET_PROPERTY_FOR_SALE_ADVERTISEMENT_FAIL = 'GET_PROPERTY_FOR_SALE_ADVERTISEMENT_FAIL';
export const GET_PROPERTY_FOR_RENT_ADVERTISEMENT = 'GET_PROPERTY_FOR_RENT_ADVERTISEMENT';
export const GET_PROPERTY_FOR_RENT_ADVERTISEMENT_SUCCESS = 'GET_PROPERTY_FOR_RENT_ADVERTISEMENT_SUCCESS';
export const GET_PROPERTY_FOR_RENT_ADVERTISEMENT_FAIL = 'GET_PROPERTY_FOR_RENT_ADVERTISEMENT_FAIL';
export const SET_COMPARABLE_RULE = 'SET_COMPARABLE_RULE';
export const HANDLE_RECENT_SEARCH = 'HANDLE_RECENT_SEARCH';
export const HANDLE_RECENT_SEARCH_FAIL = 'HANDLE_RECENT_SEARCH_FAIL';
export const HANDLE_RECENT_SEARCH_SUCCESS = 'HANDLE_RECENT_SEARCH_SUCCESS';
export const GET_PROPERTY_PROFILE_SUCCESS = 'GET_PROPERTY_PROFILE_SUCCESS';
export const GET_GENERAL_DISCLAIMER = 'GET_GENERAL_DISCLAIMER';
export const GET_GENERAL_DISCLAIMER_SUCCESS = 'GET_GENERAL_DISCLAIMER_SUCCESS';
export const GET_GENERAL_DISCLAIMER_FAIL = 'GET_GENERAL_DISCLAIMER_FAIL';
export const GET_CONTENT_DISCLAIMER = 'GET_CONTENT_DISCLAIMER';
export const GET_CONTENT_DISCLAIMER_SUCCESS = 'GET_CONTENT_DISCLAIMER_SUCCESS';
export const GET_CONTENT_DISCLAIMER_FAIL = 'GET_CONTENT_DISCLAIMER_FAIL';
export const GET_VALUATION_DISCLAIMER = 'GET_VALUATION_DISCLAIMER';
export const GET_VALUATION_DISCLAIMER_SUCCESS = 'GET_VALUATION_DISCLAIMER_SUCCESS';
export const GET_VALUATION_DISCLAIMER_FAIL = 'GET_VALUATION_DISCLAIMER_FAIL';
export const GET_STATE_DISCLAIMER = 'GET_STATE_DISCLAIMER';
export const GET_STATE_DISCLAIMER_SUCCESS = 'GET_STATE_DISCLAIMER_SUCCESS';
export const GET_STATE_DISCLAIMER_FAIL = 'GET_STATE_DISCLAIMER_FAIL';
export const GET_CENSUS_DISCLAIMER = 'GET_CENSUS_DISCLAIMER';
export const GET_CENSUS_DISCLAIMER_SUCCESS = 'GET_CENSUS_DISCLAIMER_SUCCESS';
export const GET_CENSUS_DISCLAIMER_FAIL = 'GET_CENSUS_DISCLAIMER_FAIL';
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const GET_SEARCH_RESULTS_FAIL = 'GET_SEARCH_RESULTS_FAIL';
export const GET_SEARCH_RESULTS_SUCCESS = 'GET_SEARCH_RESULTS_SUCCESS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const SHOW_MORE_SUCCESS = 'SHOW_MORE_SUCCESS';
export const SET_SEARCH_FILTERS_V2 = 'SET_SEARCH_FILTERS_V2';
export const SET_SAVED_LIST_FILTERS = 'SET_SAVED_LIST_FILTERS';
export const UPDATE_SEARCH_FILTERS_OFFSET = 'UPDATE_SEARCH_FILTERS_OFFSET';
export const GET_RAPID_NAME_SEARCH_RESULT = 'GET_RAPID_NAME_SEARCH_RESULT';
export const GET_RAPID_NAME_SEARCH_RESULT_SUCCESS = 'GET_RAPID_NAME_SEARCH_RESULT_SUCCESS';
export const GET_RAPID_NAME_SEARCH_RESULT_FAIL = 'GET_RAPID_NAME_SEARCH_RESULT_FAIL';
export const GET_SAVED_LIST_RESULT = 'GET_SAVED_LIST_RESULT';
export const GET_SAVED_LIST_RESULT_SUCCESS = 'GET_SAVED_LIST_RESULT_SUCCESS';
export const GET_SAVED_LIST_RESULT_FAILED = 'GET_SAVED_LIST_RESULT_FAILED';
export const CLEAR_NAME_SEARCH_RESULTS = 'CLEAR_NAME_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS_PROPERTY_SUMMARIES = 'CLEAR_SEARCH_RESULTS_PROPERTY_SUMMARIES';
export const GET_PROPERTY_DETAILS_BY_IDS = 'GET_PROPERTY_DETAILS_BY_IDS';
export const GET_PROPERTY_DETAILS_BY_IDS_SUCCESS = 'GET_PROPERTY_DETAILS_BY_IDS_SUCCESS';
export const GET_PROPERTY_DETAILS_BY_IDS_FAIL = 'GET_PROPERTY_DETAILS_BY_IDS_FAIL';
export const CLEAR_NAME_SEARCH_IDS = 'CLEAR_NAME_SEARCH_IDS';
export const CLEAR_SEARCH_FILTERS = 'CLEAR_SEARCH_FILTERS';
export const GET_USER_DETAIL_ASYNC = `${actionPrefix}GET_USER_DETAIL_ASYNC`;
export const GET_USER_DETAIL_SUCCESS = `${actionPrefix}GET_USER_DETAIL_SUCCESS`;
export const GET_USER_DETAIL_FAIL = `${actionPrefix}GET_USER_DETAIL_FAIL`;
export const GET_PROPERTY_DETAILS_LOCATION = 'GET_PROPERTY_DETAILS_LOCATION';
export const GET_PROPERTY_DETAILS_LOCATION_FAIL = 'GET_PROPERTY_DETAILS_LOCATION_FAIL';
export const GET_PROPERTY_DETAILS_LOCATION_SUCCESS = 'GET_PROPERTY_DETAILS_LOCATION_SUCCESS';
export const GET_COPYRIGHTS = 'GET_COPYRIGHTS';
export const GET_COPYRIGHTS_SUCCESS = 'GET_COPYRIGHTS_SUCCESS';
export const GET_COPYRIGHTS_FAIL = 'GET_COPYRIGHTS_FAIL';
export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS';
export const GET_PARTIAL_PROPERTY_DETAILS = 'GET_PARTIAL_PROPERTY_DETAILS';
export const GET_PARTIAL_PROPERTY_DETAILS_SUCCESS = 'GET_PARTIAL_PROPERTY_DETAILS_SUCCESS';
export const UPDATE_USER_PHOTO = 'UPDATE_USER_PHOTO';
export const SAVE_PROPERTY_PHOTO = 'SAVE_PROPERTY_PHOTO';
export const CLEAR_ADDRESS_COLLECTIVE = 'CLEAR_ADDRESS_COLLECTIVE';
export const GET_ADDRESS_COLLECTIVE = 'GET_ADDRESS_COLLECTIVE';
export const GET_ADDRESS_COLLECTIVE_SUCCESS = 'GET_ADDRESS_COLLECTIVE_SUCCESS';
export const SET_SEARCH_STATUS_TYPE = 'SET_SEARCH_STATUS_TYPE';
export const GET_TITLE_DESCRIPTION = 'GET_TITLE_DESCRIPTION';
export const GET_TITLE_DESCRIPTION_SUCCESS = 'GET_TITLE_DESCRIPTION_SUCCESS';
export const GET_TITLE_DESCRIPTION_FAIL = 'GET_TITLE_DESCRIPTION_FAIL';
export const GET_LATEST_ADVERTISEMENT_DESC = 'GET_LATEST_ADVERTISEMENT_DESC';
export const GET_LATEST_ADVERTISEMENT_DESC_SUCCESS = 'GET_LATEST_ADVERTISEMENT_DESC_SUCCESS';
export const GET_LATEST_ADVERTISEMENT_DESC_FAIL = 'GET_LATEST_ADVERTISEMENT_DESC_FAIL';
export const UPDATE_SEARCH_FILTERS_LIMIT = 'UPDATE_SEARCH_FILTERS_LIMIT';
export const UPDATE_SAVED_LIST_FILTERS_LIMIT = 'UPDATE_SAVED_LIST_FILTERS_LIMIT';
export const CLEAR_PAGE_LIMIT_OFFSET = 'CLEAR_PAGE_LIMIT_OFFSET';

export function updateUserPhoto(userPhoto) {
    return {
        type: UPDATE_USER_PHOTO,
        payload: userPhoto,
    };
}

export function getAuth() {
    return {
        type: CLAPI_GET_AUTH,
    };
}

export function getPartialPropertyDetails(
    propertyId, entryPoint, suggestionTypeId,
    isActiveProperty, callback,
) {
    return {
        type: GET_PARTIAL_PROPERTY_DETAILS,
        payload: {
            propertyId,
            entryPoint,
            suggestionTypeId,
            callback,
            isActiveProperty,
        },
    };
}

export function getTitleDescription(propertyId) {
    return {
        type: GET_TITLE_DESCRIPTION,
        payload: {
            propertyId,
        },
    };
}

export function getSuggestions({ withDetails, ...rest }) {
    return {
        type: withDetails ? GET_SUGGESTIONS_WITH_DETAILS : GET_SUGGESTIONS,
        payload: rest,
    };
}

export function getParcelSuggestions(keyword) {
    return {
        type: GET_PARCEL_SUGGESTIONS,
        payload: {
            keyword,
        },
    };
}

export function getLegalSuggestions(keyword, suggestionTypes) {
    return {
        type: GET_LEGAL_SUGGESTIONS,
        payload: {
            keyword,
            suggestionTypes,
        },
    };
}

export function getTitleRefSuggestions(keyword, suggestionTypes) {
    return {
        type: GET_TITLE_REF_SUGGESTIONS,
        payload: {
            keyword,
            suggestionTypes,
        },
    };
}

export function getRentalAvm(propertyId) {
    return {
        type: CLAPI_GET_RENTAL_AVM,
        payload: {
            propertyId,
        },
    };
}

export function getIntelliValValuationAvm(propertyId) {
    return {
        type: CLAPI_GET_INTELLIVAL_VALUATION_AVM,
        payload: {
            propertyId,
        },
    };
}

export function clearSuggestion() {
    return {
        type: CLEAR_SUGGESTION,
    };
}

export function clearParcelSuggestion() {
    return {
        type: CLEAR_PARCEL_SUGGESTION,
    };
}

export function clearLegalSuggestion() {
    return {
        type: CLEAR_LEGAL_SUGGESTION,
    };
}

export function clearTitleRefSuggestion() {
    return {
        type: CLEAR_TITLE_REF_SUGGESTION,
    };
}

export function getLatestAdvertisementDesc(propertyId) {
    return {
        type: GET_LATEST_ADVERTISEMENT_DESC,
        payload: { propertyId },
    };
}

export function getAdvertisementSaleDesc(propertyId) {
    return {
        type: GET_PROPERTY_FOR_SALE_ADVERTISEMENT,
        payload: { propertyId },
    };
}

export function getAdvertisementRentalDesc(propertyId) {
    return {
        type: GET_PROPERTY_FOR_RENT_ADVERTISEMENT,
        payload: { propertyId },
    };
}
export function setComparableRule(comparableRule) {
    return {
        type: SET_COMPARABLE_RULE,
        payload: comparableRule,
    };
}
export function handleRecentSearch(keyword) {
    return {
        type: HANDLE_RECENT_SEARCH,
        payload: {
            keyword,
        },
    };
}

export function getAddressCollective(suggestion, historic) {
    return {
        type: GET_ADDRESS_COLLECTIVE,
        payload: {
            suggestion,
            historic
        }
    };
}

export function getAddressCollectiveSuccess(response) {
    return {
        type: GET_ADDRESS_COLLECTIVE_SUCCESS,
        payload: {
            response,
        }
    };
}

export function getGeneralDisclaimer(category, key) {
    return {
        type: GET_GENERAL_DISCLAIMER,
        payload: { category, key },
    };
}

export function getContentDisclaimer(category, key) {
    return {
        type: GET_CONTENT_DISCLAIMER,
        payload: { category, key },
    };
}

export function getValuationDisclaimer(category, key) {
    return {
        type: GET_VALUATION_DISCLAIMER,
        payload: { category, key },
    };
}

export function getStateDisclaimer(category, key) {
    return {
        type: GET_STATE_DISCLAIMER,
        payload: { category, key },
    };
}

export function getCensusDisclaimer(category, key) {
    return {
        type: GET_CENSUS_DISCLAIMER,
        payload: { category, key },
    };
}

export function getSearchResults(payload) {
    return {
        type: GET_SEARCH_RESULTS,
        payload,
    };
}

export function clearSearchResults() {
    return {
        type: CLEAR_SEARCH_RESULTS,
    };
}

export function clearNameSearchResults() {
    return {
        type: CLEAR_NAME_SEARCH_RESULTS,
    };
}
export function clearSearchResultsPropertySummaries() {
    return {
        type: CLEAR_SEARCH_RESULTS_PROPERTY_SUMMARIES,
    };
}

export function getPropertyDetailsByIds(propertyIds, returnFields) {
    return {
        type: GET_PROPERTY_DETAILS_BY_IDS,
        payload: propertyIds,
        returnFields,
    };
}

export function clearNameSearchIds() {
    return {
        type: CLEAR_NAME_SEARCH_IDS,
    };
}

export function setSearchFiltersV2(payload) {
    return {
        type: SET_SEARCH_FILTERS_V2,
        payload,
    };
}

export function updateSearchFiltersOffset({ offset, propertyId }, isNonAddressSearch) {
    return {
        type: UPDATE_SEARCH_FILTERS_OFFSET,
        payload: { offset, propertyId, isNonAddressSearch },
    };
}

export function getRapidSearchResult(payload) {
    return {
        type: GET_RAPID_NAME_SEARCH_RESULT,
        payload,
    };
}

export function clearSearchFilters() {
    return {
        type: CLEAR_SEARCH_FILTERS,
    };
}

export function getUserDetailAsync(callback) {
    return {
        type: GET_USER_DETAIL_ASYNC,
        callback,
    };
}

export function getUserDetailSuccess(usrDetail) {
    return {
        type: GET_USER_DETAIL_SUCCESS,
        payload: {
            usrDetail,
        },
    };
}

export function getPropertyDetailsLocation(propertyId) {
    return {
        type: GET_PROPERTY_DETAILS_LOCATION,
        payload: { propertyId },
    };
}

export function getCopyRights(category, key) {
    return {
        type: GET_COPYRIGHTS,
        payload: { category, key },
    };
}

export function resetSearchResults(payload, callback) {
    return {
        type: RESET_SEARCH_RESULTS,
        payload,
        callback,
    };
}

export function savePropertyPhoto(propertyPhoto = {}) {
    return {
        type: SAVE_PROPERTY_PHOTO,
        payload: propertyPhoto,
    };
}

export function clearClapiPropertyDetails() {
    return {
        type: CLAPI_CLEAR_PROPERTY,
    };
}

export function setSearchStatusType(payload) {
    return {
        type: SET_SEARCH_STATUS_TYPE,
        payload,
    };
}
export function clearPageLimitOffset() {
    return { type: CLEAR_PAGE_LIMIT_OFFSET };
}

export function updateSearchFiltersLimit(payload = '20') {
    return { type: UPDATE_SEARCH_FILTERS_LIMIT, payload };
}

export function updateSavedListFiltersLimit(payload = '20') {
    return { type: UPDATE_SAVED_LIST_FILTERS_LIMIT, payload };
}
