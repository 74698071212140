export const RECENT_SEARCH_START = 'RECENT_SEARCH_START';
export const RECENT_SEARCH_END = 'RECENT_SEARCH_END';

export const startRecentSearchProgress = () => {
    return {
        type: RECENT_SEARCH_START,
    };
}

export const endRecentSearchProgress = () => {
    return {
        type: RECENT_SEARCH_END,
    };
}
