import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip as MUITooltip } from '@mui/material';
import { getAssetDomain } from '../../constants/assets';
import Tooltip from './Tooltip';

const Checkbox = ({
    id = '',
    label = '',
    customClass = '',
    checked,
    onChange,
    tooltip,
    tooltipId,
    isReturningSoon,
    tooltipTitle,
    disabled
}) => {
    const _customClass = customClass ? ` ${customClass}` : '';
    const _customDisabledClass = isReturningSoon || disabled ? ' custom-checkbox-disabled' : '';
    return (
        <span className={`custom-checkbox${_customClass}${_customDisabledClass}`}>
            <label htmlFor={id}>
                <input
                    id={id}
                    data-testid={id}
                    aria-label={label}
                    checked={checked}
                    type="checkbox"
                    onChange={onChange}
                    disabled={!!isReturningSoon || disabled}
                />
                <span className="check-icon">
                    <img src={getAssetDomain('tick-icon.png')} alt="" />
                </span>

                {
                    isReturningSoon ?
                        <MUITooltip
                            title={tooltipTitle}
                            placement="top"
                            arrow
                        >
                            <span className="checkbox-label">
                                <span dangerouslySetInnerHTML={{ __html: label }} />
                            </span>
                        </MUITooltip> :
                        <span className="checkbox-label">
                            <span dangerouslySetInnerHTML={{ __html: label }} />
                        </span>
                }
            </label>
            {
                tooltip &&
                <Tooltip
                    tooltipTxt={tooltip}
                    tooltipId={tooltipId}
                    tooltipClass="white"
                    tooltipPosition="bottom"
                    iconType="infoIcon"
                />
            }
        </span>
    );
};

Checkbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    customClass: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    tooltipId: PropTypes.string,
    isReturningSoon: PropTypes.bool,
    tooltipTitle: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Checkbox;
