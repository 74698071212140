export class UriBuilder {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
        this.slugs = [];
        this.params = [];
    }

    /**
	 * @function addSlug inserts a new string in the slugs array
	 * @param {*} slug url slug e.g. http://localhost/my/url <- url slug is localhost, my, and url
	 * @return Postman
	 */
    addSlug(slug) {
        this.slugs.push(slug);
        return this;
    }

    /**
	 * @function addParam inserts a new JSON object in the params array. JSON object will attributes of key and value e.g. {key:name, value:john}
	 * @param {*} key the parameter name of the search query
	 * @param {*} value the value of the parameter key
	 * @return Postman
	 */
    addParam(key, value) {
        this.params.push({
            key,
            value,
        });
        return this;
    }

    /**
	 * @function stringifySlug construct url
	 * @return additional url slug
	 */
    stringifySlug() {
        // check if slugs are available
        // then construct url
        // else return empty string
        if (this.slugs.length > 0) {
            return `/${this.slugs.join('/')}`;
        }

        return '';
    }

    /**
	 * @function stringifyParam construct query string for url
	 * @return search query string of url e.g. '?id=5&name=john&age=20'
	 */
    stringifyParam() {
        // check if query string is available
        // then construct query string for url
        // else return empty string
        if (this.params.length > 0) {
            let queryString = '';
            for (const param of this.params) {
                if (param.key !== 'geoPolygon') {
                    queryString += `&${param.key}=${encodeURIComponent(param.value)}`;
                }
            }

            // Add geoPolygon if available
            const geoPolygonParam = this.params.find(param => param.key === 'geoPolygon');
            if (geoPolygonParam) {
                const geoPoints = geoPolygonParam.value.map(point => `{"lat":${point.lat}, "lon":${point.lon}}`).join(',');
                queryString += `&geoPolygon=[${geoPoints}]`;
            }

            return `?${queryString.substring(1)}`;
        }

        return '';
    }

    /**
	 * @function build construct url string
	 * @return final url string
	 */
    build() {
        // TODO: build string for the url request using the slugs and params array
        return `${this.baseUrl}${this.stringifySlug()}${this.stringifyParam()}`;
    }
}
