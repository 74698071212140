import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ParcelSearch } from '../parcelSearch/ParcelSearch';

const TitleSearch = withRouter(connect(state => ({
    usrDetail: state.clapi.get('usrDetail'),
    suggestions: state.clapi.get('parcelSuggestions'),
    legalSuggestions: state.clapi.get('legalSuggestions'),
    isRecent: state.clapi.get('isRecent'),
    recentSearches: state.msg.get('recentTitleSearches'),
    clUsrId: state.claud.get('session')?.clusrId,
    handler: state.msg.get('handler'),
}))(ParcelSearch));

export default TitleSearch;
