import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { isAU, isNZ } from '../../../constants/crux';
import { routeCodes } from '../../../constants/routes';
import Option from '../../../constants/options/dropdownOptions';
import RapidNameSearch from './nameSearch/RapidNameSearch';
import SearchBarTabs from './SearchBarTabs';
import SearchBarDropdown from './SearchBarDropdown';
import CompanySearch from './companySearch/CompanySearch';
import ParcelSearch from './parcelSearch/ParcelSearch';
import VolumeFolioSearch from './volumeFolioSearch/VolumeFolioSearch';
import RollValuationSearch from './rollValuationSearch/RollValuationSearch';
import Entitlements from '../../../helpers/Entitlements';
import Commons from '../../../helpers/Commons';
import TitleSearch from './titleSearch/TitleSearch';
import suggestionTypes from '../../../constants/suggestionTypes';
import { saveSearchType } from '../../../actions/componentAccess';
import { LOCALIZED_SEARCH_OPTIONS, PARCEL_SEARCH_PLACEHOLDER } from '../../../helpers/Localization';
import BuildingSearch from './buildingSearch/BuildingSearch';
import MultiLocalitySearch from './multiLocalitySearch/MultiLocalitySearch';
import ReportAddressSearch from './report/ReportAddressSearch';

import '../../../../css/crux/components/global/SearchBar.scss';

const { LEGAL_DESC, PARCEL, TITLE_REF } = suggestionTypes;

export class SearchBarV2Component extends Component {
    static propTypes = {
        styles: PropTypes.object,
        route: PropTypes.object.isRequired,
        entryPoint: PropTypes.string.isRequired,
        dispatch: PropTypes.func,
        setSearchType: PropTypes.func,
        isTab: PropTypes.bool,
        deed7: PropTypes.bool,
        usrDetail: PropTypes.object,
        searchType: PropTypes.string,
        location: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            showSearchModal: false,
        };
    }

    componentDidMount() {
        const searchTypeRouteToOptionMap = {
            [routeCodes.HOME.id]: Option.ADDRESS,
            [routeCodes.RADIUS_SEARCH_RESULT.id]: Option.ADDRESS,
            [routeCodes.NAME_SEARCH_RESULT.id]: Option.NAME,
            [routeCodes.COMPANY_SEARCH_RESULT.id]: Option.COMPANY,
            [routeCodes.BUILDING_SEARCH_RESULT.id]: Option.BUILDING_NAME,
            [routeCodes.PARCEL_SEARCH_RESULT.id]: Option.PARCEL,
            [routeCodes.VOLUME_FOLIO_SEARCH_RESULT.id]: Option.VOLUME_FOLIO,
            [routeCodes.LEGAL_DESCRIPTION_SEARCH_RESULT.id]: Option.PARCEL,
            [routeCodes.TITLE_REFERENCE_SEARCH_RESULT.id]: Option.TITLE_REFERENCE,
            [routeCodes.BUILDING_SEARCH_RESULT.id]: Option.BUILDING_NAME,
            [routeCodes.ROLL_VALUATION_SEARCH_RESULT.id]: Option.ROLL_VALUATION,
            [routeCodes.PROPERTY.id]: Option.ADDRESS,
        };
        // for direct url access (ex: bookmark)
        if (searchTypeRouteToOptionMap[this.props.route.id]) {
            if (this.props.searchType !== searchTypeRouteToOptionMap[this.props.route.id]) {
                this
                    .props
                    .dispatch(saveSearchType(searchTypeRouteToOptionMap[this.props.route.id]));
            }
        }
    }

    componentWillUnmount() {
        sessionStorage.setItem('searchType', this.props.searchType);
    }

    getSearchOptions = (withReports) => {
        const { usrDetail } = this.props;
        const searchOptions = [];
        const entitlementsPerType = {
            [Option.NAME]: !Entitlements.hasSearchNameRole(usrDetail),
            [Option.COMPANY]: !Entitlements.hasSearchNameRole(usrDetail),
            [Option.BUILDING_NAME]: !Entitlements.hasSearchBuildingRole(usrDetail),
            [Option.PARCEL]: !Entitlements.hasParcelSearchRole(usrDetail),
            [Option.VOLUME_FOLIO]: !Entitlements.hasParcelSearchRole(usrDetail),
            [Option.TITLE_REFERENCE]: !Entitlements.hasParcelSearchRole(usrDetail),
            [Option.ROLL_VALUATION]: !Entitlements.hasParcelSearchRole(usrDetail),
            [Option.BUILDING_NAME]: !Entitlements.hasSearchBuildingRole(usrDetail),
        };

        Object.keys(LOCALIZED_SEARCH_OPTIONS).forEach((type) => {
            if (type !== 'Reports' || withReports) {
                searchOptions.push({
                    type,
                    disabled: entitlementsPerType[type],
                });
            }
        });

        return searchOptions;
    }

    hasParcelSearchRole = () => {
        const { usrDetail } = this.props;
        return Entitlements.hasParcelSearchRole(usrDetail);
    };

    handleTabChange = (event, newValue) => {
        // disable moving of tab for no entitlements
        if (event.currentTarget.classList.contains('disabled-tab')) {
            event.preventDefault();
            return;
        }
        this.props.dispatch(saveSearchType(newValue));
    };

    handleSearchTypeOnChange = (option) => {
        const { setSearchType } = this.props;
        this.props.dispatch(saveSearchType(option));
        setSearchType(option);
        sessionStorage.setItem('searchType', option);
    };

    render() {
        const { showSearchModal } = this.state;
        const {
            styles, route, entryPoint,
            dispatch, isTab, location,
            deed7, usrDetail, searchType,
        } = this.props;

        const {
            accountDetail: {
                customerException: {
                    allowVictorianSearch = false,
                } = {}
            } = {}
        } = usrDetail;
        const parcelSearchSuggestionType = isAU ? PARCEL : LEGAL_DESC;
        const parcelSearchRoutePathname = isAU
            ? routeCodes.PARCEL_SEARCH_RESULT.path
            : routeCodes.LEGAL_DESCRIPTION_SEARCH_RESULT.path;
        const parcelSearchRouteSearch = encodedSuggestion => (isAU
            ? `addressParcelSuburbState=${encodedSuggestion}`
            : `legalDescription=${encodedSuggestion}`);

        const extendedSearchBar = ['Address'];
        const searchBarContainerClassNames = `search-bar-container${
            extendedSearchBar.includes(searchType) ? ' extended' : ''
        }`;

        return (
            <div
                key={`search-bar-${location?.key}`} id="crux-search-bar"
                className="crux-search-bar crux-search-v2"
                style={styles}
            >
                <div
                    className={route.pageContext !== routeCodes.HOME.pageContext
                        ? 'search-bar-nav'
                        : searchBarContainerClassNames
                    }
                >
                    <div id="crux-advance-search" className={`crux-search${isNZ ? ' nz' : ''}`}>
                        <div className="address-name-search-panel" autoComplete="off">
                            { isTab ?
                                <SearchBarTabs
                                    selectedOption={searchType}
                                    onTabChange={this.handleTabChange}
                                    searchOptions={this.getSearchOptions(true)}
                                    userDetail={usrDetail}
                                >
                                    {
                                        searchType === Option.ADDRESS &&
                                        <MultiLocalitySearch
                                            route={route}
                                            entryPoint={entryPoint}
                                            isHistoricVisible
                                        />
                                    }
                                    {
                                        searchType === Option.REPORTS &&
                                        <ReportAddressSearch
                                            route={route}
                                            entryPoint={entryPoint}
                                            isHistoricVisible
                                        />
                                    }
                                    {
                                        searchType === Option.NAME &&
                                        <RapidNameSearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                            isCheckboxVisible
                                            enableVIC={allowVictorianSearch}
                                        />
                                    }
                                    {
                                        searchType === Option.COMPANY &&
                                        <CompanySearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                            isCheckboxVisible
                                            deed7={deed7}
                                            enableVIC={allowVictorianSearch}
                                        />
                                    }
                                    {
                                        isAU && searchType === Option.BUILDING_NAME &&
                                        <BuildingSearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                            isCheckboxVisible
                                        />
                                    }
                                    {
                                        searchType === Option.PARCEL &&
                                        <ParcelSearch
                                            route={route}
                                            entryPoint={entryPoint}
                                            placeholder={PARCEL_SEARCH_PLACEHOLDER}
                                            suggestionType={parcelSearchSuggestionType}
                                            routePathname={parcelSearchRoutePathname}
                                            routeSearch={parcelSearchRouteSearch}
                                        />
                                    }
                                    {
                                        searchType === Option.VOLUME_FOLIO &&
                                        <VolumeFolioSearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                        />
                                    }
                                    {
                                        searchType === Option.TITLE_REFERENCE && isNZ &&
                                        isNZ && searchType === Option.TITLE_REFERENCE &&
                                        <TitleSearch
                                            route={route}
                                            entryPoint={entryPoint}
                                            suggestionType={TITLE_REF}
                                            placeholder="Search for a title reference"
                                            routePathname={
                                                routeCodes
                                                    .TITLE_REFERENCE_SEARCH_RESULT
                                                    .path
                                            }
                                            routeSearch={encodedSuggestion => (`titleReference=${encodedSuggestion}`)}
                                        />
                                    }
                                    {
                                        searchType === Option.ROLL_VALUATION && isNZ &&
                                        <RollValuationSearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                        />
                                    }
                                </SearchBarTabs> :
                                <SearchBarDropdown
                                    showModal={showSearchModal}
                                    selected={searchType === 'Reports'
                                        ? 'Address'
                                        : searchType}
                                    onSelectionChange={this.handleSearchTypeOnChange}
                                    searchOptions={this.getSearchOptions()}
                                    userDetail={usrDetail}
                                >
                                    {
                                        (searchType === Option.ADDRESS
                                            || searchType === Option.REPORTS) &&
                                        <MultiLocalitySearch
                                            route={route}
                                            entryPoint={entryPoint}
                                            isHistoricVisible={false}
                                            isHeader
                                            location={location}
                                        />
                                    }
                                    {
                                        searchType === Option.NAME &&
                                        <RapidNameSearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                            isCheckboxVisible={false}
                                            enableVIC={allowVictorianSearch}
                                        />
                                    }
                                    {
                                        searchType === Option.COMPANY &&
                                        <CompanySearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                            isCheckboxVisible={false}
                                            deed7={deed7}
                                            enableVIC={allowVictorianSearch}
                                        />
                                    }
                                    {
                                        isAU && searchType === Option.BUILDING_NAME &&
                                        <BuildingSearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                            isCheckboxVisible={false}
                                            navSearch
                                        />
                                    }
                                    {
                                        searchType === Option.PARCEL &&
                                        <ParcelSearch
                                            route={route}
                                            entryPoint={entryPoint}
                                            suggestionType={parcelSearchSuggestionType}
                                            placeholder={PARCEL_SEARCH_PLACEHOLDER}
                                            routePathname={parcelSearchRoutePathname}
                                            routeSearch={parcelSearchRouteSearch}
                                        />
                                    }
                                    {
                                        searchType === Option.VOLUME_FOLIO &&
                                        <VolumeFolioSearch
                                            route={route}
                                            dispatch={dispatch}
                                            navSearch
                                            showPopper
                                        />
                                    }
                                    {
                                        searchType === Option.TITLE_REFERENCE && isNZ &&
                                        isNZ && searchType === Option.TITLE_REFERENCE &&
                                        <TitleSearch
                                            route={route}
                                            entryPoint={entryPoint}
                                            suggestionType={TITLE_REF}
                                            placeholder="Search for a title reference"
                                            routePathname={
                                                routeCodes
                                                    .TITLE_REFERENCE_SEARCH_RESULT
                                                    .path
                                            }
                                            routeSearch={encodedSuggestion => (`titleReference=${encodedSuggestion}`)}
                                        />
                                    }
                                    {
                                        searchType === Option.ROLL_VALUATION && isNZ &&
                                        <RollValuationSearch
                                            route={route}
                                            dispatch={dispatch}
                                            showPopper
                                        />
                                    }
                                </SearchBarDropdown>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const SearchBarV2 = connect(state => ({
    usrDetail: state.clapi.get('usrDetail'),
    deed7: !!Commons.get(state.clapi.get('usrDetail'), 'entitlements.deed7'),
    searchType: state.componentAccess.get('selectedSearchOption'),
}))(withRouter(SearchBarV2Component));
SearchBarV2.displayName = 'SearchBarV2';
export default SearchBarV2;
