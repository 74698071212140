import { Component } from 'react';
import PropTypes from 'prop-types';
import { MapContext, OverlayView } from '@react-google-maps/api';
import { LAYER, LAYER_NZ } from '../../../../constants/map';
import { isNZ } from '../../../../constants/crux';
import GeoUtil from '../util/GeoUtil';
import MapOverlayImage from './MapOverlayImage';

const DEFAULT_HEIGHT = 766;
const DEFAULT_WIDTH = 1208;
const countryLayer = isNZ ? LAYER_NZ : LAYER;

export default class BoundingBoxLayer extends Component {
    static contextType = MapContext;

    static propTypes = {
        bounds: PropTypes.object,
        zoom: PropTypes.number,
        layerName: PropTypes.string,
        layerCategory: PropTypes.string,
        className: PropTypes.string,
        request: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };

    constructor(props) {
        super(props);
        this.state = {
            overlays: []
        };
        this.overlayIdCounter = 0;
    }

    getPropertyAsString = (obj, propName) => {
        const returnedPropName = Object.keys(obj).find(name => name === propName);
        if (returnedPropName) {
            return returnedPropName;
        }
        console.log(`Error: property name ${propName} is absent from object`);
        return '';
    }

    getMapDimension = () => {
        const map = this.context;
        const height = map ? map.getDiv().offsetHeight : DEFAULT_HEIGHT;
        const width = map ? map.getDiv().offsetWidth : DEFAULT_WIDTH;
        return {
            height,
            width,
        }
    }

    constructUrl(layerParam) {
        const { bounds, layerName, propertyId } = this.props;
        const { height, width } = this.getMapDimension();
        const {
            north, east, south, west,
        } = bounds;

        const selectedBoundaryParam = ['Target Property Boundary', 'pboundary', 'parcelb'].includes(layerName)
            ? `&layers=show%3A0&layerDefs=%7B0:%27property_id%2BIN%2B(${propertyId})%27%7D` : '';
        let mapLayerUrl;
        if (isNZ) {
            const { uri, param } = layerParam;
            let additionalParam = '';
            if (!!selectedBoundaryParam) {
                additionalParam = selectedBoundaryParam;
            } else if (!!param) {
                additionalParam = `&layers=${param}`;
            }
            mapLayerUrl = `${uri}?bbox=${GeoUtil.degrees2metersLon(west)}%2C${GeoUtil.degrees2metersLat(south)}%2C${GeoUtil.degrees2metersLon(east)}%2C${GeoUtil.degrees2metersLat(north)}&bboxSR=3857&datumTransformations=1565&imageSR=3857&f=image&size=${width}%2C${height}&transparent=true&format=png32${additionalParam}`;
        } else {
            mapLayerUrl = `${layerParam}?bbox=${GeoUtil.degrees2metersLon(west)}%2C${GeoUtil.degrees2metersLat(south)}%2C${GeoUtil.degrees2metersLon(east)}%2C${GeoUtil.degrees2metersLat(north)}&format=png32&transparent=true&size=${width}%2C${height}&dpi=96&f=image${selectedBoundaryParam}`;
        }
        return mapLayerUrl;
    }

    componentDidMount() {
        // Add an initial overlay when the component first mounts
        this.addInitialOverlay();
    }

    // When zoom changes, clear overlay and add new one
    // When bounds changes, add new overlay on top of existing layers
    componentDidUpdate(prevProps) {
        if (!this.shouldCreateOverlay()) {
            return;
        }
        const zoomChanged = prevProps.zoom !== this.props.zoom;
        const boundsChanged = prevProps.bounds !== this.props.bounds;
        const layerTypeChanged = prevProps.layerName !== this.props.layerName;
        if (zoomChanged || layerTypeChanged) {
            this.addLayerInNewList();
        } else if (boundsChanged) {
            this.addLayerOnTop();
        }
    }

    addInitialOverlay() {
        if(this.shouldCreateOverlay()) {
            this.addLayerOnTop();
        }
    }

    // Add a new overlay in existing list
    addLayerOnTop() {
        this.setState(prevState => {
            return { overlays: [...prevState.overlays, this.createNewOverlay()] };
        });
    }

    // Add a new overlay in an empty list
    addLayerInNewList() {
        this.setState({ overlays: [this.createNewOverlay()] });
    }

    shouldCreateOverlay(){
        const {
            layerCategory, bounds, zoom,
        } = this.props;

        if (!bounds) {
            return false;
        }

        return !(this.getPropertyAsString(countryLayer, 'propertyData') === layerCategory
            && zoom < 15 && isNZ);
    }

    createNewOverlay() {
        const {
            layerCategory, bounds,
            className, request, layerName,
        } = this.props;

        let paneType;
        if (this.getPropertyAsString(countryLayer, 'boundary') === layerCategory ||
            this.getPropertyAsString(countryLayer, 'targetProperty') === layerCategory
        ) {
            paneType = OverlayView.MARKER_LAYER;
        } else if (this.getPropertyAsString(countryLayer, 'propertyData') === layerCategory) {
            paneType = OverlayView.OVERLAY_MOUSE_TARGET;
            if (layerName === 'pb') {
                paneType = OverlayView.OVERLAY_LAYER;
            }
        } else if (this.getPropertyAsString(countryLayer, 'baseMap') === layerCategory) {
            paneType = OverlayView.MAP_PANE;
        } else {
            paneType = OverlayView.OVERLAY_LAYER;
        }

        const url = this.constructUrl(request);
        return {
            id: `${layerName}${++this.overlayIdCounter}`,
            url,
            bounds,
            paneType,
            className,
        }
    }

    //Remove the earliest layer when the number of layers reached limit
    removeFirstOverlayLayer() {
        const cacheLayerCount = 1;
        this.setState(prevState => {
            const newOverlays = [...prevState.overlays];
            if (newOverlays.length > cacheLayerCount) {
                return { overlays: newOverlays.slice(-cacheLayerCount) };
            } else {
                return null;
            }
        });
    }

    renderOverlays() {
        return this.state.overlays.map((overlay) => {
            const {
                id,
                url,
                bounds,
                paneType,
                className
            } = overlay;

            const {
                north, east, south, west,
            } = bounds;

            const { height, width } = this.getMapDimension();

            return (
                <OverlayView
                    key={id}
                    mapPaneName={paneType}
                    bounds={{ne: {lat: north, lng: east}, sw: {lat: south, lng: west}}}
                    getPixelPositionOffset={() => ({x: -width, y: -height})}
                >
                    <MapOverlayImage
                        srcUrl={url}
                        className={className}
                        onImageLoad={() => this.removeFirstOverlayLayer()}
                    />
                </OverlayView>
            );
        });
    }

    render() {
        return (
            <div
                key={this.props.layerName}
                className="map-bounding-box-layer"
                data-testid="bounding-box-layer"
            >
                {this.renderOverlays()}
            </div>
        );
    }
}
